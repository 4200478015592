var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! @license Firebase v2.4.2
    License: https://www.firebase.com/terms/terms-of-service.html */
(function () {
  var h,
      n = this || _global;

  function p(a) {
    return void 0 !== a;
  }

  function aa() {}

  function ba(a) {
    a.yb = function () {
      return a.zf ? a.zf : a.zf = new a();
    };
  }

  function ca(a) {
    var b = typeof a;
    if ("object" == b) {
      if (a) {
        if (a instanceof Array) return "array";
        if (a instanceof Object) return b;
        var c = Object.prototype.toString.call(a);
        if ("[object Window]" == c) return "object";
        if ("[object Array]" == c || "number" == typeof a.length && "undefined" != typeof a.splice && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("splice")) return "array";
        if ("[object Function]" == c || "undefined" != typeof a.call && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("call")) return "function";
      } else return "null";
    } else if ("function" == b && "undefined" == typeof a.call) return "object";
    return b;
  }

  function da(a) {
    return "array" == ca(a);
  }

  function ea(a) {
    var b = ca(a);
    return "array" == b || "object" == b && "number" == typeof a.length;
  }

  function q(a) {
    return "string" == typeof a;
  }

  function fa(a) {
    return "number" == typeof a;
  }

  function r(a) {
    return "function" == ca(a);
  }

  function ga(a) {
    var b = typeof a;
    return "object" == b && null != a || "function" == b;
  }

  function ha(a, b, c) {
    return a.call.apply(a.bind, arguments);
  }

  function ia(a, b, c) {
    if (!a) throw Error();

    if (2 < arguments.length) {
      var d = Array.prototype.slice.call(arguments, 2);
      return function () {
        var c = Array.prototype.slice.call(arguments);
        Array.prototype.unshift.apply(c, d);
        return a.apply(b, c);
      };
    }

    return function () {
      return a.apply(b, arguments);
    };
  }

  function u(a, b, c) {
    u = Function.prototype.bind && -1 != Function.prototype.bind.toString().indexOf("native code") ? ha : ia;
    return u.apply(null, arguments);
  }

  var ja = Date.now || function () {
    return +new Date();
  };

  function ka(a, b) {
    function c() {}

    c.prototype = b.prototype;
    a.ph = b.prototype;
    a.prototype = new c();
    a.prototype.constructor = a;

    a.lh = function (a, c, f) {
      for (var g = Array(arguments.length - 2), k = 2; k < arguments.length; k++) g[k - 2] = arguments[k];

      return b.prototype[c].apply(a, g);
    };
  }

  ;

  function la(a) {
    if (Error.captureStackTrace) Error.captureStackTrace(this || _global, la);else {
      var b = Error().stack;
      b && ((this || _global).stack = b);
    }
    a && ((this || _global).message = String(a));
  }

  ka(la, Error);
  la.prototype.name = "CustomError";

  function v(a, b) {
    for (var c in a) b.call(void 0, a[c], c, a);
  }

  function ma(a, b) {
    var c = {},
        d;

    for (d in a) c[d] = b.call(void 0, a[d], d, a);

    return c;
  }

  function na(a, b) {
    for (var c in a) if (!b.call(void 0, a[c], c, a)) return !1;

    return !0;
  }

  function oa(a) {
    var b = 0,
        c;

    for (c in a) b++;

    return b;
  }

  function pa(a) {
    for (var b in a) return b;
  }

  function qa(a) {
    var b = [],
        c = 0,
        d;

    for (d in a) b[c++] = a[d];

    return b;
  }

  function ra(a) {
    var b = [],
        c = 0,
        d;

    for (d in a) b[c++] = d;

    return b;
  }

  function sa(a, b) {
    for (var c in a) if (a[c] == b) return !0;

    return !1;
  }

  function ta(a, b, c) {
    for (var d in a) if (b.call(c, a[d], d, a)) return d;
  }

  function ua(a, b) {
    var c = ta(a, b, void 0);
    return c && a[c];
  }

  function va(a) {
    for (var b in a) return !1;

    return !0;
  }

  function wa(a) {
    var b = {},
        c;

    for (c in a) b[c] = a[c];

    return b;
  }

  var xa = "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");

  function ya(a, b) {
    for (var c, d, e = 1; e < arguments.length; e++) {
      d = arguments[e];

      for (c in d) a[c] = d[c];

      for (var f = 0; f < xa.length; f++) c = xa[f], Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c]);
    }
  }

  ;

  function za(a) {
    a = String(a);
    if (/^\s*$/.test(a) ? 0 : /^[\],:{}\s\u2028\u2029]*$/.test(a.replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r\u2028\u2029\x00-\x08\x0a-\x1f]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:[\s\u2028\u2029]*\[)+/g, ""))) try {
      return eval("(" + a + ")");
    } catch (b) {}
    throw Error("Invalid JSON string: " + a);
  }

  function Aa() {
    (this || _global).Vd = void 0;
  }

  function Ba(a, b, c) {
    switch (typeof b) {
      case "string":
        Ca(b, c);
        break;

      case "number":
        c.push(isFinite(b) && !isNaN(b) ? b : "null");
        break;

      case "boolean":
        c.push(b);
        break;

      case "undefined":
        c.push("null");
        break;

      case "object":
        if (null == b) {
          c.push("null");
          break;
        }

        if (da(b)) {
          var d = b.length;
          c.push("[");

          for (var e = "", f = 0; f < d; f++) c.push(e), e = b[f], Ba(a, a.Vd ? a.Vd.call(b, String(f), e) : e, c), e = ",";

          c.push("]");
          break;
        }

        c.push("{");
        d = "";

        for (f in b) Object.prototype.hasOwnProperty.call(b, f) && (e = b[f], "function" != typeof e && (c.push(d), Ca(f, c), c.push(":"), Ba(a, a.Vd ? a.Vd.call(b, f, e) : e, c), d = ","));

        c.push("}");
        break;

      case "function":
        break;

      default:
        throw Error("Unknown type: " + typeof b);
    }
  }

  var Da = {
    "\"": "\\\"",
    "\\": "\\\\",
    "/": "\\/",
    "\b": "\\b",
    "\f": "\\f",
    "\n": "\\n",
    "\r": "\\r",
    "\t": "\\t",
    "\x0B": "\\u000b"
  },
      Ea = /\uffff/.test("\uFFFF") ? /[\\\"\x00-\x1f\x7f-\uffff]/g : /[\\\"\x00-\x1f\x7f-\xff]/g;

  function Ca(a, b) {
    b.push("\"", a.replace(Ea, function (a) {
      if (a in Da) return Da[a];
      var b = a.charCodeAt(0),
          e = "\\u";
      16 > b ? e += "000" : 256 > b ? e += "00" : 4096 > b && (e += "0");
      return Da[a] = e + b.toString(16);
    }), "\"");
  }

  ;

  function Fa() {
    return Math.floor(2147483648 * Math.random()).toString(36) + Math.abs(Math.floor(2147483648 * Math.random()) ^ ja()).toString(36);
  }

  ;
  var w;

  a: {
    var Ga = n.navigator;

    if (Ga) {
      var Ha = Ga.userAgent;

      if (Ha) {
        w = Ha;
        break a;
      }
    }

    w = "";
  }

  ;

  function Ia() {
    (this || _global).Ya = -1;
  }

  ;

  function Ja() {
    (this || _global).Ya = -1;
    (this || _global).Ya = 64;
    (this || _global).P = [];
    (this || _global).pe = [];
    (this || _global).eg = [];
    (this || _global).Od = [];
    (this || _global).Od[0] = 128;

    for (var a = 1; a < (this || _global).Ya; ++a) (this || _global).Od[a] = 0;

    (this || _global).ge = (this || _global).ec = 0;
    this.reset();
  }

  ka(Ja, Ia);

  Ja.prototype.reset = function () {
    (this || _global).P[0] = 1732584193;
    (this || _global).P[1] = 4023233417;
    (this || _global).P[2] = 2562383102;
    (this || _global).P[3] = 271733878;
    (this || _global).P[4] = 3285377520;
    (this || _global).ge = (this || _global).ec = 0;
  };

  function Ka(a, b, c) {
    c || (c = 0);
    var d = a.eg;
    if (q(b)) for (var e = 0; 16 > e; e++) d[e] = b.charCodeAt(c) << 24 | b.charCodeAt(c + 1) << 16 | b.charCodeAt(c + 2) << 8 | b.charCodeAt(c + 3), c += 4;else for (e = 0; 16 > e; e++) d[e] = b[c] << 24 | b[c + 1] << 16 | b[c + 2] << 8 | b[c + 3], c += 4;

    for (e = 16; 80 > e; e++) {
      var f = d[e - 3] ^ d[e - 8] ^ d[e - 14] ^ d[e - 16];
      d[e] = (f << 1 | f >>> 31) & 4294967295;
    }

    b = a.P[0];
    c = a.P[1];

    for (var g = a.P[2], k = a.P[3], m = a.P[4], l, e = 0; 80 > e; e++) 40 > e ? 20 > e ? (f = k ^ c & (g ^ k), l = 1518500249) : (f = c ^ g ^ k, l = 1859775393) : 60 > e ? (f = c & g | k & (c | g), l = 2400959708) : (f = c ^ g ^ k, l = 3395469782), f = (b << 5 | b >>> 27) + f + m + l + d[e] & 4294967295, m = k, k = g, g = (c << 30 | c >>> 2) & 4294967295, c = b, b = f;

    a.P[0] = a.P[0] + b & 4294967295;
    a.P[1] = a.P[1] + c & 4294967295;
    a.P[2] = a.P[2] + g & 4294967295;
    a.P[3] = a.P[3] + k & 4294967295;
    a.P[4] = a.P[4] + m & 4294967295;
  }

  Ja.prototype.update = function (a, b) {
    if (null != a) {
      p(b) || (b = a.length);

      for (var c = b - (this || _global).Ya, d = 0, e = (this || _global).pe, f = (this || _global).ec; d < b;) {
        if (0 == f) for (; d <= c;) Ka(this || _global, a, d), d += (this || _global).Ya;
        if (q(a)) for (; d < b;) {
          if (e[f] = a.charCodeAt(d), ++f, ++d, f == (this || _global).Ya) {
            Ka(this || _global, e);
            f = 0;
            break;
          }
        } else for (; d < b;) if (e[f] = a[d], ++f, ++d, f == (this || _global).Ya) {
          Ka(this || _global, e);
          f = 0;
          break;
        }
      }

      (this || _global).ec = f;
      (this || _global).ge += b;
    }
  };

  var x = Array.prototype,
      La = x.indexOf ? function (a, b, c) {
    return x.indexOf.call(a, b, c);
  } : function (a, b, c) {
    c = null == c ? 0 : 0 > c ? Math.max(0, a.length + c) : c;
    if (q(a)) return q(b) && 1 == b.length ? a.indexOf(b, c) : -1;

    for (; c < a.length; c++) if (c in a && a[c] === b) return c;

    return -1;
  },
      Ma = x.forEach ? function (a, b, c) {
    x.forEach.call(a, b, c);
  } : function (a, b, c) {
    for (var d = a.length, e = q(a) ? a.split("") : a, f = 0; f < d; f++) f in e && b.call(c, e[f], f, a);
  },
      Na = x.filter ? function (a, b, c) {
    return x.filter.call(a, b, c);
  } : function (a, b, c) {
    for (var d = a.length, e = [], f = 0, g = q(a) ? a.split("") : a, k = 0; k < d; k++) if (k in g) {
      var m = g[k];
      b.call(c, m, k, a) && (e[f++] = m);
    }

    return e;
  },
      Oa = x.map ? function (a, b, c) {
    return x.map.call(a, b, c);
  } : function (a, b, c) {
    for (var d = a.length, e = Array(d), f = q(a) ? a.split("") : a, g = 0; g < d; g++) g in f && (e[g] = b.call(c, f[g], g, a));

    return e;
  },
      Pa = x.reduce ? function (a, b, c, d) {
    for (var e = [], f = 1, g = arguments.length; f < g; f++) e.push(arguments[f]);

    d && (e[0] = u(b, d));
    return x.reduce.apply(a, e);
  } : function (a, b, c, d) {
    var e = c;
    Ma(a, function (c, g) {
      e = b.call(d, e, c, g, a);
    });
    return e;
  },
      Qa = x.every ? function (a, b, c) {
    return x.every.call(a, b, c);
  } : function (a, b, c) {
    for (var d = a.length, e = q(a) ? a.split("") : a, f = 0; f < d; f++) if (f in e && !b.call(c, e[f], f, a)) return !1;

    return !0;
  };

  function Ra(a, b) {
    var c = Sa(a, b, void 0);
    return 0 > c ? null : q(a) ? a.charAt(c) : a[c];
  }

  function Sa(a, b, c) {
    for (var d = a.length, e = q(a) ? a.split("") : a, f = 0; f < d; f++) if (f in e && b.call(c, e[f], f, a)) return f;

    return -1;
  }

  function Ta(a, b) {
    var c = La(a, b);
    0 <= c && x.splice.call(a, c, 1);
  }

  function Ua(a, b, c) {
    return 2 >= arguments.length ? x.slice.call(a, b) : x.slice.call(a, b, c);
  }

  function Va(a, b) {
    a.sort(b || Wa);
  }

  function Wa(a, b) {
    return a > b ? 1 : a < b ? -1 : 0;
  }

  ;

  function Xa(a) {
    n.setTimeout(function () {
      throw a;
    }, 0);
  }

  var Ya;

  function Za() {
    var a = n.MessageChannel;
    "undefined" === typeof a && "undefined" !== typeof window && window.postMessage && window.addEventListener && -1 == w.indexOf("Presto") && (a = function () {
      var a = document.createElement("iframe");
      a.style.display = "none";
      a.src = "";
      document.documentElement.appendChild(a);
      var b = a.contentWindow,
          a = b.document;
      a.open();
      a.write("");
      a.close();
      var c = "callImmediate" + Math.random(),
          d = "file:" == b.location.protocol ? "*" : b.location.protocol + "//" + b.location.host,
          a = u(function (a) {
        if (("*" == d || a.origin == d) && a.data == c) (this || _global).port1.onmessage();
      }, this || _global);
      b.addEventListener("message", a, !1);
      (this || _global).port1 = {};
      (this || _global).port2 = {
        postMessage: function () {
          b.postMessage(c, d);
        }
      };
    });

    if ("undefined" !== typeof a && -1 == w.indexOf("Trident") && -1 == w.indexOf("MSIE")) {
      var b = new a(),
          c = {},
          d = c;

      b.port1.onmessage = function () {
        if (p(c.next)) {
          c = c.next;
          var a = c.hb;
          c.hb = null;
          a();
        }
      };

      return function (a) {
        d.next = {
          hb: a
        };
        d = d.next;
        b.port2.postMessage(0);
      };
    }

    return "undefined" !== typeof document && "onreadystatechange" in document.createElement("script") ? function (a) {
      var b = document.createElement("script");

      b.onreadystatechange = function () {
        b.onreadystatechange = null;
        b.parentNode.removeChild(b);
        b = null;
        a();
        a = null;
      };

      document.documentElement.appendChild(b);
    } : function (a) {
      n.setTimeout(a, 0);
    };
  }

  ;

  function $a(a, b) {
    ab || bb();
    cb || (ab(), cb = !0);
    db.push(new eb(a, b));
  }

  var ab;

  function bb() {
    if (n.Promise && n.Promise.resolve) {
      var a = n.Promise.resolve();

      ab = function () {
        a.then(fb);
      };
    } else ab = function () {
      var a = fb;
      !r(n.setImmediate) || n.Window && n.Window.prototype && n.Window.prototype.setImmediate == n.setImmediate ? (Ya || (Ya = Za()), Ya(a)) : n.setImmediate(a);
    };
  }

  var cb = !1,
      db = [];
  [].push(function () {
    cb = !1;
    db = [];
  });

  function fb() {
    for (; db.length;) {
      var a = db;
      db = [];

      for (var b = 0; b < a.length; b++) {
        var c = a[b];

        try {
          c.yg.call(c.scope);
        } catch (d) {
          Xa(d);
        }
      }
    }

    cb = !1;
  }

  function eb(a, b) {
    (this || _global).yg = a;
    (this || _global).scope = b;
  }

  ;
  var gb = -1 != w.indexOf("Opera") || -1 != w.indexOf("OPR"),
      hb = -1 != w.indexOf("Trident") || -1 != w.indexOf("MSIE"),
      ib = -1 != w.indexOf("Gecko") && -1 == w.toLowerCase().indexOf("webkit") && !(-1 != w.indexOf("Trident") || -1 != w.indexOf("MSIE")),
      jb = -1 != w.toLowerCase().indexOf("webkit");

  (function () {
    var a = "",
        b;
    if (gb && n.opera) return a = n.opera.version, r(a) ? a() : a;
    ib ? b = /rv\:([^\);]+)(\)|;)/ : hb ? b = /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/ : jb && (b = /WebKit\/(\S+)/);
    b && (a = (a = b.exec(w)) ? a[1] : "");
    return hb && (b = (b = n.document) ? b.documentMode : void 0, b > parseFloat(a)) ? String(b) : a;
  })();

  var kb = null,
      lb = null,
      mb = null;

  function nb(a, b) {
    if (!ea(a)) throw Error("encodeByteArray takes an array as a parameter");
    ob();

    for (var c = b ? lb : kb, d = [], e = 0; e < a.length; e += 3) {
      var f = a[e],
          g = e + 1 < a.length,
          k = g ? a[e + 1] : 0,
          m = e + 2 < a.length,
          l = m ? a[e + 2] : 0,
          t = f >> 2,
          f = (f & 3) << 4 | k >> 4,
          k = (k & 15) << 2 | l >> 6,
          l = l & 63;
      m || (l = 64, g || (k = 64));
      d.push(c[t], c[f], c[k], c[l]);
    }

    return d.join("");
  }

  function ob() {
    if (!kb) {
      kb = {};
      lb = {};
      mb = {};

      for (var a = 0; 65 > a; a++) kb[a] = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(a), lb[a] = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.".charAt(a), mb[lb[a]] = a, 62 <= a && (mb["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(a)] = a);
    }
  }

  ;

  function pb(a, b) {
    (this || _global).N = qb;
    (this || _global).Rf = void 0;
    (this || _global).Ba = (this || _global).Ha = null;
    (this || _global).yd = (this || _global).ye = !1;
    if (a == rb) sb(this || _global, tb, b);else try {
      var c = this || _global;
      a.call(b, function (a) {
        sb(c, tb, a);
      }, function (a) {
        if (!(a instanceof ub)) try {
          if (a instanceof Error) throw a;
          throw Error("Promise rejected.");
        } catch (b) {}
        sb(c, vb, a);
      });
    } catch (d) {
      sb(this || _global, vb, d);
    }
  }

  var qb = 0,
      tb = 2,
      vb = 3;

  function rb() {}

  pb.prototype.then = function (a, b, c) {
    return wb(this || _global, r(a) ? a : null, r(b) ? b : null, c);
  };

  pb.prototype.then = pb.prototype.then;
  pb.prototype.$goog_Thenable = !0;
  h = pb.prototype;

  h.gh = function (a, b) {
    return wb(this || _global, null, a, b);
  };

  h.cancel = function (a) {
    (this || _global).N == qb && $a(function () {
      var b = new ub(a);
      xb(this || _global, b);
    }, this || _global);
  };

  function xb(a, b) {
    if (a.N == qb) if (a.Ha) {
      var c = a.Ha;

      if (c.Ba) {
        for (var d = 0, e = -1, f = 0, g; g = c.Ba[f]; f++) if (g = g.o) if (d++, g == a && (e = f), 0 <= e && 1 < d) break;

        0 <= e && (c.N == qb && 1 == d ? xb(c, b) : (d = c.Ba.splice(e, 1)[0], yb(c, d, vb, b)));
      }

      a.Ha = null;
    } else sb(a, vb, b);
  }

  function zb(a, b) {
    a.Ba && a.Ba.length || a.N != tb && a.N != vb || Ab(a);
    a.Ba || (a.Ba = []);
    a.Ba.push(b);
  }

  function wb(a, b, c, d) {
    var e = {
      o: null,
      Hf: null,
      Jf: null
    };
    e.o = new pb(function (a, g) {
      e.Hf = b ? function (c) {
        try {
          var e = b.call(d, c);
          a(e);
        } catch (l) {
          g(l);
        }
      } : a;
      e.Jf = c ? function (b) {
        try {
          var e = c.call(d, b);
          !p(e) && b instanceof ub ? g(b) : a(e);
        } catch (l) {
          g(l);
        }
      } : g;
    });
    e.o.Ha = a;
    zb(a, e);
    return e.o;
  }

  h.Yf = function (a) {
    (this || _global).N = qb;
    sb(this || _global, tb, a);
  };

  h.Zf = function (a) {
    (this || _global).N = qb;
    sb(this || _global, vb, a);
  };

  function sb(a, b, c) {
    if (a.N == qb) {
      if (a == c) b = vb, c = new TypeError("Promise cannot resolve to itself");else {
        var d;
        if (c) try {
          d = !!c.$goog_Thenable;
        } catch (e) {
          d = !1;
        } else d = !1;

        if (d) {
          a.N = 1;
          c.then(a.Yf, a.Zf, a);
          return;
        }

        if (ga(c)) try {
          var f = c.then;

          if (r(f)) {
            Bb(a, c, f);
            return;
          }
        } catch (g) {
          b = vb, c = g;
        }
      }
      a.Rf = c;
      a.N = b;
      a.Ha = null;
      Ab(a);
      b != vb || c instanceof ub || Cb(a, c);
    }
  }

  function Bb(a, b, c) {
    function d(b) {
      f || (f = !0, a.Zf(b));
    }

    function e(b) {
      f || (f = !0, a.Yf(b));
    }

    a.N = 1;
    var f = !1;

    try {
      c.call(b, e, d);
    } catch (g) {
      d(g);
    }
  }

  function Ab(a) {
    a.ye || (a.ye = !0, $a(a.wg, a));
  }

  h.wg = function () {
    for (; (this || _global).Ba && (this || _global).Ba.length;) {
      var a = (this || _global).Ba;
      (this || _global).Ba = null;

      for (var b = 0; b < a.length; b++) yb(this || _global, a[b], (this || _global).N, (this || _global).Rf);
    }

    (this || _global).ye = !1;
  };

  function yb(a, b, c, d) {
    if (c == tb) b.Hf(d);else {
      if (b.o) for (; a && a.yd; a = a.Ha) a.yd = !1;
      b.Jf(d);
    }
  }

  function Cb(a, b) {
    a.yd = !0;
    $a(function () {
      a.yd && Db.call(null, b);
    });
  }

  var Db = Xa;

  function ub(a) {
    la.call(this || _global, a);
  }

  ka(ub, la);
  ub.prototype.name = "cancel";
  var Eb = Eb || "2.4.2";

  function y(a, b) {
    return Object.prototype.hasOwnProperty.call(a, b);
  }

  function z(a, b) {
    if (Object.prototype.hasOwnProperty.call(a, b)) return a[b];
  }

  function Fb(a, b) {
    for (var c in a) Object.prototype.hasOwnProperty.call(a, c) && b(c, a[c]);
  }

  function Gb(a) {
    var b = {};
    Fb(a, function (a, d) {
      b[a] = d;
    });
    return b;
  }

  function Hb(a) {
    return "object" === typeof a && null !== a;
  }

  ;

  function Ib(a) {
    var b = [];
    Fb(a, function (a, d) {
      da(d) ? Ma(d, function (d) {
        b.push(encodeURIComponent(a) + "=" + encodeURIComponent(d));
      }) : b.push(encodeURIComponent(a) + "=" + encodeURIComponent(d));
    });
    return b.length ? "&" + b.join("&") : "";
  }

  function Jb(a) {
    var b = {};
    a = a.replace(/^\?/, "").split("&");
    Ma(a, function (a) {
      a && (a = a.split("="), b[a[0]] = a[1]);
    });
    return b;
  }

  ;

  function Kb(a, b) {
    if (!a) throw Lb(b);
  }

  function Lb(a) {
    return Error("Firebase (" + Eb + ") INTERNAL ASSERT FAILED: " + a);
  }

  ;
  var Mb = n.Promise || pb;
  pb.prototype["catch"] = pb.prototype.gh;

  function B() {
    var a = this || _global;
    (this || _global).reject = (this || _global).resolve = null;
    (this || _global).D = new Mb(function (b, c) {
      a.resolve = b;
      a.reject = c;
    });
  }

  function C(a, b) {
    return function (c, d) {
      c ? a.reject(c) : a.resolve(d);
      r(b) && (Nb(a.D), 1 === b.length ? b(c) : b(c, d));
    };
  }

  function Nb(a) {
    a.then(void 0, aa);
  }

  ;

  function Ob(a) {
    for (var b = [], c = 0, d = 0; d < a.length; d++) {
      var e = a.charCodeAt(d);
      55296 <= e && 56319 >= e && (e -= 55296, d++, Kb(d < a.length, "Surrogate pair missing trail surrogate."), e = 65536 + (e << 10) + (a.charCodeAt(d) - 56320));
      128 > e ? b[c++] = e : (2048 > e ? b[c++] = e >> 6 | 192 : (65536 > e ? b[c++] = e >> 12 | 224 : (b[c++] = e >> 18 | 240, b[c++] = e >> 12 & 63 | 128), b[c++] = e >> 6 & 63 | 128), b[c++] = e & 63 | 128);
    }

    return b;
  }

  function Pb(a) {
    for (var b = 0, c = 0; c < a.length; c++) {
      var d = a.charCodeAt(c);
      128 > d ? b++ : 2048 > d ? b += 2 : 55296 <= d && 56319 >= d ? (b += 4, c++) : b += 3;
    }

    return b;
  }

  ;

  function D(a, b, c, d) {
    var e;
    d < b ? e = "at least " + b : d > c && (e = 0 === c ? "none" : "no more than " + c);
    if (e) throw Error(a + " failed: Was called with " + d + (1 === d ? " argument." : " arguments.") + " Expects " + e + ".");
  }

  function E(a, b, c) {
    var d = "";

    switch (b) {
      case 1:
        d = c ? "first" : "First";
        break;

      case 2:
        d = c ? "second" : "Second";
        break;

      case 3:
        d = c ? "third" : "Third";
        break;

      case 4:
        d = c ? "fourth" : "Fourth";
        break;

      default:
        throw Error("errorPrefix called with argumentNumber > 4.  Need to update it?");
    }

    return a = a + " failed: " + (d + " argument ");
  }

  function F(a, b, c, d) {
    if ((!d || p(c)) && !r(c)) throw Error(E(a, b, d) + "must be a valid function.");
  }

  function Qb(a, b, c) {
    if (p(c) && (!ga(c) || null === c)) throw Error(E(a, b, !0) + "must be a valid context object.");
  }

  ;

  function Rb(a) {
    return "undefined" !== typeof JSON && p(JSON.parse) ? JSON.parse(a) : za(a);
  }

  function G(a) {
    if ("undefined" !== typeof JSON && p(JSON.stringify)) a = JSON.stringify(a);else {
      var b = [];
      Ba(new Aa(), a, b);
      a = b.join("");
    }
    return a;
  }

  ;

  function Sb() {
    (this || _global).Zd = H;
  }

  Sb.prototype.j = function (a) {
    return (this || _global).Zd.S(a);
  };

  Sb.prototype.toString = function () {
    return (this || _global).Zd.toString();
  };

  function Tb() {}

  Tb.prototype.uf = function () {
    return null;
  };

  Tb.prototype.Ce = function () {
    return null;
  };

  var Ub = new Tb();

  function Vb(a, b, c) {
    (this || _global).bg = a;
    (this || _global).Oa = b;
    (this || _global).Nd = c;
  }

  Vb.prototype.uf = function (a) {
    var b = (this || _global).Oa.Q;
    if (Wb(b, a)) return b.j().T(a);
    b = null != (this || _global).Nd ? new Xb((this || _global).Nd, !0, !1) : (this || _global).Oa.w();
    return (this || _global).bg.Bc(a, b);
  };

  Vb.prototype.Ce = function (a, b, c) {
    var d = null != (this || _global).Nd ? (this || _global).Nd : Yb((this || _global).Oa);
    a = (this || _global).bg.qe(d, b, 1, c, a);
    return 0 === a.length ? null : a[0];
  };

  function Zb() {
    (this || _global).xb = [];
  }

  function $b(a, b) {
    for (var c = null, d = 0; d < b.length; d++) {
      var e = b[d],
          f = e.cc();
      null === c || f.ea(c.cc()) || (a.xb.push(c), c = null);
      null === c && (c = new ac(f));
      c.add(e);
    }

    c && a.xb.push(c);
  }

  function bc(a, b, c) {
    $b(a, c);
    cc(a, function (a) {
      return a.ea(b);
    });
  }

  function dc(a, b, c) {
    $b(a, c);
    cc(a, function (a) {
      return a.contains(b) || b.contains(a);
    });
  }

  function cc(a, b) {
    for (var c = !0, d = 0; d < a.xb.length; d++) {
      var e = a.xb[d];
      if (e) if (e = e.cc(), b(e)) {
        for (var e = a.xb[d], f = 0; f < e.xd.length; f++) {
          var g = e.xd[f];

          if (null !== g) {
            e.xd[f] = null;
            var k = g.Zb();
            ec && fc("event: " + g.toString());
            gc(k);
          }
        }

        a.xb[d] = null;
      } else c = !1;
    }

    c && (a.xb = []);
  }

  function ac(a) {
    (this || _global).ta = a;
    (this || _global).xd = [];
  }

  ac.prototype.add = function (a) {
    (this || _global).xd.push(a);
  };

  ac.prototype.cc = function () {
    return (this || _global).ta;
  };

  function J(a, b, c, d) {
    (this || _global).type = a;
    (this || _global).Na = b;
    (this || _global).Za = c;
    (this || _global).Oe = d;
    (this || _global).Td = void 0;
  }

  function hc(a) {
    return new J(ic, a);
  }

  var ic = "value";

  function jc(a, b, c, d) {
    (this || _global).xe = b;
    (this || _global).be = c;
    (this || _global).Td = d;
    (this || _global).wd = a;
  }

  jc.prototype.cc = function () {
    var a = (this || _global).be.Mb();

    return "value" === (this || _global).wd ? a.path : a.parent().path;
  };

  jc.prototype.De = function () {
    return (this || _global).wd;
  };

  jc.prototype.Zb = function () {
    return (this || _global).xe.Zb(this || _global);
  };

  jc.prototype.toString = function () {
    return this.cc().toString() + ":" + (this || _global).wd + ":" + G((this || _global).be.qf());
  };

  function kc(a, b, c) {
    (this || _global).xe = a;
    (this || _global).error = b;
    (this || _global).path = c;
  }

  kc.prototype.cc = function () {
    return (this || _global).path;
  };

  kc.prototype.De = function () {
    return "cancel";
  };

  kc.prototype.Zb = function () {
    return (this || _global).xe.Zb(this || _global);
  };

  kc.prototype.toString = function () {
    return (this || _global).path.toString() + ":cancel";
  };

  function Xb(a, b, c) {
    (this || _global).A = a;
    (this || _global).ga = b;
    (this || _global).Yb = c;
  }

  function lc(a) {
    return a.ga;
  }

  function mc(a) {
    return a.Yb;
  }

  function nc(a, b) {
    return b.e() ? a.ga && !a.Yb : Wb(a, K(b));
  }

  function Wb(a, b) {
    return a.ga && !a.Yb || a.A.Fa(b);
  }

  Xb.prototype.j = function () {
    return (this || _global).A;
  };

  function oc(a) {
    (this || _global).pg = a;
    (this || _global).Gd = null;
  }

  oc.prototype.get = function () {
    var a = (this || _global).pg.get(),
        b = wa(a);

    if ((this || _global).Gd) for (var c in (this || _global).Gd) b[c] -= (this || _global).Gd[c];
    (this || _global).Gd = a;
    return b;
  };

  function pc(a, b) {
    (this || _global).Vf = {};
    (this || _global).hd = new oc(a);
    (this || _global).da = b;
    var c = 10000 + 20000 * Math.random();
    setTimeout(u((this || _global).Of, this || _global), Math.floor(c));
  }

  pc.prototype.Of = function () {
    var a = (this || _global).hd.get(),
        b = {},
        c = !1,
        d;

    for (d in a) 0 < a[d] && y((this || _global).Vf, d) && (b[d] = a[d], c = !0);

    c && (this || _global).da.Ye(b);
    setTimeout(u((this || _global).Of, this || _global), Math.floor(600000 * Math.random()));
  };

  function qc() {
    (this || _global).Hc = {};
  }

  function rc(a, b, c) {
    p(c) || (c = 1);
    y(a.Hc, b) || (a.Hc[b] = 0);
    a.Hc[b] += c;
  }

  qc.prototype.get = function () {
    return wa((this || _global).Hc);
  };

  var sc = {},
      tc = {};

  function uc(a) {
    a = a.toString();
    sc[a] || (sc[a] = new qc());
    return sc[a];
  }

  function vc(a, b) {
    var c = a.toString();
    tc[c] || (tc[c] = b());
    return tc[c];
  }

  ;

  function L(a, b) {
    (this || _global).name = a;
    (this || _global).U = b;
  }

  function wc(a, b) {
    return new L(a, b);
  }

  ;

  function xc(a, b) {
    return yc(a.name, b.name);
  }

  function zc(a, b) {
    return yc(a, b);
  }

  ;

  function Ac(a, b, c) {
    (this || _global).type = Bc;
    (this || _global).source = a;
    (this || _global).path = b;
    (this || _global).Ja = c;
  }

  Ac.prototype.$c = function (a) {
    return (this || _global).path.e() ? new Ac((this || _global).source, M, (this || _global).Ja.T(a)) : new Ac((this || _global).source, N((this || _global).path), (this || _global).Ja);
  };

  Ac.prototype.toString = function () {
    return "Operation(" + (this || _global).path + ": " + (this || _global).source.toString() + " overwrite: " + (this || _global).Ja.toString() + ")";
  };

  function Cc(a, b) {
    (this || _global).type = Dc;
    (this || _global).source = a;
    (this || _global).path = b;
  }

  Cc.prototype.$c = function () {
    return (this || _global).path.e() ? new Cc((this || _global).source, M) : new Cc((this || _global).source, N((this || _global).path));
  };

  Cc.prototype.toString = function () {
    return "Operation(" + (this || _global).path + ": " + (this || _global).source.toString() + " listen_complete)";
  };

  function Ec(a, b) {
    (this || _global).Pa = a;
    (this || _global).xa = b ? b : Fc;
  }

  h = Ec.prototype;

  h.Sa = function (a, b) {
    return new Ec((this || _global).Pa, (this || _global).xa.Sa(a, b, (this || _global).Pa).$(null, null, !1, null, null));
  };

  h.remove = function (a) {
    return new Ec((this || _global).Pa, (this || _global).xa.remove(a, (this || _global).Pa).$(null, null, !1, null, null));
  };

  h.get = function (a) {
    for (var b, c = (this || _global).xa; !c.e();) {
      b = this.Pa(a, c.key);
      if (0 === b) return c.value;
      0 > b ? c = c.left : 0 < b && (c = c.right);
    }

    return null;
  };

  function Gc(a, b) {
    for (var c, d = a.xa, e = null; !d.e();) {
      c = a.Pa(b, d.key);

      if (0 === c) {
        if (d.left.e()) return e ? e.key : null;

        for (d = d.left; !d.right.e();) d = d.right;

        return d.key;
      }

      0 > c ? d = d.left : 0 < c && (e = d, d = d.right);
    }

    throw Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }

  h.e = function () {
    return (this || _global).xa.e();
  };

  h.count = function () {
    return (this || _global).xa.count();
  };

  h.Vc = function () {
    return (this || _global).xa.Vc();
  };

  h.jc = function () {
    return (this || _global).xa.jc();
  };

  h.ka = function (a) {
    return (this || _global).xa.ka(a);
  };

  h.ac = function (a) {
    return new Hc((this || _global).xa, null, (this || _global).Pa, !1, a);
  };

  h.bc = function (a, b) {
    return new Hc((this || _global).xa, a, (this || _global).Pa, !1, b);
  };

  h.dc = function (a, b) {
    return new Hc((this || _global).xa, a, (this || _global).Pa, !0, b);
  };

  h.xf = function (a) {
    return new Hc((this || _global).xa, null, (this || _global).Pa, !0, a);
  };

  function Hc(a, b, c, d, e) {
    (this || _global).Xd = e || null;
    (this || _global).Je = d;
    (this || _global).Ta = [];

    for (e = 1; !a.e();) if (e = b ? c(a.key, b) : 1, d && (e *= -1), 0 > e) a = (this || _global).Je ? a.left : a.right;else if (0 === e) {
      (this || _global).Ta.push(a);

      break;
    } else (this || _global).Ta.push(a), a = (this || _global).Je ? a.right : a.left;
  }

  function Ic(a) {
    if (0 === a.Ta.length) return null;
    var b = a.Ta.pop(),
        c;
    c = a.Xd ? a.Xd(b.key, b.value) : {
      key: b.key,
      value: b.value
    };
    if (a.Je) for (b = b.left; !b.e();) a.Ta.push(b), b = b.right;else for (b = b.right; !b.e();) a.Ta.push(b), b = b.left;
    return c;
  }

  function Jc(a) {
    if (0 === a.Ta.length) return null;
    var b;
    b = a.Ta;
    b = b[b.length - 1];
    return a.Xd ? a.Xd(b.key, b.value) : {
      key: b.key,
      value: b.value
    };
  }

  function Kc(a, b, c, d, e) {
    (this || _global).key = a;
    (this || _global).value = b;
    (this || _global).color = null != c ? c : !0;
    (this || _global).left = null != d ? d : Fc;
    (this || _global).right = null != e ? e : Fc;
  }

  h = Kc.prototype;

  h.$ = function (a, b, c, d, e) {
    return new Kc(null != a ? a : (this || _global).key, null != b ? b : (this || _global).value, null != c ? c : (this || _global).color, null != d ? d : (this || _global).left, null != e ? e : (this || _global).right);
  };

  h.count = function () {
    return (this || _global).left.count() + 1 + (this || _global).right.count();
  };

  h.e = function () {
    return !1;
  };

  h.ka = function (a) {
    return (this || _global).left.ka(a) || a((this || _global).key, (this || _global).value) || (this || _global).right.ka(a);
  };

  function Lc(a) {
    return a.left.e() ? a : Lc(a.left);
  }

  h.Vc = function () {
    return Lc(this || _global).key;
  };

  h.jc = function () {
    return (this || _global).right.e() ? (this || _global).key : (this || _global).right.jc();
  };

  h.Sa = function (a, b, c) {
    var d, e;
    e = this || _global;
    d = c(a, e.key);
    e = 0 > d ? e.$(null, null, null, e.left.Sa(a, b, c), null) : 0 === d ? e.$(null, b, null, null, null) : e.$(null, null, null, null, e.right.Sa(a, b, c));
    return Mc(e);
  };

  function Nc(a) {
    if (a.left.e()) return Fc;
    a.left.ha() || a.left.left.ha() || (a = Oc(a));
    a = a.$(null, null, null, Nc(a.left), null);
    return Mc(a);
  }

  h.remove = function (a, b) {
    var c, d;
    c = this || _global;
    if (0 > b(a, c.key)) c.left.e() || c.left.ha() || c.left.left.ha() || (c = Oc(c)), c = c.$(null, null, null, c.left.remove(a, b), null);else {
      c.left.ha() && (c = Pc(c));
      c.right.e() || c.right.ha() || c.right.left.ha() || (c = Qc(c), c.left.left.ha() && (c = Pc(c), c = Qc(c)));

      if (0 === b(a, c.key)) {
        if (c.right.e()) return Fc;
        d = Lc(c.right);
        c = c.$(d.key, d.value, null, null, Nc(c.right));
      }

      c = c.$(null, null, null, null, c.right.remove(a, b));
    }
    return Mc(c);
  };

  h.ha = function () {
    return (this || _global).color;
  };

  function Mc(a) {
    a.right.ha() && !a.left.ha() && (a = Rc(a));
    a.left.ha() && a.left.left.ha() && (a = Pc(a));
    a.left.ha() && a.right.ha() && (a = Qc(a));
    return a;
  }

  function Oc(a) {
    a = Qc(a);
    a.right.left.ha() && (a = a.$(null, null, null, null, Pc(a.right)), a = Rc(a), a = Qc(a));
    return a;
  }

  function Rc(a) {
    return a.right.$(null, null, a.color, a.$(null, null, !0, null, a.right.left), null);
  }

  function Pc(a) {
    return a.left.$(null, null, a.color, null, a.$(null, null, !0, a.left.right, null));
  }

  function Qc(a) {
    return a.$(null, null, !a.color, a.left.$(null, null, !a.left.color, null, null), a.right.$(null, null, !a.right.color, null, null));
  }

  function Sc() {}

  h = Sc.prototype;

  h.$ = function () {
    return this || _global;
  };

  h.Sa = function (a, b) {
    return new Kc(a, b, null);
  };

  h.remove = function () {
    return this || _global;
  };

  h.count = function () {
    return 0;
  };

  h.e = function () {
    return !0;
  };

  h.ka = function () {
    return !1;
  };

  h.Vc = function () {
    return null;
  };

  h.jc = function () {
    return null;
  };

  h.ha = function () {
    return !1;
  };

  var Fc = new Sc();

  function Tc(a, b) {
    return a && "object" === typeof a ? (O(".sv" in a, "Unexpected leaf node or priority contents"), b[a[".sv"]]) : a;
  }

  function Uc(a, b) {
    var c = new Vc();
    Wc(a, new P(""), function (a, e) {
      c.rc(a, Xc(e, b));
    });
    return c;
  }

  function Xc(a, b) {
    var c = a.C().J(),
        c = Tc(c, b),
        d;

    if (a.L()) {
      var e = Tc(a.Ea(), b);
      return e !== a.Ea() || c !== a.C().J() ? new Yc(e, Q(c)) : a;
    }

    d = a;
    c !== a.C().J() && (d = d.ia(new Yc(c)));
    a.R(R, function (a, c) {
      var e = Xc(c, b);
      e !== c && (d = d.W(a, e));
    });
    return d;
  }

  ;

  function Zc() {
    (this || _global).Ac = {};
  }

  Zc.prototype.set = function (a, b) {
    null == b ? delete (this || _global).Ac[a] : (this || _global).Ac[a] = b;
  };

  Zc.prototype.get = function (a) {
    return y((this || _global).Ac, a) ? (this || _global).Ac[a] : null;
  };

  Zc.prototype.remove = function (a) {
    delete (this || _global).Ac[a];
  };

  Zc.prototype.Af = !0;

  function $c(a) {
    (this || _global).Ic = a;
    (this || _global).Sd = "firebase:";
  }

  h = $c.prototype;

  h.set = function (a, b) {
    null == b ? (this || _global).Ic.removeItem((this || _global).Sd + a) : (this || _global).Ic.setItem((this || _global).Sd + a, G(b));
  };

  h.get = function (a) {
    a = (this || _global).Ic.getItem((this || _global).Sd + a);
    return null == a ? null : Rb(a);
  };

  h.remove = function (a) {
    (this || _global).Ic.removeItem((this || _global).Sd + a);
  };

  h.Af = !1;

  h.toString = function () {
    return (this || _global).Ic.toString();
  };

  function ad(a) {
    try {
      if ("undefined" !== typeof window && "undefined" !== typeof window[a]) {
        var b = window[a];
        b.setItem("firebase:sentinel", "cache");
        b.removeItem("firebase:sentinel");
        return new $c(b);
      }
    } catch (c) {}

    return new Zc();
  }

  var bd = ad("localStorage"),
      cd = ad("sessionStorage");

  function dd(a, b, c, d, e) {
    (this || _global).host = a.toLowerCase();
    (this || _global).domain = (this || _global).host.substr((this || _global).host.indexOf(".") + 1);
    (this || _global).ob = b;
    (this || _global).lc = c;
    (this || _global).jh = d;
    (this || _global).Rd = e || "";
    (this || _global).ab = bd.get("host:" + a) || (this || _global).host;
  }

  function ed(a, b) {
    b !== a.ab && (a.ab = b, "s-" === a.ab.substr(0, 2) && bd.set("host:" + a.host, a.ab));
  }

  function fd(a, b, c) {
    O("string" === typeof b, "typeof type must == string");
    O("object" === typeof c, "typeof params must == object");
    if (b === gd) b = (a.ob ? "wss://" : "ws://") + a.ab + "/.ws?";else if (b === hd) b = (a.ob ? "https://" : "http://") + a.ab + "/.lp?";else throw Error("Unknown connection type: " + b);
    a.host !== a.ab && (c.ns = a.lc);
    var d = [];
    v(c, function (a, b) {
      d.push(b + "=" + a);
    });
    return b + d.join("&");
  }

  dd.prototype.toString = function () {
    var a = ((this || _global).ob ? "https://" : "http://") + (this || _global).host;
    (this || _global).Rd && (a += "<" + (this || _global).Rd + ">");
    return a;
  };

  var id = function () {
    var a = 1;
    return function () {
      return a++;
    };
  }(),
      O = Kb,
      jd = Lb;

  function kd(a) {
    try {
      var b;
      if ("undefined" !== typeof atob) b = atob(a);else {
        ob();

        for (var c = mb, d = [], e = 0; e < a.length;) {
          var f = c[a.charAt(e++)],
              g = e < a.length ? c[a.charAt(e)] : 0;
          ++e;
          var k = e < a.length ? c[a.charAt(e)] : 64;
          ++e;
          var m = e < a.length ? c[a.charAt(e)] : 64;
          ++e;
          if (null == f || null == g || null == k || null == m) throw Error();
          d.push(f << 2 | g >> 4);
          64 != k && (d.push(g << 4 & 240 | k >> 2), 64 != m && d.push(k << 6 & 192 | m));
        }

        if (8192 > d.length) b = String.fromCharCode.apply(null, d);else {
          a = "";

          for (c = 0; c < d.length; c += 8192) a += String.fromCharCode.apply(null, Ua(d, c, c + 8192));

          b = a;
        }
      }
      return b;
    } catch (l) {
      fc("base64Decode failed: ", l);
    }

    return null;
  }

  function ld(a) {
    var b = Ob(a);
    a = new Ja();
    a.update(b);
    var b = [],
        c = 8 * a.ge;
    56 > a.ec ? a.update(a.Od, 56 - a.ec) : a.update(a.Od, a.Ya - (a.ec - 56));

    for (var d = a.Ya - 1; 56 <= d; d--) a.pe[d] = c & 255, c /= 256;

    Ka(a, a.pe);

    for (d = c = 0; 5 > d; d++) for (var e = 24; 0 <= e; e -= 8) b[c] = a.P[d] >> e & 255, ++c;

    return nb(b);
  }

  function md(a) {
    for (var b = "", c = 0; c < arguments.length; c++) b = ea(arguments[c]) ? b + md.apply(null, arguments[c]) : "object" === typeof arguments[c] ? b + G(arguments[c]) : b + arguments[c], b += " ";

    return b;
  }

  var ec = null,
      nd = !0;

  function od(a, b) {
    Kb(!b || !0 === a || !1 === a, "Can't turn on custom loggers persistently.");
    !0 === a ? ("undefined" !== typeof console && ("function" === typeof console.log ? ec = u(console.log, console) : "object" === typeof console.log && (ec = function (a) {
      console.log(a);
    })), b && cd.set("logging_enabled", !0)) : r(a) ? ec = a : (ec = null, cd.remove("logging_enabled"));
  }

  function fc(a) {
    !0 === nd && (nd = !1, null === ec && !0 === cd.get("logging_enabled") && od(!0));

    if (ec) {
      var b = md.apply(null, arguments);
      ec(b);
    }
  }

  function pd(a) {
    return function () {
      fc(a, arguments);
    };
  }

  function qd(a) {
    if ("undefined" !== typeof console) {
      var b = "FIREBASE INTERNAL ERROR: " + md.apply(null, arguments);
      "undefined" !== typeof console.error ? console.error(b) : console.log(b);
    }
  }

  function rd(a) {
    var b = md.apply(null, arguments);
    throw Error("FIREBASE FATAL ERROR: " + b);
  }

  function S(a) {
    if ("undefined" !== typeof console) {
      var b = "FIREBASE WARNING: " + md.apply(null, arguments);
      "undefined" !== typeof console.warn ? console.warn(b) : console.log(b);
    }
  }

  function sd(a) {
    var b = "",
        c = "",
        d = "",
        e = "",
        f = !0,
        g = "https",
        k = 443;

    if (q(a)) {
      var m = a.indexOf("//");
      0 <= m && (g = a.substring(0, m - 1), a = a.substring(m + 2));
      m = a.indexOf("/");
      -1 === m && (m = a.length);
      b = a.substring(0, m);
      e = "";
      a = a.substring(m).split("/");

      for (m = 0; m < a.length; m++) if (0 < a[m].length) {
        var l = a[m];

        try {
          l = decodeURIComponent(l.replace(/\+/g, " "));
        } catch (t) {}

        e += "/" + l;
      }

      a = b.split(".");
      3 === a.length ? (c = a[1], d = a[0].toLowerCase()) : 2 === a.length && (c = a[0]);
      m = b.indexOf(":");
      0 <= m && (f = "https" === g || "wss" === g, k = b.substring(m + 1), isFinite(k) && (k = String(k)), k = q(k) ? /^\s*-?0x/i.test(k) ? parseInt(k, 16) : parseInt(k, 10) : NaN);
    }

    return {
      host: b,
      port: k,
      domain: c,
      fh: d,
      ob: f,
      scheme: g,
      bd: e
    };
  }

  function td(a) {
    return fa(a) && (a != a || a == Number.POSITIVE_INFINITY || a == Number.NEGATIVE_INFINITY);
  }

  function ud(a) {
    if ("complete" === document.readyState) a();else {
      var b = !1,
          c = function () {
        document.body ? b || (b = !0, a()) : setTimeout(c, Math.floor(10));
      };

      document.addEventListener ? (document.addEventListener("DOMContentLoaded", c, !1), window.addEventListener("load", c, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", function () {
        "complete" === document.readyState && c();
      }), window.attachEvent("onload", c));
    }
  }

  function yc(a, b) {
    if (a === b) return 0;
    if ("[MIN_NAME]" === a || "[MAX_NAME]" === b) return -1;
    if ("[MIN_NAME]" === b || "[MAX_NAME]" === a) return 1;
    var c = vd(a),
        d = vd(b);
    return null !== c ? null !== d ? 0 == c - d ? a.length - b.length : c - d : -1 : null !== d ? 1 : a < b ? -1 : 1;
  }

  function wd(a, b) {
    if (b && a in b) return b[a];
    throw Error("Missing required key (" + a + ") in object: " + G(b));
  }

  function xd(a) {
    if ("object" !== typeof a || null === a) return G(a);
    var b = [],
        c;

    for (c in a) b.push(c);

    b.sort();
    c = "{";

    for (var d = 0; d < b.length; d++) 0 !== d && (c += ","), c += G(b[d]), c += ":", c += xd(a[b[d]]);

    return c + "}";
  }

  function yd(a, b) {
    if (a.length <= b) return [a];

    for (var c = [], d = 0; d < a.length; d += b) d + b > a ? c.push(a.substring(d, a.length)) : c.push(a.substring(d, d + b));

    return c;
  }

  function zd(a, b) {
    if (da(a)) for (var c = 0; c < a.length; ++c) b(c, a[c]);else v(a, b);
  }

  function Ad(a) {
    O(!td(a), "Invalid JSON number");
    var b, c, d, e;
    0 === a ? (d = c = 0, b = -Infinity === 1 / a ? 1 : 0) : (b = 0 > a, a = Math.abs(a), a >= Math.pow(2, -1022) ? (d = Math.min(Math.floor(Math.log(a) / Math.LN2), 1023), c = d + 1023, d = Math.round(a * Math.pow(2, 52 - d) - Math.pow(2, 52))) : (c = 0, d = Math.round(a / Math.pow(2, -1074))));
    e = [];

    for (a = 52; a; --a) e.push(d % 2 ? 1 : 0), d = Math.floor(d / 2);

    for (a = 11; a; --a) e.push(c % 2 ? 1 : 0), c = Math.floor(c / 2);

    e.push(b ? 1 : 0);
    e.reverse();
    b = e.join("");
    c = "";

    for (a = 0; 64 > a; a += 8) d = parseInt(b.substr(a, 8), 2).toString(16), 1 === d.length && (d = "0" + d), c += d;

    return c.toLowerCase();
  }

  var Bd = /^-?\d{1,10}$/;

  function vd(a) {
    return Bd.test(a) && (a = Number(a), -2147483648 <= a && 2147483647 >= a) ? a : null;
  }

  function gc(a) {
    try {
      a();
    } catch (b) {
      setTimeout(function () {
        S("Exception was thrown by user callback.", b.stack || "");
        throw b;
      }, Math.floor(0));
    }
  }

  function T(a, b) {
    if (r(a)) {
      var c = Array.prototype.slice.call(arguments, 1).slice();
      gc(function () {
        a.apply(null, c);
      });
    }
  }

  ;

  function Cd(a) {
    var b = {},
        c = {},
        d = {},
        e = "";

    try {
      var f = a.split("."),
          b = Rb(kd(f[0]) || ""),
          c = Rb(kd(f[1]) || ""),
          e = f[2],
          d = c.d || {};
      delete c.d;
    } catch (g) {}

    return {
      mh: b,
      Ec: c,
      data: d,
      bh: e
    };
  }

  function Dd(a) {
    a = Cd(a).Ec;
    return "object" === typeof a && a.hasOwnProperty("iat") ? z(a, "iat") : null;
  }

  function Ed(a) {
    a = Cd(a);
    var b = a.Ec;
    return !!a.bh && !!b && "object" === typeof b && b.hasOwnProperty("iat");
  }

  ;

  function Fd(a) {
    (this || _global).Y = a;
    (this || _global).g = a.n.g;
  }

  function Gd(a, b, c, d) {
    var e = [],
        f = [];
    Ma(b, function (b) {
      "child_changed" === b.type && a.g.Dd(b.Oe, b.Na) && f.push(new J("child_moved", b.Na, b.Za));
    });
    Hd(a, e, "child_removed", b, d, c);
    Hd(a, e, "child_added", b, d, c);
    Hd(a, e, "child_moved", f, d, c);
    Hd(a, e, "child_changed", b, d, c);
    Hd(a, e, ic, b, d, c);
    return e;
  }

  function Hd(a, b, c, d, e, f) {
    d = Na(d, function (a) {
      return a.type === c;
    });
    Va(d, u(a.qg, a));
    Ma(d, function (c) {
      var d = Id(a, c, f);
      Ma(e, function (e) {
        e.Qf(c.type) && b.push(e.createEvent(d, a.Y));
      });
    });
  }

  function Id(a, b, c) {
    "value" !== b.type && "child_removed" !== b.type && (b.Td = c.wf(b.Za, b.Na, a.g));
    return b;
  }

  Fd.prototype.qg = function (a, b) {
    if (null == a.Za || null == b.Za) throw jd("Should only compare child_ events.");
    return (this || _global).g.compare(new L(a.Za, a.Na), new L(b.Za, b.Na));
  };

  function Jd() {
    (this || _global).ib = {};
  }

  function Kd(a, b) {
    var c = b.type,
        d = b.Za;
    O("child_added" == c || "child_changed" == c || "child_removed" == c, "Only child changes supported for tracking");
    O(".priority" !== d, "Only non-priority child changes can be tracked.");
    var e = z(a.ib, d);

    if (e) {
      var f = e.type;
      if ("child_added" == c && "child_removed" == f) a.ib[d] = new J("child_changed", b.Na, d, e.Na);else if ("child_removed" == c && "child_added" == f) delete a.ib[d];else if ("child_removed" == c && "child_changed" == f) a.ib[d] = new J("child_removed", e.Oe, d);else if ("child_changed" == c && "child_added" == f) a.ib[d] = new J("child_added", b.Na, d);else if ("child_changed" == c && "child_changed" == f) a.ib[d] = new J("child_changed", b.Na, d, e.Oe);else throw jd("Illegal combination of changes: " + b + " occurred after " + e);
    } else a.ib[d] = b;
  }

  ;

  function Ld(a) {
    (this || _global).g = a;
  }

  h = Ld.prototype;

  h.H = function (a, b, c, d, e, f) {
    O(a.Mc((this || _global).g), "A node must be indexed if only a child is updated");
    e = a.T(b);
    if (e.S(d).ea(c.S(d)) && e.e() == c.e()) return a;
    null != f && (c.e() ? a.Fa(b) ? Kd(f, new J("child_removed", e, b)) : O(a.L(), "A child remove without an old child only makes sense on a leaf node") : e.e() ? Kd(f, new J("child_added", c, b)) : Kd(f, new J("child_changed", c, b, e)));
    return a.L() && c.e() ? a : a.W(b, c).pb((this || _global).g);
  };

  h.ya = function (a, b, c) {
    null != c && (a.L() || a.R(R, function (a, e) {
      b.Fa(a) || Kd(c, new J("child_removed", e, a));
    }), b.L() || b.R(R, function (b, e) {
      if (a.Fa(b)) {
        var f = a.T(b);
        f.ea(e) || Kd(c, new J("child_changed", e, b, f));
      } else Kd(c, new J("child_added", e, b));
    }));
    return b.pb((this || _global).g);
  };

  h.ia = function (a, b) {
    return a.e() ? H : a.ia(b);
  };

  h.Ra = function () {
    return !1;
  };

  h.$b = function () {
    return this || _global;
  };

  function Md(a) {
    (this || _global).Fe = new Ld(a.g);
    (this || _global).g = a.g;
    var b;
    a.oa ? (b = Nd(a), b = a.g.Sc(Od(a), b)) : b = a.g.Wc();
    (this || _global).gd = b;
    a.ra ? (b = Pd(a), a = a.g.Sc(Rd(a), b)) : a = a.g.Tc();
    (this || _global).Jc = a;
  }

  h = Md.prototype;

  h.matches = function (a) {
    return 0 >= (this || _global).g.compare((this || _global).gd, a) && 0 >= (this || _global).g.compare(a, (this || _global).Jc);
  };

  h.H = function (a, b, c, d, e, f) {
    this.matches(new L(b, c)) || (c = H);
    return (this || _global).Fe.H(a, b, c, d, e, f);
  };

  h.ya = function (a, b, c) {
    b.L() && (b = H);
    var d = b.pb((this || _global).g),
        d = d.ia(H),
        e = this || _global;
    b.R(R, function (a, b) {
      e.matches(new L(a, b)) || (d = d.W(a, H));
    });
    return (this || _global).Fe.ya(a, d, c);
  };

  h.ia = function (a) {
    return a;
  };

  h.Ra = function () {
    return !0;
  };

  h.$b = function () {
    return (this || _global).Fe;
  };

  function Sd(a) {
    (this || _global).ua = new Md(a);
    (this || _global).g = a.g;
    O(a.la, "Only valid if limit has been set");
    (this || _global).ma = a.ma;
    (this || _global).Nb = !Td(a);
  }

  h = Sd.prototype;

  h.H = function (a, b, c, d, e, f) {
    (this || _global).ua.matches(new L(b, c)) || (c = H);
    return a.T(b).ea(c) ? a : a.Hb() < (this || _global).ma ? (this || _global).ua.$b().H(a, b, c, d, e, f) : Ud(this || _global, a, b, c, e, f);
  };

  h.ya = function (a, b, c) {
    var d;
    if (b.L() || b.e()) d = H.pb((this || _global).g);else if (2 * (this || _global).ma < b.Hb() && b.Mc((this || _global).g)) {
      d = H.pb((this || _global).g);
      b = (this || _global).Nb ? b.dc((this || _global).ua.Jc, (this || _global).g) : b.bc((this || _global).ua.gd, (this || _global).g);

      for (var e = 0; 0 < b.Ta.length && e < (this || _global).ma;) {
        var f = Ic(b),
            g;
        if (g = (this || _global).Nb ? 0 >= (this || _global).g.compare((this || _global).ua.gd, f) : 0 >= (this || _global).g.compare(f, (this || _global).ua.Jc)) d = d.W(f.name, f.U), e++;else break;
      }
    } else {
      d = b.pb((this || _global).g);
      d = d.ia(H);
      var k, m, l;

      if ((this || _global).Nb) {
        b = d.xf((this || _global).g);
        k = (this || _global).ua.Jc;
        m = (this || _global).ua.gd;
        var t = Vd((this || _global).g);

        l = function (a, b) {
          return t(b, a);
        };
      } else b = d.ac((this || _global).g), k = (this || _global).ua.gd, m = (this || _global).ua.Jc, l = Vd((this || _global).g);

      for (var e = 0, A = !1; 0 < b.Ta.length;) f = Ic(b), !A && 0 >= l(k, f) && (A = !0), (g = A && e < (this || _global).ma && 0 >= l(f, m)) ? e++ : d = d.W(f.name, H);
    }
    return (this || _global).ua.$b().ya(a, d, c);
  };

  h.ia = function (a) {
    return a;
  };

  h.Ra = function () {
    return !0;
  };

  h.$b = function () {
    return (this || _global).ua.$b();
  };

  function Ud(a, b, c, d, e, f) {
    var g;

    if (a.Nb) {
      var k = Vd(a.g);

      g = function (a, b) {
        return k(b, a);
      };
    } else g = Vd(a.g);

    O(b.Hb() == a.ma, "");
    var m = new L(c, d),
        l = a.Nb ? Wd(b, a.g) : Xd(b, a.g),
        t = a.ua.matches(m);

    if (b.Fa(c)) {
      for (var A = b.T(c), l = e.Ce(a.g, l, a.Nb); null != l && (l.name == c || b.Fa(l.name));) l = e.Ce(a.g, l, a.Nb);

      e = null == l ? 1 : g(l, m);
      if (t && !d.e() && 0 <= e) return null != f && Kd(f, new J("child_changed", d, c, A)), b.W(c, d);
      null != f && Kd(f, new J("child_removed", A, c));
      b = b.W(c, H);
      return null != l && a.ua.matches(l) ? (null != f && Kd(f, new J("child_added", l.U, l.name)), b.W(l.name, l.U)) : b;
    }

    return d.e() ? b : t && 0 <= g(l, m) ? (null != f && (Kd(f, new J("child_removed", l.U, l.name)), Kd(f, new J("child_added", d, c))), b.W(c, d).W(l.name, H)) : b;
  }

  ;

  function Yd(a, b) {
    (this || _global).me = a;
    (this || _global).og = b;
  }

  function Zd(a) {
    (this || _global).X = a;
  }

  Zd.prototype.gb = function (a, b, c, d) {
    var e = new Jd(),
        f;
    if (b.type === Bc) b.source.Ae ? c = $d(this || _global, a, b.path, b.Ja, c, d, e) : (O(b.source.tf, "Unknown source."), f = b.source.ef || mc(a.w()) && !b.path.e(), c = ae(this || _global, a, b.path, b.Ja, c, d, f, e));else if (b.type === be) b.source.Ae ? c = ce(this || _global, a, b.path, b.children, c, d, e) : (O(b.source.tf, "Unknown source."), f = b.source.ef || mc(a.w()), c = de(this || _global, a, b.path, b.children, c, d, f, e));else if (b.type === ee) {
      if (b.Yd) {
        if (b = b.path, null != c.xc(b)) c = a;else {
          f = new Vb(c, a, d);
          d = a.Q.j();
          if (b.e() || ".priority" === K(b)) lc(a.w()) ? b = c.Aa(Yb(a)) : (b = a.w().j(), O(b instanceof fe, "serverChildren would be complete if leaf node"), b = c.Cc(b)), b = (this || _global).X.ya(d, b, e);else {
            var g = K(b),
                k = c.Bc(g, a.w());
            null == k && Wb(a.w(), g) && (k = d.T(g));
            b = null != k ? (this || _global).X.H(d, g, k, N(b), f, e) : a.Q.j().Fa(g) ? (this || _global).X.H(d, g, H, N(b), f, e) : d;
            b.e() && lc(a.w()) && (d = c.Aa(Yb(a)), d.L() && (b = (this || _global).X.ya(b, d, e)));
          }
          d = lc(a.w()) || null != c.xc(M);
          c = ge(a, b, d, (this || _global).X.Ra());
        }
      } else c = he(this || _global, a, b.path, b.Ub, c, d, e);
    } else if (b.type === Dc) d = b.path, b = a.w(), f = b.j(), g = b.ga || d.e(), c = ie(this || _global, new je(a.Q, new Xb(f, g, b.Yb)), d, c, Ub, e);else throw jd("Unknown operation type: " + b.type);
    e = qa(e.ib);
    d = c;
    b = d.Q;
    b.ga && (f = b.j().L() || b.j().e(), g = ke(a), (0 < e.length || !a.Q.ga || f && !b.j().ea(g) || !b.j().C().ea(g.C())) && e.push(hc(ke(d))));
    return new Yd(c, e);
  };

  function ie(a, b, c, d, e, f) {
    var g = b.Q;
    if (null != d.xc(c)) return b;
    var k;
    if (c.e()) O(lc(b.w()), "If change path is empty, we must have complete server data"), mc(b.w()) ? (e = Yb(b), d = d.Cc(e instanceof fe ? e : H)) : d = d.Aa(Yb(b)), f = a.X.ya(b.Q.j(), d, f);else {
      var m = K(c);
      if (".priority" == m) O(1 == le(c), "Can't have a priority with additional path components"), f = g.j(), k = b.w().j(), d = d.nd(c, f, k), f = null != d ? a.X.ia(f, d) : g.j();else {
        var l = N(c);
        Wb(g, m) ? (k = b.w().j(), d = d.nd(c, g.j(), k), d = null != d ? g.j().T(m).H(l, d) : g.j().T(m)) : d = d.Bc(m, b.w());
        f = null != d ? a.X.H(g.j(), m, d, l, e, f) : g.j();
      }
    }
    return ge(b, f, g.ga || c.e(), a.X.Ra());
  }

  function ae(a, b, c, d, e, f, g, k) {
    var m = b.w();
    g = g ? a.X : a.X.$b();
    if (c.e()) d = g.ya(m.j(), d, null);else if (g.Ra() && !m.Yb) d = m.j().H(c, d), d = g.ya(m.j(), d, null);else {
      var l = K(c);
      if (!nc(m, c) && 1 < le(c)) return b;
      var t = N(c);
      d = m.j().T(l).H(t, d);
      d = ".priority" == l ? g.ia(m.j(), d) : g.H(m.j(), l, d, t, Ub, null);
    }
    m = m.ga || c.e();
    b = new je(b.Q, new Xb(d, m, g.Ra()));
    return ie(a, b, c, e, new Vb(e, b, f), k);
  }

  function $d(a, b, c, d, e, f, g) {
    var k = b.Q;
    e = new Vb(e, b, f);
    if (c.e()) g = a.X.ya(b.Q.j(), d, g), a = ge(b, g, !0, a.X.Ra());else if (f = K(c), ".priority" === f) g = a.X.ia(b.Q.j(), d), a = ge(b, g, k.ga, k.Yb);else {
      c = N(c);
      var m = k.j().T(f);

      if (!c.e()) {
        var l = e.uf(f);
        d = null != l ? ".priority" === me(c) && l.S(c.parent()).e() ? l : l.H(c, d) : H;
      }

      m.ea(d) ? a = b : (g = a.X.H(k.j(), f, d, c, e, g), a = ge(b, g, k.ga, a.X.Ra()));
    }
    return a;
  }

  function ce(a, b, c, d, e, f, g) {
    var k = b;
    ne(d, function (d, l) {
      var t = c.o(d);
      Wb(b.Q, K(t)) && (k = $d(a, k, t, l, e, f, g));
    });
    ne(d, function (d, l) {
      var t = c.o(d);
      Wb(b.Q, K(t)) || (k = $d(a, k, t, l, e, f, g));
    });
    return k;
  }

  function oe(a, b) {
    ne(b, function (b, d) {
      a = a.H(b, d);
    });
    return a;
  }

  function de(a, b, c, d, e, f, g, k) {
    if (b.w().j().e() && !lc(b.w())) return b;
    var m = b;
    c = c.e() ? d : pe(qe, c, d);
    var l = b.w().j();
    c.children.ka(function (c, d) {
      if (l.Fa(c)) {
        var I = b.w().j().T(c),
            I = oe(I, d);
        m = ae(a, m, new P(c), I, e, f, g, k);
      }
    });
    c.children.ka(function (c, d) {
      var I = !Wb(b.w(), c) && null == d.value;
      l.Fa(c) || I || (I = b.w().j().T(c), I = oe(I, d), m = ae(a, m, new P(c), I, e, f, g, k));
    });
    return m;
  }

  function he(a, b, c, d, e, f, g) {
    if (null != e.xc(c)) return b;
    var k = mc(b.w()),
        m = b.w();

    if (null != d.value) {
      if (c.e() && m.ga || nc(m, c)) return ae(a, b, c, m.j().S(c), e, f, k, g);

      if (c.e()) {
        var l = qe;
        m.j().R(re, function (a, b) {
          l = l.set(new P(a), b);
        });
        return de(a, b, c, l, e, f, k, g);
      }

      return b;
    }

    l = qe;
    ne(d, function (a) {
      var b = c.o(a);
      nc(m, b) && (l = l.set(a, m.j().S(b)));
    });
    return de(a, b, c, l, e, f, k, g);
  }

  ;

  function se() {}

  var te = {};

  function Vd(a) {
    return u(a.compare, a);
  }

  se.prototype.Dd = function (a, b) {
    return 0 !== this.compare(new L("[MIN_NAME]", a), new L("[MIN_NAME]", b));
  };

  se.prototype.Wc = function () {
    return ue;
  };

  function ve(a) {
    O(!a.e() && ".priority" !== K(a), "Can't create PathIndex with empty path or .priority key");
    (this || _global).gc = a;
  }

  ka(ve, se);
  h = ve.prototype;

  h.Lc = function (a) {
    return !a.S((this || _global).gc).e();
  };

  h.compare = function (a, b) {
    var c = a.U.S((this || _global).gc),
        d = b.U.S((this || _global).gc),
        c = c.Gc(d);
    return 0 === c ? yc(a.name, b.name) : c;
  };

  h.Sc = function (a, b) {
    var c = Q(a),
        c = H.H((this || _global).gc, c);
    return new L(b, c);
  };

  h.Tc = function () {
    var a = H.H((this || _global).gc, we);
    return new L("[MAX_NAME]", a);
  };

  h.toString = function () {
    return (this || _global).gc.slice().join("/");
  };

  function xe() {}

  ka(xe, se);
  h = xe.prototype;

  h.compare = function (a, b) {
    var c = a.U.C(),
        d = b.U.C(),
        c = c.Gc(d);
    return 0 === c ? yc(a.name, b.name) : c;
  };

  h.Lc = function (a) {
    return !a.C().e();
  };

  h.Dd = function (a, b) {
    return !a.C().ea(b.C());
  };

  h.Wc = function () {
    return ue;
  };

  h.Tc = function () {
    return new L("[MAX_NAME]", new Yc("[PRIORITY-POST]", we));
  };

  h.Sc = function (a, b) {
    var c = Q(a);
    return new L(b, new Yc("[PRIORITY-POST]", c));
  };

  h.toString = function () {
    return ".priority";
  };

  var R = new xe();

  function ye() {}

  ka(ye, se);
  h = ye.prototype;

  h.compare = function (a, b) {
    return yc(a.name, b.name);
  };

  h.Lc = function () {
    throw jd("KeyIndex.isDefinedOn not expected to be called.");
  };

  h.Dd = function () {
    return !1;
  };

  h.Wc = function () {
    return ue;
  };

  h.Tc = function () {
    return new L("[MAX_NAME]", H);
  };

  h.Sc = function (a) {
    O(q(a), "KeyIndex indexValue must always be a string.");
    return new L(a, H);
  };

  h.toString = function () {
    return ".key";
  };

  var re = new ye();

  function ze() {}

  ka(ze, se);
  h = ze.prototype;

  h.compare = function (a, b) {
    var c = a.U.Gc(b.U);
    return 0 === c ? yc(a.name, b.name) : c;
  };

  h.Lc = function () {
    return !0;
  };

  h.Dd = function (a, b) {
    return !a.ea(b);
  };

  h.Wc = function () {
    return ue;
  };

  h.Tc = function () {
    return Ae;
  };

  h.Sc = function (a, b) {
    var c = Q(a);
    return new L(b, c);
  };

  h.toString = function () {
    return ".value";
  };

  var Be = new ze();

  function Ce() {
    (this || _global).Xb = (this || _global).ra = (this || _global).Pb = (this || _global).oa = (this || _global).la = !1;
    (this || _global).ma = 0;
    (this || _global).Rb = "";
    (this || _global).ic = null;
    (this || _global).Bb = "";
    (this || _global).fc = null;
    (this || _global).zb = "";
    (this || _global).g = R;
  }

  var De = new Ce();

  function Td(a) {
    return "" === a.Rb ? a.oa : "l" === a.Rb;
  }

  function Od(a) {
    O(a.oa, "Only valid if start has been set");
    return a.ic;
  }

  function Nd(a) {
    O(a.oa, "Only valid if start has been set");
    return a.Pb ? a.Bb : "[MIN_NAME]";
  }

  function Rd(a) {
    O(a.ra, "Only valid if end has been set");
    return a.fc;
  }

  function Pd(a) {
    O(a.ra, "Only valid if end has been set");
    return a.Xb ? a.zb : "[MAX_NAME]";
  }

  function Ee(a) {
    var b = new Ce();
    b.la = a.la;
    b.ma = a.ma;
    b.oa = a.oa;
    b.ic = a.ic;
    b.Pb = a.Pb;
    b.Bb = a.Bb;
    b.ra = a.ra;
    b.fc = a.fc;
    b.Xb = a.Xb;
    b.zb = a.zb;
    b.g = a.g;
    return b;
  }

  h = Ce.prototype;

  h.Le = function (a) {
    var b = Ee(this || _global);
    b.la = !0;
    b.ma = a;
    b.Rb = "";
    return b;
  };

  h.Me = function (a) {
    var b = Ee(this || _global);
    b.la = !0;
    b.ma = a;
    b.Rb = "l";
    return b;
  };

  h.Ne = function (a) {
    var b = Ee(this || _global);
    b.la = !0;
    b.ma = a;
    b.Rb = "r";
    return b;
  };

  h.ce = function (a, b) {
    var c = Ee(this || _global);
    c.oa = !0;
    p(a) || (a = null);
    c.ic = a;
    null != b ? (c.Pb = !0, c.Bb = b) : (c.Pb = !1, c.Bb = "");
    return c;
  };

  h.vd = function (a, b) {
    var c = Ee(this || _global);
    c.ra = !0;
    p(a) || (a = null);
    c.fc = a;
    p(b) ? (c.Xb = !0, c.zb = b) : (c.oh = !1, c.zb = "");
    return c;
  };

  function Fe(a, b) {
    var c = Ee(a);
    c.g = b;
    return c;
  }

  function Ge(a) {
    var b = {};
    a.oa && (b.sp = a.ic, a.Pb && (b.sn = a.Bb));
    a.ra && (b.ep = a.fc, a.Xb && (b.en = a.zb));

    if (a.la) {
      b.l = a.ma;
      var c = a.Rb;
      "" === c && (c = Td(a) ? "l" : "r");
      b.vf = c;
    }

    a.g !== R && (b.i = a.g.toString());
    return b;
  }

  function He(a) {
    return !(a.oa || a.ra || a.la);
  }

  function Ie(a) {
    return He(a) && a.g == R;
  }

  function Je(a) {
    var b = {};
    if (Ie(a)) return b;
    var c;
    a.g === R ? c = "$priority" : a.g === Be ? c = "$value" : a.g === re ? c = "$key" : (O(a.g instanceof ve, "Unrecognized index type!"), c = a.g.toString());
    b.orderBy = G(c);
    a.oa && (b.startAt = G(a.ic), a.Pb && (b.startAt += "," + G(a.Bb)));
    a.ra && (b.endAt = G(a.fc), a.Xb && (b.endAt += "," + G(a.zb)));
    a.la && (Td(a) ? b.limitToFirst = a.ma : b.limitToLast = a.ma);
    return b;
  }

  h.toString = function () {
    return G(Ge(this || _global));
  };

  function Ke(a, b) {
    (this || _global).Ed = a;
    (this || _global).hc = b;
  }

  Ke.prototype.get = function (a) {
    var b = z((this || _global).Ed, a);
    if (!b) throw Error("No index defined for " + a);
    return b === te ? null : b;
  };

  function Le(a, b, c) {
    var d = ma(a.Ed, function (d, f) {
      var g = z(a.hc, f);
      O(g, "Missing index implementation for " + f);

      if (d === te) {
        if (g.Lc(b.U)) {
          for (var k = [], m = c.ac(wc), l = Ic(m); l;) l.name != b.name && k.push(l), l = Ic(m);

          k.push(b);
          return Me(k, Vd(g));
        }

        return te;
      }

      g = c.get(b.name);
      k = d;
      g && (k = k.remove(new L(b.name, g)));
      return k.Sa(b, b.U);
    });
    return new Ke(d, a.hc);
  }

  function Ne(a, b, c) {
    var d = ma(a.Ed, function (a) {
      if (a === te) return a;
      var d = c.get(b.name);
      return d ? a.remove(new L(b.name, d)) : a;
    });
    return new Ke(d, a.hc);
  }

  var Oe = new Ke({
    ".priority": te
  }, {
    ".priority": R
  });

  function Yc(a, b) {
    (this || _global).B = a;
    O(p((this || _global).B) && null !== (this || _global).B, "LeafNode shouldn't be created with null/undefined value.");
    (this || _global).ca = b || H;
    Pe((this || _global).ca);
    (this || _global).Gb = null;
  }

  var Qe = ["object", "boolean", "number", "string"];
  h = Yc.prototype;

  h.L = function () {
    return !0;
  };

  h.C = function () {
    return (this || _global).ca;
  };

  h.ia = function (a) {
    return new Yc((this || _global).B, a);
  };

  h.T = function (a) {
    return ".priority" === a ? (this || _global).ca : H;
  };

  h.S = function (a) {
    return a.e() ? this || _global : ".priority" === K(a) ? (this || _global).ca : H;
  };

  h.Fa = function () {
    return !1;
  };

  h.wf = function () {
    return null;
  };

  h.W = function (a, b) {
    return ".priority" === a ? this.ia(b) : b.e() && ".priority" !== a ? this || _global : H.W(a, b).ia((this || _global).ca);
  };

  h.H = function (a, b) {
    var c = K(a);
    if (null === c) return b;
    if (b.e() && ".priority" !== c) return this || _global;
    O(".priority" !== c || 1 === le(a), ".priority must be the last token in a path");
    return this.W(c, H.H(N(a), b));
  };

  h.e = function () {
    return !1;
  };

  h.Hb = function () {
    return 0;
  };

  h.R = function () {
    return !1;
  };

  h.J = function (a) {
    return a && !this.C().e() ? {
      ".value": this.Ea(),
      ".priority": this.C().J()
    } : this.Ea();
  };

  h.hash = function () {
    if (null === (this || _global).Gb) {
      var a = "";
      (this || _global).ca.e() || (a += "priority:" + Re((this || _global).ca.J()) + ":");
      var b = typeof (this || _global).B,
          a = a + (b + ":"),
          a = "number" === b ? a + Ad((this || _global).B) : a + (this || _global).B;
      (this || _global).Gb = ld(a);
    }

    return (this || _global).Gb;
  };

  h.Ea = function () {
    return (this || _global).B;
  };

  h.Gc = function (a) {
    if (a === H) return 1;
    if (a instanceof fe) return -1;
    O(a.L(), "Unknown node type");
    var b = typeof a.B,
        c = typeof (this || _global).B,
        d = La(Qe, b),
        e = La(Qe, c);
    O(0 <= d, "Unknown leaf type: " + b);
    O(0 <= e, "Unknown leaf type: " + c);
    return d === e ? "object" === c ? 0 : (this || _global).B < a.B ? -1 : (this || _global).B === a.B ? 0 : 1 : e - d;
  };

  h.pb = function () {
    return this || _global;
  };

  h.Mc = function () {
    return !0;
  };

  h.ea = function (a) {
    return a === (this || _global) ? !0 : a.L() ? (this || _global).B === a.B && (this || _global).ca.ea(a.ca) : !1;
  };

  h.toString = function () {
    return G(this.J(!0));
  };

  function fe(a, b, c) {
    (this || _global).m = a;
    ((this || _global).ca = b) && Pe((this || _global).ca);
    a.e() && O(!(this || _global).ca || (this || _global).ca.e(), "An empty node cannot have a priority");
    (this || _global).Ab = c;
    (this || _global).Gb = null;
  }

  h = fe.prototype;

  h.L = function () {
    return !1;
  };

  h.C = function () {
    return (this || _global).ca || H;
  };

  h.ia = function (a) {
    return (this || _global).m.e() ? this || _global : new fe((this || _global).m, a, (this || _global).Ab);
  };

  h.T = function (a) {
    if (".priority" === a) return this.C();
    a = (this || _global).m.get(a);
    return null === a ? H : a;
  };

  h.S = function (a) {
    var b = K(a);
    return null === b ? this || _global : this.T(b).S(N(a));
  };

  h.Fa = function (a) {
    return null !== (this || _global).m.get(a);
  };

  h.W = function (a, b) {
    O(b, "We should always be passing snapshot nodes");
    if (".priority" === a) return this.ia(b);
    var c = new L(a, b),
        d,
        e;
    b.e() ? (d = (this || _global).m.remove(a), c = Ne((this || _global).Ab, c, (this || _global).m)) : (d = (this || _global).m.Sa(a, b), c = Le((this || _global).Ab, c, (this || _global).m));
    e = d.e() ? H : (this || _global).ca;
    return new fe(d, e, c);
  };

  h.H = function (a, b) {
    var c = K(a);
    if (null === c) return b;
    O(".priority" !== K(a) || 1 === le(a), ".priority must be the last token in a path");
    var d = this.T(c).H(N(a), b);
    return this.W(c, d);
  };

  h.e = function () {
    return (this || _global).m.e();
  };

  h.Hb = function () {
    return (this || _global).m.count();
  };

  var Se = /^(0|[1-9]\d*)$/;
  h = fe.prototype;

  h.J = function (a) {
    if (this.e()) return null;
    var b = {},
        c = 0,
        d = 0,
        e = !0;
    this.R(R, function (f, g) {
      b[f] = g.J(a);
      c++;
      e && Se.test(f) ? d = Math.max(d, Number(f)) : e = !1;
    });

    if (!a && e && d < 2 * c) {
      var f = [],
          g;

      for (g in b) f[g] = b[g];

      return f;
    }

    a && !this.C().e() && (b[".priority"] = this.C().J());
    return b;
  };

  h.hash = function () {
    if (null === (this || _global).Gb) {
      var a = "";
      this.C().e() || (a += "priority:" + Re(this.C().J()) + ":");
      this.R(R, function (b, c) {
        var d = c.hash();
        "" !== d && (a += ":" + b + ":" + d);
      });
      (this || _global).Gb = "" === a ? "" : ld(a);
    }

    return (this || _global).Gb;
  };

  h.wf = function (a, b, c) {
    return (c = Te(this || _global, c)) ? (a = Gc(c, new L(a, b))) ? a.name : null : Gc((this || _global).m, a);
  };

  function Wd(a, b) {
    var c;
    c = (c = Te(a, b)) ? (c = c.Vc()) && c.name : a.m.Vc();
    return c ? new L(c, a.m.get(c)) : null;
  }

  function Xd(a, b) {
    var c;
    c = (c = Te(a, b)) ? (c = c.jc()) && c.name : a.m.jc();
    return c ? new L(c, a.m.get(c)) : null;
  }

  h.R = function (a, b) {
    var c = Te(this || _global, a);
    return c ? c.ka(function (a) {
      return b(a.name, a.U);
    }) : (this || _global).m.ka(b);
  };

  h.ac = function (a) {
    return this.bc(a.Wc(), a);
  };

  h.bc = function (a, b) {
    var c = Te(this || _global, b);
    if (c) return c.bc(a, function (a) {
      return a;
    });

    for (var c = (this || _global).m.bc(a.name, wc), d = Jc(c); null != d && 0 > b.compare(d, a);) Ic(c), d = Jc(c);

    return c;
  };

  h.xf = function (a) {
    return this.dc(a.Tc(), a);
  };

  h.dc = function (a, b) {
    var c = Te(this || _global, b);
    if (c) return c.dc(a, function (a) {
      return a;
    });

    for (var c = (this || _global).m.dc(a.name, wc), d = Jc(c); null != d && 0 < b.compare(d, a);) Ic(c), d = Jc(c);

    return c;
  };

  h.Gc = function (a) {
    return this.e() ? a.e() ? 0 : -1 : a.L() || a.e() ? 1 : a === we ? -1 : 0;
  };

  h.pb = function (a) {
    if (a === re || sa((this || _global).Ab.hc, a.toString())) return this || _global;
    var b = (this || _global).Ab,
        c = (this || _global).m;
    O(a !== re, "KeyIndex always exists and isn't meant to be added to the IndexMap.");

    for (var d = [], e = !1, c = c.ac(wc), f = Ic(c); f;) e = e || a.Lc(f.U), d.push(f), f = Ic(c);

    d = e ? Me(d, Vd(a)) : te;
    e = a.toString();
    c = wa(b.hc);
    c[e] = a;
    a = wa(b.Ed);
    a[e] = d;
    return new fe((this || _global).m, (this || _global).ca, new Ke(a, c));
  };

  h.Mc = function (a) {
    return a === re || sa((this || _global).Ab.hc, a.toString());
  };

  h.ea = function (a) {
    if (a === (this || _global)) return !0;
    if (a.L()) return !1;

    if (this.C().ea(a.C()) && (this || _global).m.count() === a.m.count()) {
      var b = this.ac(R);
      a = a.ac(R);

      for (var c = Ic(b), d = Ic(a); c && d;) {
        if (c.name !== d.name || !c.U.ea(d.U)) return !1;
        c = Ic(b);
        d = Ic(a);
      }

      return null === c && null === d;
    }

    return !1;
  };

  function Te(a, b) {
    return b === re ? null : a.Ab.get(b.toString());
  }

  h.toString = function () {
    return G(this.J(!0));
  };

  function Q(a, b) {
    if (null === a) return H;
    var c = null;
    "object" === typeof a && ".priority" in a ? c = a[".priority"] : "undefined" !== typeof b && (c = b);
    O(null === c || "string" === typeof c || "number" === typeof c || "object" === typeof c && ".sv" in c, "Invalid priority type found: " + typeof c);
    "object" === typeof a && ".value" in a && null !== a[".value"] && (a = a[".value"]);
    if ("object" !== typeof a || ".sv" in a) return new Yc(a, Q(c));

    if (a instanceof Array) {
      var d = H,
          e = a;
      v(e, function (a, b) {
        if (y(e, b) && "." !== b.substring(0, 1)) {
          var c = Q(a);
          if (c.L() || !c.e()) d = d.W(b, c);
        }
      });
      return d.ia(Q(c));
    }

    var f = [],
        g = !1,
        k = a;
    Fb(k, function (a) {
      if ("string" !== typeof a || "." !== a.substring(0, 1)) {
        var b = Q(k[a]);
        b.e() || (g = g || !b.C().e(), f.push(new L(a, b)));
      }
    });
    if (0 == f.length) return H;
    var m = Me(f, xc, function (a) {
      return a.name;
    }, zc);

    if (g) {
      var l = Me(f, Vd(R));
      return new fe(m, Q(c), new Ke({
        ".priority": l
      }, {
        ".priority": R
      }));
    }

    return new fe(m, Q(c), Oe);
  }

  var Ue = Math.log(2);

  function Ve(a) {
    (this || _global).count = parseInt(Math.log(a + 1) / Ue, 10);
    (this || _global).nf = (this || _global).count - 1;
    (this || _global).ng = a + 1 & parseInt(Array((this || _global).count + 1).join("1"), 2);
  }

  function We(a) {
    var b = !(a.ng & 1 << a.nf);
    a.nf--;
    return b;
  }

  function Me(a, b, c, d) {
    function e(b, d) {
      var f = d - b;
      if (0 == f) return null;

      if (1 == f) {
        var l = a[b],
            t = c ? c(l) : l;
        return new Kc(t, l.U, !1, null, null);
      }

      var l = parseInt(f / 2, 10) + b,
          f = e(b, l),
          A = e(l + 1, d),
          l = a[l],
          t = c ? c(l) : l;
      return new Kc(t, l.U, !1, f, A);
    }

    a.sort(b);

    var f = function (b) {
      function d(b, g) {
        var k = t - b,
            A = t;
        t -= b;
        var A = e(k + 1, A),
            k = a[k],
            I = c ? c(k) : k,
            A = new Kc(I, k.U, g, null, A);
        f ? f.left = A : l = A;
        f = A;
      }

      for (var f = null, l = null, t = a.length, A = 0; A < b.count; ++A) {
        var I = We(b),
            Qd = Math.pow(2, b.count - (A + 1));
        I ? d(Qd, !1) : (d(Qd, !1), d(Qd, !0));
      }

      return l;
    }(new Ve(a.length));

    return null !== f ? new Ec(d || b, f) : new Ec(d || b);
  }

  function Re(a) {
    return "number" === typeof a ? "number:" + Ad(a) : "string:" + a;
  }

  function Pe(a) {
    if (a.L()) {
      var b = a.J();
      O("string" === typeof b || "number" === typeof b || "object" === typeof b && y(b, ".sv"), "Priority must be a string or number.");
    } else O(a === we || a.e(), "priority of unexpected type.");

    O(a === we || a.C().e(), "Priority nodes can't have a priority of their own.");
  }

  var H = new fe(new Ec(zc), null, Oe);

  function Xe() {
    fe.call(this || _global, new Ec(zc), H, Oe);
  }

  ka(Xe, fe);
  h = Xe.prototype;

  h.Gc = function (a) {
    return a === (this || _global) ? 0 : 1;
  };

  h.ea = function (a) {
    return a === (this || _global);
  };

  h.C = function () {
    return this || _global;
  };

  h.T = function () {
    return H;
  };

  h.e = function () {
    return !1;
  };

  var we = new Xe(),
      ue = new L("[MIN_NAME]", H),
      Ae = new L("[MAX_NAME]", we);

  function je(a, b) {
    (this || _global).Q = a;
    (this || _global).ae = b;
  }

  function ge(a, b, c, d) {
    return new je(new Xb(b, c, d), a.ae);
  }

  function ke(a) {
    return a.Q.ga ? a.Q.j() : null;
  }

  je.prototype.w = function () {
    return (this || _global).ae;
  };

  function Yb(a) {
    return a.ae.ga ? a.ae.j() : null;
  }

  ;

  function Ye(a, b) {
    (this || _global).Y = a;
    var c = a.n,
        d = new Ld(c.g),
        c = He(c) ? new Ld(c.g) : c.la ? new Sd(c) : new Md(c);
    (this || _global).Nf = new Zd(c);
    var e = b.w(),
        f = b.Q,
        g = d.ya(H, e.j(), null),
        k = c.ya(H, f.j(), null);
    (this || _global).Oa = new je(new Xb(k, f.ga, c.Ra()), new Xb(g, e.ga, d.Ra()));
    (this || _global).$a = [];
    (this || _global).ug = new Fd(a);
  }

  function Ze(a) {
    return a.Y;
  }

  h = Ye.prototype;

  h.w = function () {
    return (this || _global).Oa.w().j();
  };

  h.kb = function (a) {
    var b = Yb((this || _global).Oa);
    return b && (He((this || _global).Y.n) || !a.e() && !b.T(K(a)).e()) ? b.S(a) : null;
  };

  h.e = function () {
    return 0 === (this || _global).$a.length;
  };

  h.Tb = function (a) {
    (this || _global).$a.push(a);
  };

  h.nb = function (a, b) {
    var c = [];

    if (b) {
      O(null == a, "A cancel should cancel all event registrations.");
      var d = (this || _global).Y.path;
      Ma((this || _global).$a, function (a) {
        (a = a.lf(b, d)) && c.push(a);
      });
    }

    if (a) {
      for (var e = [], f = 0; f < (this || _global).$a.length; ++f) {
        var g = (this || _global).$a[f];
        if (!g.matches(a)) e.push(g);else if (a.yf()) {
          e = e.concat((this || _global).$a.slice(f + 1));
          break;
        }
      }

      (this || _global).$a = e;
    } else (this || _global).$a = [];

    return c;
  };

  h.gb = function (a, b, c) {
    a.type === be && null !== a.source.Lb && (O(Yb((this || _global).Oa), "We should always have a full cache before handling merges"), O(ke((this || _global).Oa), "Missing event cache, even though we have a server cache"));
    var d = (this || _global).Oa;
    a = (this || _global).Nf.gb(d, a, b, c);
    b = (this || _global).Nf;
    c = a.me;
    O(c.Q.j().Mc(b.X.g), "Event snap not indexed");
    O(c.w().j().Mc(b.X.g), "Server snap not indexed");
    O(lc(a.me.w()) || !lc(d.w()), "Once a server snap is complete, it should never go back");
    (this || _global).Oa = a.me;
    return $e(this || _global, a.og, a.me.Q.j(), null);
  };

  function af(a, b) {
    var c = a.Oa.Q,
        d = [];
    c.j().L() || c.j().R(R, function (a, b) {
      d.push(new J("child_added", b, a));
    });
    c.ga && d.push(hc(c.j()));
    return $e(a, d, c.j(), b);
  }

  function $e(a, b, c, d) {
    return Gd(a.ug, b, c, d ? [d] : a.$a);
  }

  ;

  function bf(a, b, c) {
    (this || _global).type = be;
    (this || _global).source = a;
    (this || _global).path = b;
    (this || _global).children = c;
  }

  bf.prototype.$c = function (a) {
    if ((this || _global).path.e()) return a = (this || _global).children.subtree(new P(a)), a.e() ? null : a.value ? new Ac((this || _global).source, M, a.value) : new bf((this || _global).source, M, a);
    O(K((this || _global).path) === a, "Can't get a merge for a child not on the path of the operation");
    return new bf((this || _global).source, N((this || _global).path), (this || _global).children);
  };

  bf.prototype.toString = function () {
    return "Operation(" + (this || _global).path + ": " + (this || _global).source.toString() + " merge: " + (this || _global).children.toString() + ")";
  };

  function cf(a, b) {
    (this || _global).f = pd("p:rest:");
    (this || _global).G = a;
    (this || _global).Kb = b;
    (this || _global).Ca = null;
    (this || _global).ba = {};
  }

  function df(a, b) {
    if (p(b)) return "tag$" + b;
    O(Ie(a.n), "should have a tag if it's not a default query.");
    return a.path.toString();
  }

  h = cf.prototype;

  h.Cf = function (a, b, c, d) {
    var e = a.path.toString();
    this.f("Listen called for " + e + " " + a.wa());
    var f = df(a, c),
        g = {};
    (this || _global).ba[f] = g;
    a = Je(a.n);
    var k = this || _global;
    ef(this || _global, e + ".json", a, function (a, b) {
      var t = b;
      404 === a && (a = t = null);
      null === a && k.Kb(e, t, !1, c);
      z(k.ba, f) === g && d(a ? 401 == a ? "permission_denied" : "rest_error:" + a : "ok", null);
    });
  };

  h.$f = function (a, b) {
    var c = df(a, b);
    delete (this || _global).ba[c];
  };

  h.O = function (a, b) {
    (this || _global).Ca = a;
    var c = Cd(a),
        d = c.data,
        c = c.Ec && c.Ec.exp;
    b && b("ok", {
      auth: d,
      expires: c
    });
  };

  h.je = function (a) {
    (this || _global).Ca = null;
    a("ok", null);
  };

  h.Qe = function () {};

  h.Gf = function () {};

  h.Md = function () {};

  h.put = function () {};

  h.Df = function () {};

  h.Ye = function () {};

  function ef(a, b, c, d) {
    c = c || {};
    c.format = "export";
    a.Ca && (c.auth = a.Ca);
    var e = (a.G.ob ? "https://" : "http://") + a.G.host + b + "?" + Ib(c);
    a.f("Sending REST request for " + e);
    var f = new XMLHttpRequest();

    f.onreadystatechange = function () {
      if (d && 4 === f.readyState) {
        a.f("REST Response for " + e + " received. status:", f.status, "response:", f.responseText);
        var b = null;

        if (200 <= f.status && 300 > f.status) {
          try {
            b = Rb(f.responseText);
          } catch (c) {
            S("Failed to parse JSON response for " + e + ": " + f.responseText);
          }

          d(null, b);
        } else 401 !== f.status && 404 !== f.status && S("Got unsuccessful REST response for " + e + " Status: " + f.status), d(f.status);

        d = null;
      }
    };

    f.open("GET", e, !0);
    f.send();
  }

  ;

  function ff(a) {
    O(da(a) && 0 < a.length, "Requires a non-empty array");
    (this || _global).fg = a;
    (this || _global).Rc = {};
  }

  ff.prototype.ie = function (a, b) {
    var c;
    c = (this || _global).Rc[a] || [];
    var d = c.length;

    if (0 < d) {
      for (var e = Array(d), f = 0; f < d; f++) e[f] = c[f];

      c = e;
    } else c = [];

    for (d = 0; d < c.length; d++) c[d].Dc.apply(c[d].Qa, Array.prototype.slice.call(arguments, 1));
  };

  ff.prototype.Ib = function (a, b, c) {
    gf(this || _global, a);
    (this || _global).Rc[a] = (this || _global).Rc[a] || [];

    (this || _global).Rc[a].push({
      Dc: b,
      Qa: c
    });

    (a = this.Ee(a)) && b.apply(c, a);
  };

  ff.prototype.mc = function (a, b, c) {
    gf(this || _global, a);
    a = (this || _global).Rc[a] || [];

    for (var d = 0; d < a.length; d++) if (a[d].Dc === b && (!c || c === a[d].Qa)) {
      a.splice(d, 1);
      break;
    }
  };

  function gf(a, b) {
    O(Ra(a.fg, function (a) {
      return a === b;
    }), "Unknown event: " + b);
  }

  ;

  var hf = function () {
    var a = 0,
        b = [];
    return function (c) {
      var d = c === a;
      a = c;

      for (var e = Array(8), f = 7; 0 <= f; f--) e[f] = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz".charAt(c % 64), c = Math.floor(c / 64);

      O(0 === c, "Cannot push at time == 0");
      c = e.join("");

      if (d) {
        for (f = 11; 0 <= f && 63 === b[f]; f--) b[f] = 0;

        b[f]++;
      } else for (f = 0; 12 > f; f++) b[f] = Math.floor(64 * Math.random());

      for (f = 0; 12 > f; f++) c += "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz".charAt(b[f]);

      O(20 === c.length, "nextPushId: Length should be 20.");
      return c;
    };
  }();

  function jf() {
    ff.call(this || _global, ["online"]);
    (this || _global).oc = !0;

    if ("undefined" !== typeof window && "undefined" !== typeof window.addEventListener) {
      var a = this || _global;
      window.addEventListener("online", function () {
        a.oc || (a.oc = !0, a.ie("online", !0));
      }, !1);
      window.addEventListener("offline", function () {
        a.oc && (a.oc = !1, a.ie("online", !1));
      }, !1);
    }
  }

  ka(jf, ff);

  jf.prototype.Ee = function (a) {
    O("online" === a, "Unknown event type: " + a);
    return [(this || _global).oc];
  };

  ba(jf);

  function kf() {
    ff.call(this || _global, ["visible"]);
    var a, b;
    "undefined" !== typeof document && "undefined" !== typeof document.addEventListener && ("undefined" !== typeof document.hidden ? (b = "visibilitychange", a = "hidden") : "undefined" !== typeof document.mozHidden ? (b = "mozvisibilitychange", a = "mozHidden") : "undefined" !== typeof document.msHidden ? (b = "msvisibilitychange", a = "msHidden") : "undefined" !== typeof document.webkitHidden && (b = "webkitvisibilitychange", a = "webkitHidden"));
    (this || _global).Sb = !0;

    if (b) {
      var c = this || _global;
      document.addEventListener(b, function () {
        var b = !document[a];
        b !== c.Sb && (c.Sb = b, c.ie("visible", b));
      }, !1);
    }
  }

  ka(kf, ff);

  kf.prototype.Ee = function (a) {
    O("visible" === a, "Unknown event type: " + a);
    return [(this || _global).Sb];
  };

  ba(kf);

  function P(a, b) {
    if (1 == arguments.length) {
      (this || _global).u = a.split("/");

      for (var c = 0, d = 0; d < (this || _global).u.length; d++) 0 < (this || _global).u[d].length && ((this || _global).u[c] = (this || _global).u[d], c++);

      (this || _global).u.length = c;
      (this || _global).aa = 0;
    } else (this || _global).u = a, (this || _global).aa = b;
  }

  function lf(a, b) {
    var c = K(a);
    if (null === c) return b;
    if (c === K(b)) return lf(N(a), N(b));
    throw Error("INTERNAL ERROR: innerPath (" + b + ") is not within outerPath (" + a + ")");
  }

  function mf(a, b) {
    for (var c = a.slice(), d = b.slice(), e = 0; e < c.length && e < d.length; e++) {
      var f = yc(c[e], d[e]);
      if (0 !== f) return f;
    }

    return c.length === d.length ? 0 : c.length < d.length ? -1 : 1;
  }

  function K(a) {
    return a.aa >= a.u.length ? null : a.u[a.aa];
  }

  function le(a) {
    return a.u.length - a.aa;
  }

  function N(a) {
    var b = a.aa;
    b < a.u.length && b++;
    return new P(a.u, b);
  }

  function me(a) {
    return a.aa < a.u.length ? a.u[a.u.length - 1] : null;
  }

  h = P.prototype;

  h.toString = function () {
    for (var a = "", b = (this || _global).aa; b < (this || _global).u.length; b++) "" !== (this || _global).u[b] && (a += "/" + (this || _global).u[b]);

    return a || "/";
  };

  h.slice = function (a) {
    return (this || _global).u.slice((this || _global).aa + (a || 0));
  };

  h.parent = function () {
    if ((this || _global).aa >= (this || _global).u.length) return null;

    for (var a = [], b = (this || _global).aa; b < (this || _global).u.length - 1; b++) a.push((this || _global).u[b]);

    return new P(a, 0);
  };

  h.o = function (a) {
    for (var b = [], c = (this || _global).aa; c < (this || _global).u.length; c++) b.push((this || _global).u[c]);

    if (a instanceof P) for (c = a.aa; c < a.u.length; c++) b.push(a.u[c]);else for (a = a.split("/"), c = 0; c < a.length; c++) 0 < a[c].length && b.push(a[c]);
    return new P(b, 0);
  };

  h.e = function () {
    return (this || _global).aa >= (this || _global).u.length;
  };

  h.ea = function (a) {
    if (le(this || _global) !== le(a)) return !1;

    for (var b = (this || _global).aa, c = a.aa; b <= (this || _global).u.length; b++, c++) if ((this || _global).u[b] !== a.u[c]) return !1;

    return !0;
  };

  h.contains = function (a) {
    var b = (this || _global).aa,
        c = a.aa;
    if (le(this || _global) > le(a)) return !1;

    for (; b < (this || _global).u.length;) {
      if ((this || _global).u[b] !== a.u[c]) return !1;
      ++b;
      ++c;
    }

    return !0;
  };

  var M = new P("");

  function nf(a, b) {
    (this || _global).Ua = a.slice();
    (this || _global).Ka = Math.max(1, (this || _global).Ua.length);
    (this || _global).pf = b;

    for (var c = 0; c < (this || _global).Ua.length; c++) (this || _global).Ka += Pb((this || _global).Ua[c]);

    of(this || _global);
  }

  nf.prototype.push = function (a) {
    0 < (this || _global).Ua.length && ((this || _global).Ka += 1);

    (this || _global).Ua.push(a);

    (this || _global).Ka += Pb(a);
    of(this || _global);
  };

  nf.prototype.pop = function () {
    var a = (this || _global).Ua.pop();

    (this || _global).Ka -= Pb(a);
    0 < (this || _global).Ua.length && --(this || _global).Ka;
  };

  function of(a) {
    if (768 < a.Ka) throw Error(a.pf + "has a key path longer than 768 bytes (" + a.Ka + ").");
    if (32 < a.Ua.length) throw Error(a.pf + "path specified exceeds the maximum depth that can be written (32) or object contains a cycle " + pf(a));
  }

  function pf(a) {
    return 0 == a.Ua.length ? "" : "in property '" + a.Ua.join(".") + "'";
  }

  ;

  function qf(a, b) {
    (this || _global).value = a;
    (this || _global).children = b || rf;
  }

  var rf = new Ec(function (a, b) {
    return a === b ? 0 : a < b ? -1 : 1;
  });

  function sf(a) {
    var b = qe;
    v(a, function (a, d) {
      b = b.set(new P(d), a);
    });
    return b;
  }

  h = qf.prototype;

  h.e = function () {
    return null === (this || _global).value && (this || _global).children.e();
  };

  function tf(a, b, c) {
    if (null != a.value && c(a.value)) return {
      path: M,
      value: a.value
    };
    if (b.e()) return null;
    var d = K(b);
    a = a.children.get(d);
    return null !== a ? (b = tf(a, N(b), c), null != b ? {
      path: new P(d).o(b.path),
      value: b.value
    } : null) : null;
  }

  function uf(a, b) {
    return tf(a, b, function () {
      return !0;
    });
  }

  h.subtree = function (a) {
    if (a.e()) return this || _global;

    var b = (this || _global).children.get(K(a));

    return null !== b ? b.subtree(N(a)) : qe;
  };

  h.set = function (a, b) {
    if (a.e()) return new qf(b, (this || _global).children);

    var c = K(a),
        d = ((this || _global).children.get(c) || qe).set(N(a), b),
        c = (this || _global).children.Sa(c, d);

    return new qf((this || _global).value, c);
  };

  h.remove = function (a) {
    if (a.e()) return (this || _global).children.e() ? qe : new qf(null, (this || _global).children);

    var b = K(a),
        c = (this || _global).children.get(b);

    return c ? (a = c.remove(N(a)), b = a.e() ? (this || _global).children.remove(b) : (this || _global).children.Sa(b, a), null === (this || _global).value && b.e() ? qe : new qf((this || _global).value, b)) : this || _global;
  };

  h.get = function (a) {
    if (a.e()) return (this || _global).value;

    var b = (this || _global).children.get(K(a));

    return b ? b.get(N(a)) : null;
  };

  function pe(a, b, c) {
    if (b.e()) return c;
    var d = K(b);
    b = pe(a.children.get(d) || qe, N(b), c);
    d = b.e() ? a.children.remove(d) : a.children.Sa(d, b);
    return new qf(a.value, d);
  }

  function vf(a, b) {
    return wf(a, M, b);
  }

  function wf(a, b, c) {
    var d = {};
    a.children.ka(function (a, f) {
      d[a] = wf(f, b.o(a), c);
    });
    return c(b, a.value, d);
  }

  function xf(a, b, c) {
    return yf(a, b, M, c);
  }

  function yf(a, b, c, d) {
    var e = a.value ? d(c, a.value) : !1;
    if (e) return e;
    if (b.e()) return null;
    e = K(b);
    return (a = a.children.get(e)) ? yf(a, N(b), c.o(e), d) : null;
  }

  function zf(a, b, c) {
    Af(a, b, M, c);
  }

  function Af(a, b, c, d) {
    if (b.e()) return a;
    a.value && d(c, a.value);
    var e = K(b);
    return (a = a.children.get(e)) ? Af(a, N(b), c.o(e), d) : qe;
  }

  function ne(a, b) {
    Bf(a, M, b);
  }

  function Bf(a, b, c) {
    a.children.ka(function (a, e) {
      Bf(e, b.o(a), c);
    });
    a.value && c(b, a.value);
  }

  function Cf(a, b) {
    a.children.ka(function (a, d) {
      d.value && b(a, d.value);
    });
  }

  var qe = new qf(null);

  qf.prototype.toString = function () {
    var a = {};
    ne(this || _global, function (b, c) {
      a[b.toString()] = c.toString();
    });
    return G(a);
  };

  function Df(a, b, c) {
    (this || _global).type = ee;
    (this || _global).source = Ef;
    (this || _global).path = a;
    (this || _global).Ub = b;
    (this || _global).Yd = c;
  }

  Df.prototype.$c = function (a) {
    if ((this || _global).path.e()) {
      if (null != (this || _global).Ub.value) return O((this || _global).Ub.children.e(), "affectedTree should not have overlapping affected paths."), this || _global;
      a = (this || _global).Ub.subtree(new P(a));
      return new Df(M, a, (this || _global).Yd);
    }

    O(K((this || _global).path) === a, "operationForChild called for unrelated child.");
    return new Df(N((this || _global).path), (this || _global).Ub, (this || _global).Yd);
  };

  Df.prototype.toString = function () {
    return "Operation(" + (this || _global).path + ": " + (this || _global).source.toString() + " ack write revert=" + (this || _global).Yd + " affectedTree=" + (this || _global).Ub + ")";
  };

  var Bc = 0,
      be = 1,
      ee = 2,
      Dc = 3;

  function Ff(a, b, c, d) {
    (this || _global).Ae = a;
    (this || _global).tf = b;
    (this || _global).Lb = c;
    (this || _global).ef = d;
    O(!d || b, "Tagged queries must be from server.");
  }

  var Ef = new Ff(!0, !1, null, !1),
      Gf = new Ff(!1, !0, null, !1);

  Ff.prototype.toString = function () {
    return (this || _global).Ae ? "user" : (this || _global).ef ? "server(queryID=" + (this || _global).Lb + ")" : "server";
  };

  function Hf(a) {
    (this || _global).Z = a;
  }

  var If = new Hf(new qf(null));

  function Jf(a, b, c) {
    if (b.e()) return new Hf(new qf(c));
    var d = uf(a.Z, b);

    if (null != d) {
      var e = d.path,
          d = d.value;
      b = lf(e, b);
      d = d.H(b, c);
      return new Hf(a.Z.set(e, d));
    }

    a = pe(a.Z, b, new qf(c));
    return new Hf(a);
  }

  function Kf(a, b, c) {
    var d = a;
    Fb(c, function (a, c) {
      d = Jf(d, b.o(a), c);
    });
    return d;
  }

  Hf.prototype.Ud = function (a) {
    if (a.e()) return If;
    a = pe((this || _global).Z, a, qe);
    return new Hf(a);
  };

  function Lf(a, b) {
    var c = uf(a.Z, b);
    return null != c ? a.Z.get(c.path).S(lf(c.path, b)) : null;
  }

  function Mf(a) {
    var b = [],
        c = a.Z.value;
    null != c ? c.L() || c.R(R, function (a, c) {
      b.push(new L(a, c));
    }) : a.Z.children.ka(function (a, c) {
      null != c.value && b.push(new L(a, c.value));
    });
    return b;
  }

  function Nf(a, b) {
    if (b.e()) return a;
    var c = Lf(a, b);
    return null != c ? new Hf(new qf(c)) : new Hf(a.Z.subtree(b));
  }

  Hf.prototype.e = function () {
    return (this || _global).Z.e();
  };

  Hf.prototype.apply = function (a) {
    return Of(M, (this || _global).Z, a);
  };

  function Of(a, b, c) {
    if (null != b.value) return c.H(a, b.value);
    var d = null;
    b.children.ka(function (b, f) {
      ".priority" === b ? (O(null !== f.value, "Priority writes must always be leaf nodes"), d = f.value) : c = Of(a.o(b), f, c);
    });
    c.S(a).e() || null === d || (c = c.H(a.o(".priority"), d));
    return c;
  }

  ;

  function Pf() {
    (this || _global).V = If;
    (this || _global).pa = [];
    (this || _global).Pc = -1;
  }

  function Qf(a, b) {
    for (var c = 0; c < a.pa.length; c++) {
      var d = a.pa[c];
      if (d.md === b) return d;
    }

    return null;
  }

  h = Pf.prototype;

  h.Ud = function (a) {
    var b = Sa((this || _global).pa, function (b) {
      return b.md === a;
    });
    O(0 <= b, "removeWrite called with nonexistent writeId.");
    var c = (this || _global).pa[b];

    (this || _global).pa.splice(b, 1);

    for (var d = c.visible, e = !1, f = (this || _global).pa.length - 1; d && 0 <= f;) {
      var g = (this || _global).pa[f];
      g.visible && (f >= b && Rf(g, c.path) ? d = !1 : c.path.contains(g.path) && (e = !0));
      f--;
    }

    if (d) {
      if (e) (this || _global).V = Sf((this || _global).pa, Tf, M), (this || _global).Pc = 0 < (this || _global).pa.length ? (this || _global).pa[(this || _global).pa.length - 1].md : -1;else if (c.Ja) (this || _global).V = (this || _global).V.Ud(c.path);else {
        var k = this || _global;
        v(c.children, function (a, b) {
          k.V = k.V.Ud(c.path.o(b));
        });
      }
      return !0;
    }

    return !1;
  };

  h.Aa = function (a, b, c, d) {
    if (c || d) {
      var e = Nf((this || _global).V, a);
      return !d && e.e() ? b : d || null != b || null != Lf(e, M) ? (e = Sf((this || _global).pa, function (b) {
        return (b.visible || d) && (!c || !(0 <= La(c, b.md))) && (b.path.contains(a) || a.contains(b.path));
      }, a), b = b || H, e.apply(b)) : null;
    }

    e = Lf((this || _global).V, a);
    if (null != e) return e;
    e = Nf((this || _global).V, a);
    return e.e() ? b : null != b || null != Lf(e, M) ? (b = b || H, e.apply(b)) : null;
  };

  h.Cc = function (a, b) {
    var c = H,
        d = Lf((this || _global).V, a);
    if (d) d.L() || d.R(R, function (a, b) {
      c = c.W(a, b);
    });else if (b) {
      var e = Nf((this || _global).V, a);
      b.R(R, function (a, b) {
        var d = Nf(e, new P(a)).apply(b);
        c = c.W(a, d);
      });
      Ma(Mf(e), function (a) {
        c = c.W(a.name, a.U);
      });
    } else e = Nf((this || _global).V, a), Ma(Mf(e), function (a) {
      c = c.W(a.name, a.U);
    });
    return c;
  };

  h.nd = function (a, b, c, d) {
    O(c || d, "Either existingEventSnap or existingServerSnap must exist");
    a = a.o(b);
    if (null != Lf((this || _global).V, a)) return null;
    a = Nf((this || _global).V, a);
    return a.e() ? d.S(b) : a.apply(d.S(b));
  };

  h.Bc = function (a, b, c) {
    a = a.o(b);
    var d = Lf((this || _global).V, a);
    return null != d ? d : Wb(c, b) ? Nf((this || _global).V, a).apply(c.j().T(b)) : null;
  };

  h.xc = function (a) {
    return Lf((this || _global).V, a);
  };

  h.qe = function (a, b, c, d, e, f) {
    var g;
    a = Nf((this || _global).V, a);
    g = Lf(a, M);
    if (null == g) if (null != b) g = a.apply(b);else return [];
    g = g.pb(f);
    if (g.e() || g.L()) return [];
    b = [];
    a = Vd(f);
    e = e ? g.dc(c, f) : g.bc(c, f);

    for (f = Ic(e); f && b.length < d;) 0 !== a(f, c) && b.push(f), f = Ic(e);

    return b;
  };

  function Rf(a, b) {
    return a.Ja ? a.path.contains(b) : !!ta(a.children, function (c, d) {
      return a.path.o(d).contains(b);
    });
  }

  function Tf(a) {
    return a.visible;
  }

  function Sf(a, b, c) {
    for (var d = If, e = 0; e < a.length; ++e) {
      var f = a[e];

      if (b(f)) {
        var g = f.path;
        if (f.Ja) c.contains(g) ? (g = lf(c, g), d = Jf(d, g, f.Ja)) : g.contains(c) && (g = lf(g, c), d = Jf(d, M, f.Ja.S(g)));else if (f.children) {
          if (c.contains(g)) g = lf(c, g), d = Kf(d, g, f.children);else {
            if (g.contains(c)) if (g = lf(g, c), g.e()) d = Kf(d, M, f.children);else if (f = z(f.children, K(g))) f = f.S(N(g)), d = Jf(d, M, f);
          }
        } else throw jd("WriteRecord should have .snap or .children");
      }
    }

    return d;
  }

  function Uf(a, b) {
    (this || _global).Qb = a;
    (this || _global).Z = b;
  }

  h = Uf.prototype;

  h.Aa = function (a, b, c) {
    return (this || _global).Z.Aa((this || _global).Qb, a, b, c);
  };

  h.Cc = function (a) {
    return (this || _global).Z.Cc((this || _global).Qb, a);
  };

  h.nd = function (a, b, c) {
    return (this || _global).Z.nd((this || _global).Qb, a, b, c);
  };

  h.xc = function (a) {
    return (this || _global).Z.xc((this || _global).Qb.o(a));
  };

  h.qe = function (a, b, c, d, e) {
    return (this || _global).Z.qe((this || _global).Qb, a, b, c, d, e);
  };

  h.Bc = function (a, b) {
    return (this || _global).Z.Bc((this || _global).Qb, a, b);
  };

  h.o = function (a) {
    return new Uf((this || _global).Qb.o(a), (this || _global).Z);
  };

  function Vf() {
    (this || _global).children = {};
    (this || _global).pd = 0;
    (this || _global).value = null;
  }

  function Wf(a, b, c) {
    (this || _global).Jd = a ? a : "";
    (this || _global).Ha = b ? b : null;
    (this || _global).A = c ? c : new Vf();
  }

  function Xf(a, b) {
    for (var c = b instanceof P ? b : new P(b), d = a, e; null !== (e = K(c));) d = new Wf(e, d, z(d.A.children, e) || new Vf()), c = N(c);

    return d;
  }

  h = Wf.prototype;

  h.Ea = function () {
    return (this || _global).A.value;
  };

  function Yf(a, b) {
    O("undefined" !== typeof b, "Cannot set value to undefined");
    a.A.value = b;
    Zf(a);
  }

  h.clear = function () {
    (this || _global).A.value = null;
    (this || _global).A.children = {};
    (this || _global).A.pd = 0;
    Zf(this || _global);
  };

  h.zd = function () {
    return 0 < (this || _global).A.pd;
  };

  h.e = function () {
    return null === this.Ea() && !this.zd();
  };

  h.R = function (a) {
    var b = this || _global;
    v((this || _global).A.children, function (c, d) {
      a(new Wf(d, b, c));
    });
  };

  function $f(a, b, c, d) {
    c && !d && b(a);
    a.R(function (a) {
      $f(a, b, !0, d);
    });
    c && d && b(a);
  }

  function ag(a, b) {
    for (var c = a.parent(); null !== c && !b(c);) c = c.parent();
  }

  h.path = function () {
    return new P(null === (this || _global).Ha ? (this || _global).Jd : (this || _global).Ha.path() + "/" + (this || _global).Jd);
  };

  h.name = function () {
    return (this || _global).Jd;
  };

  h.parent = function () {
    return (this || _global).Ha;
  };

  function Zf(a) {
    if (null !== a.Ha) {
      var b = a.Ha,
          c = a.Jd,
          d = a.e(),
          e = y(b.A.children, c);
      d && e ? (delete b.A.children[c], b.A.pd--, Zf(b)) : d || e || (b.A.children[c] = a.A, b.A.pd++, Zf(b));
    }
  }

  ;
  var bg = /[\[\].#$\/\u0000-\u001F\u007F]/,
      cg = /[\[\].#$\u0000-\u001F\u007F]/,
      dg = /^[a-zA-Z][a-zA-Z._\-+]+$/;

  function eg(a) {
    return q(a) && 0 !== a.length && !bg.test(a);
  }

  function fg(a) {
    return null === a || q(a) || fa(a) && !td(a) || ga(a) && y(a, ".sv");
  }

  function gg(a, b, c, d) {
    d && !p(b) || hg(E(a, 1, d), b, c);
  }

  function hg(a, b, c) {
    c instanceof P && (c = new nf(c, a));
    if (!p(b)) throw Error(a + "contains undefined " + pf(c));
    if (r(b)) throw Error(a + "contains a function " + pf(c) + " with contents: " + b.toString());
    if (td(b)) throw Error(a + "contains " + b.toString() + " " + pf(c));
    if (q(b) && b.length > 10485760 / 3 && 10485760 < Pb(b)) throw Error(a + "contains a string greater than 10485760 utf8 bytes " + pf(c) + " ('" + b.substring(0, 50) + "...')");

    if (ga(b)) {
      var d = !1,
          e = !1;
      Fb(b, function (b, g) {
        if (".value" === b) d = !0;else if (".priority" !== b && ".sv" !== b && (e = !0, !eg(b))) throw Error(a + " contains an invalid key (" + b + ") " + pf(c) + ".  Keys must be non-empty strings and can't contain \".\", \"#\", \"$\", \"/\", \"[\", or \"]\"");
        c.push(b);
        hg(a, g, c);
        c.pop();
      });
      if (d && e) throw Error(a + " contains \".value\" child " + pf(c) + " in addition to actual children.");
    }
  }

  function ig(a, b) {
    var c, d;

    for (c = 0; c < b.length; c++) {
      d = b[c];

      for (var e = d.slice(), f = 0; f < e.length; f++) if ((".priority" !== e[f] || f !== e.length - 1) && !eg(e[f])) throw Error(a + "contains an invalid key (" + e[f] + ") in path " + d.toString() + ". Keys must be non-empty strings and can't contain \".\", \"#\", \"$\", \"/\", \"[\", or \"]\"");
    }

    b.sort(mf);
    e = null;

    for (c = 0; c < b.length; c++) {
      d = b[c];
      if (null !== e && e.contains(d)) throw Error(a + "contains a path " + e.toString() + " that is ancestor of another path " + d.toString());
      e = d;
    }
  }

  function jg(a, b, c) {
    var d = E(a, 1, !1);
    if (!ga(b) || da(b)) throw Error(d + " must be an object containing the children to replace.");
    var e = [];
    Fb(b, function (a, b) {
      var k = new P(a);
      hg(d, b, c.o(k));
      if (".priority" === me(k) && !fg(b)) throw Error(d + "contains an invalid value for '" + k.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
      e.push(k);
    });
    ig(d, e);
  }

  function kg(a, b, c) {
    if (td(c)) throw Error(E(a, b, !1) + "is " + c.toString() + ", but must be a valid Firebase priority (a string, finite number, server value, or null).");
    if (!fg(c)) throw Error(E(a, b, !1) + "must be a valid Firebase priority (a string, finite number, server value, or null).");
  }

  function lg(a, b, c) {
    if (!c || p(b)) switch (b) {
      case "value":
      case "child_added":
      case "child_removed":
      case "child_changed":
      case "child_moved":
        break;

      default:
        throw Error(E(a, 1, c) + "must be a valid event type: \"value\", \"child_added\", \"child_removed\", \"child_changed\", or \"child_moved\".");
    }
  }

  function mg(a, b) {
    if (p(b) && !eg(b)) throw Error(E(a, 2, !0) + "was an invalid key: \"" + b + "\".  Firebase keys must be non-empty strings and can't contain \".\", \"#\", \"$\", \"/\", \"[\", or \"]\").");
  }

  function ng(a, b) {
    if (!q(b) || 0 === b.length || cg.test(b)) throw Error(E(a, 1, !1) + "was an invalid path: \"" + b + "\". Paths must be non-empty strings and can't contain \".\", \"#\", \"$\", \"[\", or \"]\"");
  }

  function og(a, b) {
    if (".info" === K(b)) throw Error(a + " failed: Can't modify data under /.info/");
  }

  function pg(a, b) {
    if (!q(b)) throw Error(E(a, 1, !1) + "must be a valid credential (a string).");
  }

  function qg(a, b, c) {
    if (!q(c)) throw Error(E(a, b, !1) + "must be a valid string.");
  }

  function rg(a, b) {
    qg(a, 1, b);
    if (!dg.test(b)) throw Error(E(a, 1, !1) + "'" + b + "' is not a valid authentication provider.");
  }

  function sg(a, b, c, d) {
    if (!d || p(c)) if (!ga(c) || null === c) throw Error(E(a, b, d) + "must be a valid object.");
  }

  function tg(a, b, c) {
    if (!ga(b) || !y(b, c)) throw Error(E(a, 1, !1) + "must contain the key \"" + c + "\"");
    if (!q(z(b, c))) throw Error(E(a, 1, !1) + "must contain the key \"" + c + "\" with type \"string\"");
  }

  ;

  function ug() {
    (this || _global).set = {};
  }

  h = ug.prototype;

  h.add = function (a, b) {
    (this || _global).set[a] = null !== b ? b : !0;
  };

  h.contains = function (a) {
    return y((this || _global).set, a);
  };

  h.get = function (a) {
    return this.contains(a) ? (this || _global).set[a] : void 0;
  };

  h.remove = function (a) {
    delete (this || _global).set[a];
  };

  h.clear = function () {
    (this || _global).set = {};
  };

  h.e = function () {
    return va((this || _global).set);
  };

  h.count = function () {
    return oa((this || _global).set);
  };

  function vg(a, b) {
    v(a.set, function (a, d) {
      b(d, a);
    });
  }

  h.keys = function () {
    var a = [];
    v((this || _global).set, function (b, c) {
      a.push(c);
    });
    return a;
  };

  function Vc() {
    (this || _global).m = (this || _global).B = null;
  }

  Vc.prototype.find = function (a) {
    if (null != (this || _global).B) return (this || _global).B.S(a);
    if (a.e() || null == (this || _global).m) return null;
    var b = K(a);
    a = N(a);
    return (this || _global).m.contains(b) ? (this || _global).m.get(b).find(a) : null;
  };

  Vc.prototype.rc = function (a, b) {
    if (a.e()) (this || _global).B = b, (this || _global).m = null;else if (null !== (this || _global).B) (this || _global).B = (this || _global).B.H(a, b);else {
      null == (this || _global).m && ((this || _global).m = new ug());
      var c = K(a);
      (this || _global).m.contains(c) || (this || _global).m.add(c, new Vc());
      c = (this || _global).m.get(c);
      a = N(a);
      c.rc(a, b);
    }
  };

  function wg(a, b) {
    if (b.e()) return a.B = null, a.m = null, !0;

    if (null !== a.B) {
      if (a.B.L()) return !1;
      var c = a.B;
      a.B = null;
      c.R(R, function (b, c) {
        a.rc(new P(b), c);
      });
      return wg(a, b);
    }

    return null !== a.m ? (c = K(b), b = N(b), a.m.contains(c) && wg(a.m.get(c), b) && a.m.remove(c), a.m.e() ? (a.m = null, !0) : !1) : !0;
  }

  function Wc(a, b, c) {
    null !== a.B ? c(b, a.B) : a.R(function (a, e) {
      var f = new P(b.toString() + "/" + a);
      Wc(e, f, c);
    });
  }

  Vc.prototype.R = function (a) {
    null !== (this || _global).m && vg((this || _global).m, function (b, c) {
      a(b, c);
    });
  };

  var xg = "auth.firebase.com";

  function yg(a, b, c) {
    (this || _global).qd = a || {};
    (this || _global).he = b || {};
    (this || _global).fb = c || {};
    (this || _global).qd.remember || ((this || _global).qd.remember = "default");
  }

  var zg = ["remember", "redirectTo"];

  function Ag(a) {
    var b = {},
        c = {};
    Fb(a || {}, function (a, e) {
      0 <= La(zg, a) ? b[a] = e : c[a] = e;
    });
    return new yg(b, {}, c);
  }

  ;

  function Bg(a, b) {
    (this || _global).Ue = ["session", a.Rd, a.lc].join(":");
    (this || _global).ee = b;
  }

  Bg.prototype.set = function (a, b) {
    if (!b) if ((this || _global).ee.length) b = (this || _global).ee[0];else throw Error("fb.login.SessionManager : No storage options available!");
    b.set((this || _global).Ue, a);
  };

  Bg.prototype.get = function () {
    var a = Oa((this || _global).ee, u((this || _global).Bg, this || _global)),
        a = Na(a, function (a) {
      return null !== a;
    });
    Va(a, function (a, c) {
      return Dd(c.token) - Dd(a.token);
    });
    return 0 < a.length ? a.shift() : null;
  };

  Bg.prototype.Bg = function (a) {
    try {
      var b = a.get((this || _global).Ue);
      if (b && b.token) return b;
    } catch (c) {}

    return null;
  };

  Bg.prototype.clear = function () {
    var a = this || _global;
    Ma((this || _global).ee, function (b) {
      b.remove(a.Ue);
    });
  };

  function Cg() {
    return "undefined" !== typeof navigator && "string" === typeof navigator.userAgent ? navigator.userAgent : "";
  }

  function Dg() {
    return "undefined" !== typeof window && !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Cg());
  }

  function Eg() {
    return "undefined" !== typeof location && /^file:\//.test(location.href);
  }

  function Fg(a) {
    var b = Cg();
    if ("" === b) return !1;

    if ("Microsoft Internet Explorer" === navigator.appName) {
      if ((b = b.match(/MSIE ([0-9]{1,}[\.0-9]{0,})/)) && 1 < b.length) return parseFloat(b[1]) >= a;
    } else if (-1 < b.indexOf("Trident") && (b = b.match(/rv:([0-9]{2,2}[\.0-9]{0,})/)) && 1 < b.length) return parseFloat(b[1]) >= a;

    return !1;
  }

  ;

  function Gg() {
    var a = window.opener.frames,
        b;

    for (b = a.length - 1; 0 <= b; b--) try {
      if (a[b].location.protocol === window.location.protocol && a[b].location.host === window.location.host && "__winchan_relay_frame" === a[b].name) return a[b];
    } catch (c) {}

    return null;
  }

  function Hg(a, b, c) {
    a.attachEvent ? a.attachEvent("on" + b, c) : a.addEventListener && a.addEventListener(b, c, !1);
  }

  function Ig(a, b, c) {
    a.detachEvent ? a.detachEvent("on" + b, c) : a.removeEventListener && a.removeEventListener(b, c, !1);
  }

  function Jg(a) {
    /^https?:\/\//.test(a) || (a = window.location.href);
    var b = /^(https?:\/\/[\-_a-zA-Z\.0-9:]+)/.exec(a);
    return b ? b[1] : a;
  }

  function Kg(a) {
    var b = "";

    try {
      a = a.replace(/.*\?/, "");
      var c = Jb(a);
      c && y(c, "__firebase_request_key") && (b = z(c, "__firebase_request_key"));
    } catch (d) {}

    return b;
  }

  function Lg() {
    try {
      var a = document.location.hash.replace(/&__firebase_request_key=([a-zA-z0-9]*)/, ""),
          a = a.replace(/\?$/, ""),
          a = a.replace(/^#+$/, "");
      document.location.hash = a;
    } catch (b) {}
  }

  function Mg() {
    var a = sd(xg);
    return a.scheme + "://" + a.host + "/v2";
  }

  function Ng(a) {
    return Mg() + "/" + a + "/auth/channel";
  }

  ;

  function Og(a) {
    var b = this || _global;
    (this || _global).hb = a;
    (this || _global).fe = "*";
    Fg(8) ? (this || _global).Uc = (this || _global).Cd = Gg() : ((this || _global).Uc = window.opener, (this || _global).Cd = window);
    if (!b.Uc) throw "Unable to find relay frame";
    Hg((this || _global).Cd, "message", u((this || _global).nc, this || _global));
    Hg((this || _global).Cd, "message", u((this || _global).Ff, this || _global));

    try {
      Pg(this || _global, {
        a: "ready"
      });
    } catch (c) {
      Hg((this || _global).Uc, "load", function () {
        Pg(b, {
          a: "ready"
        });
      });
    }

    Hg(window, "unload", u((this || _global).Ng, this || _global));
  }

  function Pg(a, b) {
    b = G(b);
    Fg(8) ? a.Uc.doPost(b, a.fe) : a.Uc.postMessage(b, a.fe);
  }

  Og.prototype.nc = function (a) {
    var b = this || _global,
        c;

    try {
      c = Rb(a.data);
    } catch (d) {}

    c && "request" === c.a && (Ig(window, "message", (this || _global).nc), (this || _global).fe = a.origin, (this || _global).hb && setTimeout(function () {
      b.hb(b.fe, c.d, function (a, c) {
        b.mg = !c;
        b.hb = void 0;
        Pg(b, {
          a: "response",
          d: a,
          forceKeepWindowOpen: c
        });
      });
    }, 0));
  };

  Og.prototype.Ng = function () {
    try {
      Ig((this || _global).Cd, "message", (this || _global).Ff);
    } catch (a) {}

    (this || _global).hb && (Pg(this || _global, {
      a: "error",
      d: "unknown closed window"
    }), (this || _global).hb = void 0);

    try {
      window.close();
    } catch (b) {}
  };

  Og.prototype.Ff = function (a) {
    if ((this || _global).mg && "die" === a.data) try {
      window.close();
    } catch (b) {}
  };

  function Qg(a) {
    (this || _global).tc = Fa() + Fa() + Fa();
    (this || _global).Kf = a;
  }

  Qg.prototype.open = function (a, b) {
    cd.set("redirect_request_id", (this || _global).tc);
    cd.set("redirect_request_id", (this || _global).tc);
    b.requestId = (this || _global).tc;
    b.redirectTo = b.redirectTo || window.location.href;
    a += (/\?/.test(a) ? "" : "?") + Ib(b);
    window.location = a;
  };

  Qg.isAvailable = function () {
    return !Eg() && !Dg();
  };

  Qg.prototype.Fc = function () {
    return "redirect";
  };

  var Rg = {
    NETWORK_ERROR: "Unable to contact the Firebase server.",
    SERVER_ERROR: "An unknown server error occurred.",
    TRANSPORT_UNAVAILABLE: "There are no login transports available for the requested method.",
    REQUEST_INTERRUPTED: "The browser redirected the page before the login request could complete.",
    USER_CANCELLED: "The user cancelled authentication."
  };

  function Sg(a) {
    var b = Error(z(Rg, a), a);
    b.code = a;
    return b;
  }

  ;

  function Tg(a) {
    var b;
    (b = !a.window_features) || (b = Cg(), b = -1 !== b.indexOf("Fennec/") || -1 !== b.indexOf("Firefox/") && -1 !== b.indexOf("Android"));
    b && (a.window_features = void 0);
    a.window_name || (a.window_name = "_blank");
    (this || _global).options = a;
  }

  Tg.prototype.open = function (a, b, c) {
    function d(a) {
      g && (document.body.removeChild(g), g = void 0);
      t && (t = clearInterval(t));
      Ig(window, "message", e);
      Ig(window, "unload", d);
      if (l && !a) try {
        l.close();
      } catch (b) {
        k.postMessage("die", m);
      }
      l = k = void 0;
    }

    function e(a) {
      if (a.origin === m) try {
        var b = Rb(a.data);
        "ready" === b.a ? k.postMessage(A, m) : "error" === b.a ? (d(!1), c && (c(b.d), c = null)) : "response" === b.a && (d(b.forceKeepWindowOpen), c && (c(null, b.d), c = null));
      } catch (e) {}
    }

    var f = Fg(8),
        g,
        k;
    if (!(this || _global).options.relay_url) return c(Error("invalid arguments: origin of url and relay_url must match"));
    var m = Jg(a);
    if (m !== Jg((this || _global).options.relay_url)) c && setTimeout(function () {
      c(Error("invalid arguments: origin of url and relay_url must match"));
    }, 0);else {
      f && (g = document.createElement("iframe"), g.setAttribute("src", (this || _global).options.relay_url), g.style.display = "none", g.setAttribute("name", "__winchan_relay_frame"), document.body.appendChild(g), k = g.contentWindow);
      a += (/\?/.test(a) ? "" : "?") + Ib(b);
      var l = window.open(a, (this || _global).options.window_name, (this || _global).options.window_features);
      k || (k = l);
      var t = setInterval(function () {
        l && l.closed && (d(!1), c && (c(Sg("USER_CANCELLED")), c = null));
      }, 500),
          A = G({
        a: "request",
        d: b
      });
      Hg(window, "unload", d);
      Hg(window, "message", e);
    }
  };

  Tg.isAvailable = function () {
    var a;
    if (a = "postMessage" in window && !Eg()) (a = Dg() || "undefined" !== typeof navigator && (!!Cg().match(/Windows Phone/) || !!window.Windows && /^ms-appx:/.test(location.href))) || (a = Cg(), a = "undefined" !== typeof navigator && "undefined" !== typeof window && !!(a.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i) || a.match(/CriOS/) || a.match(/Twitter for iPhone/) || a.match(/FBAN\/FBIOS/) || window.navigator.standalone)), a = !a;
    return a && !Cg().match(/PhantomJS/);
  };

  Tg.prototype.Fc = function () {
    return "popup";
  };

  function Ug(a) {
    a.method || (a.method = "GET");
    a.headers || (a.headers = {});
    a.headers.content_type || (a.headers.content_type = "application/json");
    a.headers.content_type = a.headers.content_type.toLowerCase();
    (this || _global).options = a;
  }

  Ug.prototype.open = function (a, b, c) {
    function d() {
      c && (c(Sg("REQUEST_INTERRUPTED")), c = null);
    }

    var e = new XMLHttpRequest(),
        f = (this || _global).options.method.toUpperCase(),
        g;

    Hg(window, "beforeunload", d);

    e.onreadystatechange = function () {
      if (c && 4 === e.readyState) {
        var a;

        if (200 <= e.status && 300 > e.status) {
          try {
            a = Rb(e.responseText);
          } catch (b) {}

          c(null, a);
        } else 500 <= e.status && 600 > e.status ? c(Sg("SERVER_ERROR")) : c(Sg("NETWORK_ERROR"));

        c = null;
        Ig(window, "beforeunload", d);
      }
    };

    if ("GET" === f) a += (/\?/.test(a) ? "" : "?") + Ib(b), g = null;else {
      var k = (this || _global).options.headers.content_type;
      "application/json" === k && (g = G(b));
      "application/x-www-form-urlencoded" === k && (g = Ib(b));
    }
    e.open(f, a, !0);
    a = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json;text/plain"
    };
    ya(a, (this || _global).options.headers);

    for (var m in a) e.setRequestHeader(m, a[m]);

    e.send(g);
  };

  Ug.isAvailable = function () {
    var a;
    if (a = !!window.XMLHttpRequest) a = Cg(), a = !(a.match(/MSIE/) || a.match(/Trident/)) || Fg(10);
    return a;
  };

  Ug.prototype.Fc = function () {
    return "json";
  };

  function Vg(a) {
    (this || _global).tc = Fa() + Fa() + Fa();
    (this || _global).Kf = a;
  }

  Vg.prototype.open = function (a, b, c) {
    function d() {
      c && (c(Sg("USER_CANCELLED")), c = null);
    }

    var e = this || _global,
        f = sd(xg),
        g;
    b.requestId = (this || _global).tc;
    b.redirectTo = f.scheme + "://" + f.host + "/blank/page.html";
    a += /\?/.test(a) ? "" : "?";
    a += Ib(b);
    (g = window.open(a, "_blank", "location=no")) && r(g.addEventListener) ? (g.addEventListener("loadstart", function (a) {
      var b;
      if (b = a && a.url) a: {
        try {
          var l = document.createElement("a");
          l.href = a.url;
          b = l.host === f.host && "/blank/page.html" === l.pathname;
          break a;
        } catch (t) {}

        b = !1;
      }
      b && (a = Kg(a.url), g.removeEventListener("exit", d), g.close(), a = new yg(null, null, {
        requestId: e.tc,
        requestKey: a
      }), e.Kf.requestWithCredential("/auth/session", a, c), c = null);
    }), g.addEventListener("exit", d)) : c(Sg("TRANSPORT_UNAVAILABLE"));
  };

  Vg.isAvailable = function () {
    return Dg();
  };

  Vg.prototype.Fc = function () {
    return "redirect";
  };

  function Wg(a) {
    a.callback_parameter || (a.callback_parameter = "callback");
    (this || _global).options = a;
    window.__firebase_auth_jsonp = window.__firebase_auth_jsonp || {};
  }

  Wg.prototype.open = function (a, b, c) {
    function d() {
      c && (c(Sg("REQUEST_INTERRUPTED")), c = null);
    }

    function e() {
      setTimeout(function () {
        window.__firebase_auth_jsonp[f] = void 0;
        va(window.__firebase_auth_jsonp) && (window.__firebase_auth_jsonp = void 0);

        try {
          var a = document.getElementById(f);
          a && a.parentNode.removeChild(a);
        } catch (b) {}
      }, 1);
      Ig(window, "beforeunload", d);
    }

    var f = "fn" + new Date().getTime() + Math.floor(99999 * Math.random());
    b[(this || _global).options.callback_parameter] = "__firebase_auth_jsonp." + f;
    a += (/\?/.test(a) ? "" : "?") + Ib(b);
    Hg(window, "beforeunload", d);

    window.__firebase_auth_jsonp[f] = function (a) {
      c && (c(null, a), c = null);
      e();
    };

    Xg(f, a, c);
  };

  function Xg(a, b, c) {
    setTimeout(function () {
      try {
        var d = document.createElement("script");
        d.type = "text/javascript";
        d.id = a;
        d.async = !0;
        d.src = b;

        d.onerror = function () {
          var b = document.getElementById(a);
          null !== b && b.parentNode.removeChild(b);
          c && c(Sg("NETWORK_ERROR"));
        };

        var e = document.getElementsByTagName("head");
        (e && 0 != e.length ? e[0] : document.documentElement).appendChild(d);
      } catch (f) {
        c && c(Sg("NETWORK_ERROR"));
      }
    }, 0);
  }

  Wg.isAvailable = function () {
    return "undefined" !== typeof document && null != document.createElement;
  };

  Wg.prototype.Fc = function () {
    return "json";
  };

  function Yg(a, b, c, d) {
    ff.call(this || _global, ["auth_status"]);
    (this || _global).G = a;
    (this || _global).hf = b;
    (this || _global).ih = c;
    (this || _global).Pe = d;
    (this || _global).wc = new Bg(a, [bd, cd]);
    (this || _global).qb = null;
    (this || _global).We = !1;
    Zg(this || _global);
  }

  ka(Yg, ff);
  h = Yg.prototype;

  h.Be = function () {
    return (this || _global).qb || null;
  };

  function Zg(a) {
    cd.get("redirect_request_id") && $g(a);
    var b = a.wc.get();
    b && b.token ? (ah(a, b), a.hf(b.token, function (c, d) {
      bh(a, c, d, !1, b.token, b);
    }, function (b, d) {
      ch(a, "resumeSession()", b, d);
    })) : ah(a, null);
  }

  function dh(a, b, c, d, e, f) {
    "firebaseio-demo.com" === a.G.domain && S("Firebase authentication is not supported on demo Firebases (*.firebaseio-demo.com). To secure your Firebase, create a production Firebase at https://www.firebase.com.");
    a.hf(b, function (f, k) {
      bh(a, f, k, !0, b, c, d || {}, e);
    }, function (b, c) {
      ch(a, "auth()", b, c, f);
    });
  }

  function eh(a, b) {
    a.wc.clear();
    ah(a, null);
    a.ih(function (a, d) {
      if ("ok" === a) T(b, null);else {
        var e = (a || "error").toUpperCase(),
            f = e;
        d && (f += ": " + d);
        f = Error(f);
        f.code = e;
        T(b, f);
      }
    });
  }

  function bh(a, b, c, d, e, f, g, k) {
    "ok" === b ? (d && (b = c.auth, f.auth = b, f.expires = c.expires, f.token = Ed(e) ? e : "", c = null, b && y(b, "uid") ? c = z(b, "uid") : y(f, "uid") && (c = z(f, "uid")), f.uid = c, c = "custom", b && y(b, "provider") ? c = z(b, "provider") : y(f, "provider") && (c = z(f, "provider")), f.provider = c, a.wc.clear(), Ed(e) && (g = g || {}, c = bd, "sessionOnly" === g.remember && (c = cd), "none" !== g.remember && a.wc.set(f, c)), ah(a, f)), T(k, null, f)) : (a.wc.clear(), ah(a, null), f = a = (b || "error").toUpperCase(), c && (f += ": " + c), f = Error(f), f.code = a, T(k, f));
  }

  function ch(a, b, c, d, e) {
    S(b + " was canceled: " + d);
    a.wc.clear();
    ah(a, null);
    a = Error(d);
    a.code = c.toUpperCase();
    T(e, a);
  }

  function fh(a, b, c, d, e) {
    gh(a);
    c = new yg(d || {}, {}, c || {});
    hh(a, [Ug, Wg], "/auth/" + b, c, e);
  }

  function ih(a, b, c, d) {
    gh(a);
    var e = [Tg, Vg];
    c = Ag(c);
    var f = 625;
    "anonymous" === b || "password" === b ? setTimeout(function () {
      T(d, Sg("TRANSPORT_UNAVAILABLE"));
    }, 0) : ("github" === b && (f = 1025), c.he.window_features = "menubar=yes,modal=yes,alwaysRaised=yeslocation=yes,resizable=yes,scrollbars=yes,status=yes,height=625,width=" + f + ",top=" + ("object" === typeof screen ? 0.5 * (screen.height - 625) : 0) + ",left=" + ("object" === typeof screen ? 0.5 * (screen.width - f) : 0), c.he.relay_url = Ng(a.G.lc), c.he.requestWithCredential = u(a.uc, a), hh(a, e, "/auth/" + b, c, d));
  }

  function $g(a) {
    var b = cd.get("redirect_request_id");

    if (b) {
      var c = cd.get("redirect_client_options");
      cd.remove("redirect_request_id");
      cd.remove("redirect_client_options");
      var d = [Ug, Wg],
          b = {
        requestId: b,
        requestKey: Kg(document.location.hash)
      },
          c = new yg(c, {}, b);
      a.We = !0;
      Lg();
      hh(a, d, "/auth/session", c, function () {
        (this || _global).We = !1;
      }.bind(a));
    }
  }

  h.ve = function (a, b) {
    gh(this || _global);
    var c = Ag(a);
    c.fb._method = "POST";
    this.uc("/users", c, function (a, c) {
      a ? T(b, a) : T(b, a, c);
    });
  };

  h.Xe = function (a, b) {
    var c = this || _global;
    gh(this || _global);
    var d = "/users/" + encodeURIComponent(a.email),
        e = Ag(a);
    e.fb._method = "DELETE";
    this.uc(d, e, function (a, d) {
      !a && d && d.uid && c.qb && c.qb.uid && c.qb.uid === d.uid && eh(c);
      T(b, a);
    });
  };

  h.se = function (a, b) {
    gh(this || _global);
    var c = "/users/" + encodeURIComponent(a.email) + "/password",
        d = Ag(a);
    d.fb._method = "PUT";
    d.fb.password = a.newPassword;
    this.uc(c, d, function (a) {
      T(b, a);
    });
  };

  h.re = function (a, b) {
    gh(this || _global);
    var c = "/users/" + encodeURIComponent(a.oldEmail) + "/email",
        d = Ag(a);
    d.fb._method = "PUT";
    d.fb.email = a.newEmail;
    d.fb.password = a.password;
    this.uc(c, d, function (a) {
      T(b, a);
    });
  };

  h.Ze = function (a, b) {
    gh(this || _global);
    var c = "/users/" + encodeURIComponent(a.email) + "/password",
        d = Ag(a);
    d.fb._method = "POST";
    this.uc(c, d, function (a) {
      T(b, a);
    });
  };

  h.uc = function (a, b, c) {
    jh(this || _global, [Ug, Wg], a, b, c);
  };

  function hh(a, b, c, d, e) {
    jh(a, b, c, d, function (b, c) {
      !b && c && c.token && c.uid ? dh(a, c.token, c, d.qd, function (a, b) {
        a ? T(e, a) : T(e, null, b);
      }) : T(e, b || Sg("UNKNOWN_ERROR"));
    });
  }

  function jh(a, b, c, d, e) {
    b = Na(b, function (a) {
      return "function" === typeof a.isAvailable && a.isAvailable();
    });
    0 === b.length ? setTimeout(function () {
      T(e, Sg("TRANSPORT_UNAVAILABLE"));
    }, 0) : (b = new (b.shift())(d.he), d = Gb(d.fb), d.v = "js-" + Eb, d.transport = b.Fc(), d.suppress_status_codes = !0, a = Mg() + "/" + a.G.lc + c, b.open(a, d, function (a, b) {
      if (a) T(e, a);else if (b && b.error) {
        var c = Error(b.error.message);
        c.code = b.error.code;
        c.details = b.error.details;
        T(e, c);
      } else T(e, null, b);
    }));
  }

  function ah(a, b) {
    var c = null !== a.qb || null !== b;
    a.qb = b;
    c && a.ie("auth_status", b);
    a.Pe(null !== b);
  }

  h.Ee = function (a) {
    O("auth_status" === a, "initial event must be of type \"auth_status\"");
    return (this || _global).We ? null : [(this || _global).qb];
  };

  function gh(a) {
    var b = a.G;
    if ("firebaseio.com" !== b.domain && "firebaseio-demo.com" !== b.domain && "auth.firebase.com" === xg) throw Error("This custom Firebase server ('" + a.G.domain + "') does not support delegated login.");
  }

  ;
  var gd = "websocket",
      hd = "long_polling";

  function kh(a) {
    (this || _global).nc = a;
    (this || _global).Qd = [];
    (this || _global).Wb = 0;
    (this || _global).te = -1;
    (this || _global).Jb = null;
  }

  function lh(a, b, c) {
    a.te = b;
    a.Jb = c;
    a.te < a.Wb && (a.Jb(), a.Jb = null);
  }

  function mh(a, b, c) {
    for (a.Qd[b] = c; a.Qd[a.Wb];) {
      var d = a.Qd[a.Wb];
      delete a.Qd[a.Wb];

      for (var e = 0; e < d.length; ++e) if (d[e]) {
        var f = a;
        gc(function () {
          f.nc(d[e]);
        });
      }

      if (a.Wb === a.te) {
        a.Jb && (clearTimeout(a.Jb), a.Jb(), a.Jb = null);
        break;
      }

      a.Wb++;
    }
  }

  ;

  function nh(a, b, c, d) {
    (this || _global).ue = a;
    (this || _global).f = pd(a);
    (this || _global).rb = (this || _global).sb = 0;
    (this || _global).Xa = uc(b);
    (this || _global).Xf = c;
    (this || _global).Kc = !1;
    (this || _global).Fb = d;

    (this || _global).ld = function (a) {
      return fd(b, hd, a);
    };
  }

  var oh, ph;

  nh.prototype.open = function (a, b) {
    (this || _global).mf = 0;
    (this || _global).na = b;
    (this || _global).Ef = new kh(a);
    (this || _global).Db = !1;
    var c = this || _global;
    (this || _global).ub = setTimeout(function () {
      c.f("Timed out trying to connect.");
      c.bb();
      c.ub = null;
    }, Math.floor(30000));
    ud(function () {
      if (!c.Db) {
        c.Wa = new qh(function (a, b, d, k, m) {
          rh(c, arguments);
          if (c.Wa) if (c.ub && (clearTimeout(c.ub), c.ub = null), c.Kc = !0, "start" == a) c.id = b, c.Mf = d;else if ("close" === a) b ? (c.Wa.$d = !1, lh(c.Ef, b, function () {
            c.bb();
          })) : c.bb();else throw Error("Unrecognized command received: " + a);
        }, function (a, b) {
          rh(c, arguments);
          mh(c.Ef, a, b);
        }, function () {
          c.bb();
        }, c.ld);
        var a = {
          start: "t"
        };
        a.ser = Math.floor(100000000 * Math.random());
        c.Wa.ke && (a.cb = c.Wa.ke);
        a.v = "5";
        c.Xf && (a.s = c.Xf);
        c.Fb && (a.ls = c.Fb);
        "undefined" !== typeof location && location.href && -1 !== location.href.indexOf("firebaseio.com") && (a.r = "f");
        a = c.ld(a);
        c.f("Connecting via long-poll to " + a);
        sh(c.Wa, a, function () {});
      }
    });
  };

  nh.prototype.start = function () {
    var a = (this || _global).Wa,
        b = (this || _global).Mf;
    a.Gg = (this || _global).id;
    a.Hg = b;

    for (a.oe = !0; th(a););

    a = (this || _global).id;
    b = (this || _global).Mf;
    (this || _global).kc = document.createElement("iframe");
    var c = {
      dframe: "t"
    };
    c.id = a;
    c.pw = b;
    (this || _global).kc.src = this.ld(c);
    (this || _global).kc.style.display = "none";
    document.body.appendChild((this || _global).kc);
  };

  nh.isAvailable = function () {
    return oh || !ph && "undefined" !== typeof document && null != document.createElement && !("object" === typeof window && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href)) && !("object" === typeof Windows && "object" === typeof Windows.kh) && !0;
  };

  h = nh.prototype;

  h.Hd = function () {};

  h.fd = function () {
    (this || _global).Db = !0;
    (this || _global).Wa && ((this || _global).Wa.close(), (this || _global).Wa = null);
    (this || _global).kc && (document.body.removeChild((this || _global).kc), (this || _global).kc = null);
    (this || _global).ub && (clearTimeout((this || _global).ub), (this || _global).ub = null);
  };

  h.bb = function () {
    (this || _global).Db || (this.f("Longpoll is closing itself"), this.fd(), (this || _global).na && (this.na((this || _global).Kc), (this || _global).na = null));
  };

  h.close = function () {
    (this || _global).Db || (this.f("Longpoll is being closed."), this.fd());
  };

  h.send = function (a) {
    a = G(a);
    (this || _global).sb += a.length;
    rc((this || _global).Xa, "bytes_sent", a.length);
    a = Ob(a);
    a = nb(a, !0);
    a = yd(a, 1840);

    for (var b = 0; b < a.length; b++) {
      var c = (this || _global).Wa;
      c.cd.push({
        Yg: (this || _global).mf,
        hh: a.length,
        of: a[b]
      });
      c.oe && th(c);
      (this || _global).mf++;
    }
  };

  function rh(a, b) {
    var c = G(b).length;
    a.rb += c;
    rc(a.Xa, "bytes_received", c);
  }

  function qh(a, b, c, d) {
    (this || _global).ld = d;
    (this || _global).lb = c;
    (this || _global).Te = new ug();
    (this || _global).cd = [];
    (this || _global).we = Math.floor(100000000 * Math.random());
    (this || _global).$d = !0;
    (this || _global).ke = id();
    window["pLPCommand" + (this || _global).ke] = a;
    window["pRTLPCB" + (this || _global).ke] = b;
    a = document.createElement("iframe");
    a.style.display = "none";

    if (document.body) {
      document.body.appendChild(a);

      try {
        a.contentWindow.document || fc("No IE domain setting required");
      } catch (e) {
        a.src = "javascript:void((function(){document.open();document.domain='" + document.domain + "';document.close();})())";
      }
    } else throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";

    a.contentDocument ? a.jb = a.contentDocument : a.contentWindow ? a.jb = a.contentWindow.document : a.document && (a.jb = a.document);
    (this || _global).Ga = a;
    a = "";
    (this || _global).Ga.src && "javascript:" === (this || _global).Ga.src.substr(0, 11) && (a = "<script>document.domain=\"" + document.domain + "\";</script>");
    a = "<html><body>" + a + "</body></html>";

    try {
      (this || _global).Ga.jb.open(), (this || _global).Ga.jb.write(a), (this || _global).Ga.jb.close();
    } catch (f) {
      fc("frame writing exception"), f.stack && fc(f.stack), fc(f);
    }
  }

  qh.prototype.close = function () {
    (this || _global).oe = !1;

    if ((this || _global).Ga) {
      (this || _global).Ga.jb.body.innerHTML = "";
      var a = this || _global;
      setTimeout(function () {
        null !== a.Ga && (document.body.removeChild(a.Ga), a.Ga = null);
      }, Math.floor(0));
    }

    var b = (this || _global).lb;
    b && ((this || _global).lb = null, b());
  };

  function th(a) {
    if (a.oe && a.$d && a.Te.count() < (0 < a.cd.length ? 2 : 1)) {
      a.we++;
      var b = {};
      b.id = a.Gg;
      b.pw = a.Hg;
      b.ser = a.we;

      for (var b = a.ld(b), c = "", d = 0; 0 < a.cd.length;) if (1870 >= a.cd[0].of.length + 30 + c.length) {
        var e = a.cd.shift(),
            c = c + "&seg" + d + "=" + e.Yg + "&ts" + d + "=" + e.hh + "&d" + d + "=" + e.of;
        d++;
      } else break;

      uh(a, b + c, a.we);
      return !0;
    }

    return !1;
  }

  function uh(a, b, c) {
    function d() {
      a.Te.remove(c);
      th(a);
    }

    a.Te.add(c, 1);
    var e = setTimeout(d, Math.floor(25000));
    sh(a, b, function () {
      clearTimeout(e);
      d();
    });
  }

  function sh(a, b, c) {
    setTimeout(function () {
      try {
        if (a.$d) {
          var d = a.Ga.jb.createElement("script");
          d.type = "text/javascript";
          d.async = !0;
          d.src = b;

          d.onload = d.onreadystatechange = function () {
            var a = d.readyState;
            a && "loaded" !== a && "complete" !== a || (d.onload = d.onreadystatechange = null, d.parentNode && d.parentNode.removeChild(d), c());
          };

          d.onerror = function () {
            fc("Long-poll script failed to load: " + b);
            a.$d = !1;
            a.close();
          };

          a.Ga.jb.body.appendChild(d);
        }
      } catch (e) {}
    }, Math.floor(1));
  }

  ;
  var vh = null;
  "undefined" !== typeof MozWebSocket ? vh = MozWebSocket : "undefined" !== typeof WebSocket && (vh = WebSocket);

  function wh(a, b, c, d) {
    (this || _global).ue = a;
    (this || _global).f = pd((this || _global).ue);
    (this || _global).frames = (this || _global).Nc = null;
    (this || _global).rb = (this || _global).sb = (this || _global).ff = 0;
    (this || _global).Xa = uc(b);
    a = {
      v: "5"
    };
    "undefined" !== typeof location && location.href && -1 !== location.href.indexOf("firebaseio.com") && (a.r = "f");
    c && (a.s = c);
    d && (a.ls = d);
    (this || _global).jf = fd(b, gd, a);
  }

  var xh;

  wh.prototype.open = function (a, b) {
    (this || _global).lb = b;
    (this || _global).Lg = a;
    this.f("Websocket connecting to " + (this || _global).jf);
    (this || _global).Kc = !1;
    bd.set("previous_websocket_failure", !0);

    try {
      (this || _global).La = new vh((this || _global).jf);
    } catch (c) {
      this.f("Error instantiating WebSocket.");
      var d = c.message || c.data;
      d && this.f(d);
      this.bb();
      return;
    }

    var e = this || _global;

    (this || _global).La.onopen = function () {
      e.f("Websocket connected.");
      e.Kc = !0;
    };

    (this || _global).La.onclose = function () {
      e.f("Websocket connection was disconnected.");
      e.La = null;
      e.bb();
    };

    (this || _global).La.onmessage = function (a) {
      if (null !== e.La) if (a = a.data, e.rb += a.length, rc(e.Xa, "bytes_received", a.length), yh(e), null !== e.frames) zh(e, a);else {
        a: {
          O(null === e.frames, "We already have a frame buffer");

          if (6 >= a.length) {
            var b = Number(a);

            if (!isNaN(b)) {
              e.ff = b;
              e.frames = [];
              a = null;
              break a;
            }
          }

          e.ff = 1;
          e.frames = [];
        }

        null !== a && zh(e, a);
      }
    };

    (this || _global).La.onerror = function (a) {
      e.f("WebSocket error.  Closing connection.");
      (a = a.message || a.data) && e.f(a);
      e.bb();
    };
  };

  wh.prototype.start = function () {};

  wh.isAvailable = function () {
    var a = !1;

    if ("undefined" !== typeof navigator && navigator.userAgent) {
      var b = navigator.userAgent.match(/Android ([0-9]{0,}\.[0-9]{0,})/);
      b && 1 < b.length && 4.4 > parseFloat(b[1]) && (a = !0);
    }

    return !a && null !== vh && !xh;
  };

  wh.responsesRequiredToBeHealthy = 2;
  wh.healthyTimeout = 30000;
  h = wh.prototype;

  h.Hd = function () {
    bd.remove("previous_websocket_failure");
  };

  function zh(a, b) {
    a.frames.push(b);

    if (a.frames.length == a.ff) {
      var c = a.frames.join("");
      a.frames = null;
      c = Rb(c);
      a.Lg(c);
    }
  }

  h.send = function (a) {
    yh(this || _global);
    a = G(a);
    (this || _global).sb += a.length;
    rc((this || _global).Xa, "bytes_sent", a.length);
    a = yd(a, 16384);
    1 < a.length && Ah(this || _global, String(a.length));

    for (var b = 0; b < a.length; b++) Ah(this || _global, a[b]);
  };

  h.fd = function () {
    (this || _global).Db = !0;
    (this || _global).Nc && (clearInterval((this || _global).Nc), (this || _global).Nc = null);
    (this || _global).La && ((this || _global).La.close(), (this || _global).La = null);
  };

  h.bb = function () {
    (this || _global).Db || (this.f("WebSocket is closing itself"), this.fd(), (this || _global).lb && (this.lb((this || _global).Kc), (this || _global).lb = null));
  };

  h.close = function () {
    (this || _global).Db || (this.f("WebSocket is being closed"), this.fd());
  };

  function yh(a) {
    clearInterval(a.Nc);
    a.Nc = setInterval(function () {
      a.La && Ah(a, "0");
      yh(a);
    }, Math.floor(45000));
  }

  function Ah(a, b) {
    try {
      a.La.send(b);
    } catch (c) {
      a.f("Exception thrown from WebSocket.send():", c.message || c.data, "Closing connection."), setTimeout(u(a.bb, a), 0);
    }
  }

  ;

  function Bh(a) {
    Ch(this || _global, a);
  }

  var Dh = [nh, wh];

  function Ch(a, b) {
    var c = wh && wh.isAvailable(),
        d = c && !(bd.Af || !0 === bd.get("previous_websocket_failure"));
    b.jh && (c || S("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), d = !0);
    if (d) a.jd = [wh];else {
      var e = a.jd = [];
      zd(Dh, function (a, b) {
        b && b.isAvailable() && e.push(b);
      });
    }
  }

  function Eh(a) {
    if (0 < a.jd.length) return a.jd[0];
    throw Error("No transports available");
  }

  ;

  function Fh(a, b, c, d, e, f, g) {
    (this || _global).id = a;
    (this || _global).f = pd("c:" + (this || _global).id + ":");
    (this || _global).nc = c;
    (this || _global).Zc = d;
    (this || _global).na = e;
    (this || _global).Re = f;
    (this || _global).G = b;
    (this || _global).Pd = [];
    (this || _global).kf = 0;
    (this || _global).Wf = new Bh(b);
    (this || _global).N = 0;
    (this || _global).Fb = g;
    this.f("Connection created");
    Gh(this || _global);
  }

  function Gh(a) {
    var b = Eh(a.Wf);
    a.K = new b("c:" + a.id + ":" + a.kf++, a.G, void 0, a.Fb);
    a.Ve = b.responsesRequiredToBeHealthy || 0;
    var c = Hh(a, a.K),
        d = Ih(a, a.K);
    a.kd = a.K;
    a.ed = a.K;
    a.F = null;
    a.Eb = !1;
    setTimeout(function () {
      a.K && a.K.open(c, d);
    }, Math.floor(0));
    b = b.healthyTimeout || 0;
    0 < b && (a.Bd = setTimeout(function () {
      a.Bd = null;
      a.Eb || (a.K && 102400 < a.K.rb ? (a.f("Connection exceeded healthy timeout but has received " + a.K.rb + " bytes.  Marking connection healthy."), a.Eb = !0, a.K.Hd()) : a.K && 10240 < a.K.sb ? a.f("Connection exceeded healthy timeout but has sent " + a.K.sb + " bytes.  Leaving connection alive.") : (a.f("Closing unhealthy connection after timeout."), a.close()));
    }, Math.floor(b)));
  }

  function Ih(a, b) {
    return function (c) {
      b === a.K ? (a.K = null, c || 0 !== a.N ? 1 === a.N && a.f("Realtime connection lost.") : (a.f("Realtime connection failed."), "s-" === a.G.ab.substr(0, 2) && (bd.remove("host:" + a.G.host), a.G.ab = a.G.host)), a.close()) : b === a.F ? (a.f("Secondary connection lost."), c = a.F, a.F = null, a.kd !== c && a.ed !== c || a.close()) : a.f("closing an old connection");
    };
  }

  function Hh(a, b) {
    return function (c) {
      if (2 != a.N) if (b === a.ed) {
        var d = wd("t", c);
        c = wd("d", c);

        if ("c" == d) {
          if (d = wd("t", c), "d" in c) if (c = c.d, "h" === d) {
            var d = c.ts,
                e = c.v,
                f = c.h;
            a.Uf = c.s;
            ed(a.G, f);
            0 == a.N && (a.K.start(), Jh(a, a.K, d), "5" !== e && S("Protocol version mismatch detected"), c = a.Wf, (c = 1 < c.jd.length ? c.jd[1] : null) && Kh(a, c));
          } else if ("n" === d) {
            a.f("recvd end transmission on primary");
            a.ed = a.F;

            for (c = 0; c < a.Pd.length; ++c) a.Ld(a.Pd[c]);

            a.Pd = [];
            Lh(a);
          } else "s" === d ? (a.f("Connection shutdown command received. Shutting down..."), a.Re && (a.Re(c), a.Re = null), a.na = null, a.close()) : "r" === d ? (a.f("Reset packet received.  New host: " + c), ed(a.G, c), 1 === a.N ? a.close() : (Mh(a), Gh(a))) : "e" === d ? qd("Server Error: " + c) : "o" === d ? (a.f("got pong on primary."), Nh(a), Oh(a)) : qd("Unknown control packet command: " + d);
        } else "d" == d && a.Ld(c);
      } else if (b === a.F) {
        if (d = wd("t", c), c = wd("d", c), "c" == d) "t" in c && (c = c.t, "a" === c ? Ph(a) : "r" === c ? (a.f("Got a reset on secondary, closing it"), a.F.close(), a.kd !== a.F && a.ed !== a.F || a.close()) : "o" === c && (a.f("got pong on secondary."), a.Tf--, Ph(a)));else if ("d" == d) a.Pd.push(c);else throw Error("Unknown protocol layer: " + d);
      } else a.f("message on old connection");
    };
  }

  Fh.prototype.Ia = function (a) {
    Qh(this || _global, {
      t: "d",
      d: a
    });
  };

  function Lh(a) {
    a.kd === a.F && a.ed === a.F && (a.f("cleaning up and promoting a connection: " + a.F.ue), a.K = a.F, a.F = null);
  }

  function Ph(a) {
    0 >= a.Tf ? (a.f("Secondary connection is healthy."), a.Eb = !0, a.F.Hd(), a.F.start(), a.f("sending client ack on secondary"), a.F.send({
      t: "c",
      d: {
        t: "a",
        d: {}
      }
    }), a.f("Ending transmission on primary"), a.K.send({
      t: "c",
      d: {
        t: "n",
        d: {}
      }
    }), a.kd = a.F, Lh(a)) : (a.f("sending ping on secondary."), a.F.send({
      t: "c",
      d: {
        t: "p",
        d: {}
      }
    }));
  }

  Fh.prototype.Ld = function (a) {
    Nh(this || _global);
    this.nc(a);
  };

  function Nh(a) {
    a.Eb || (a.Ve--, 0 >= a.Ve && (a.f("Primary connection is healthy."), a.Eb = !0, a.K.Hd()));
  }

  function Kh(a, b) {
    a.F = new b("c:" + a.id + ":" + a.kf++, a.G, a.Uf);
    a.Tf = b.responsesRequiredToBeHealthy || 0;
    a.F.open(Hh(a, a.F), Ih(a, a.F));
    setTimeout(function () {
      a.F && (a.f("Timed out trying to upgrade."), a.F.close());
    }, Math.floor(60000));
  }

  function Jh(a, b, c) {
    a.f("Realtime connection established.");
    a.K = b;
    a.N = 1;
    a.Zc && (a.Zc(c, a.Uf), a.Zc = null);
    0 === a.Ve ? (a.f("Primary connection is healthy."), a.Eb = !0) : setTimeout(function () {
      Oh(a);
    }, Math.floor(5000));
  }

  function Oh(a) {
    a.Eb || 1 !== a.N || (a.f("sending ping on primary."), Qh(a, {
      t: "c",
      d: {
        t: "p",
        d: {}
      }
    }));
  }

  function Qh(a, b) {
    if (1 !== a.N) throw "Connection is not connected";
    a.kd.send(b);
  }

  Fh.prototype.close = function () {
    2 !== (this || _global).N && (this.f("Closing realtime connection."), (this || _global).N = 2, Mh(this || _global), (this || _global).na && (this.na(), (this || _global).na = null));
  };

  function Mh(a) {
    a.f("Shutting down all connections");
    a.K && (a.K.close(), a.K = null);
    a.F && (a.F.close(), a.F = null);
    a.Bd && (clearTimeout(a.Bd), a.Bd = null);
  }

  ;

  function Rh(a, b, c, d) {
    (this || _global).id = Sh++;
    (this || _global).f = pd("p:" + (this || _global).id + ":");
    (this || _global).Bf = (this || _global).Ie = !1;
    (this || _global).ba = {};
    (this || _global).sa = [];
    (this || _global).ad = 0;
    (this || _global).Yc = [];
    (this || _global).qa = !1;
    (this || _global).eb = 1000;
    (this || _global).Id = 300000;
    (this || _global).Kb = b;
    (this || _global).Xc = c;
    (this || _global).Se = d;
    (this || _global).G = a;
    (this || _global).wb = (this || _global).Ca = (this || _global).Ma = (this || _global).Fb = (this || _global).$e = null;
    (this || _global).Sb = !1;
    (this || _global).Wd = {};
    (this || _global).Xg = 0;
    (this || _global).rf = !0;
    (this || _global).Oc = (this || _global).Ke = null;
    Th(this || _global, 0);
    kf.yb().Ib("visible", (this || _global).Og, this || _global);
    -1 === a.host.indexOf("fblocal") && jf.yb().Ib("online", (this || _global).Mg, this || _global);
  }

  var Sh = 0,
      Uh = 0;
  h = Rh.prototype;

  h.Ia = function (a, b, c) {
    var d = ++(this || _global).Xg;
    a = {
      r: d,
      a: a,
      b: b
    };
    this.f(G(a));
    O((this || _global).qa, "sendRequest call when we're not connected not allowed.");

    (this || _global).Ma.Ia(a);

    c && ((this || _global).Wd[d] = c);
  };

  h.Cf = function (a, b, c, d) {
    var e = a.wa(),
        f = a.path.toString();
    this.f("Listen called for " + f + " " + e);
    (this || _global).ba[f] = (this || _global).ba[f] || {};
    O(Ie(a.n) || !He(a.n), "listen() called for non-default but complete query");
    O(!(this || _global).ba[f][e], "listen() called twice for same path/queryId.");
    a = {
      I: d,
      Ad: b,
      Ug: a,
      tag: c
    };
    (this || _global).ba[f][e] = a;
    (this || _global).qa && Vh(this || _global, a);
  };

  function Vh(a, b) {
    var c = b.Ug,
        d = c.path.toString(),
        e = c.wa();
    a.f("Listen on " + d + " for " + e);
    var f = {
      p: d
    };
    b.tag && (f.q = Ge(c.n), f.t = b.tag);
    f.h = b.Ad();
    a.Ia("q", f, function (f) {
      var k = f.d,
          m = f.s;

      if (k && "object" === typeof k && y(k, "w")) {
        var l = z(k, "w");
        da(l) && 0 <= La(l, "no_index") && S("Using an unspecified index. Consider adding " + ("\".indexOn\": \"" + c.n.g.toString() + "\"") + " at " + c.path.toString() + " to your security rules for better performance");
      }

      (a.ba[d] && a.ba[d][e]) === b && (a.f("listen response", f), "ok" !== m && Wh(a, d, e), b.I && b.I(m, k));
    });
  }

  h.O = function (a, b, c) {
    (this || _global).Ca = {
      rg: a,
      sf: !1,
      Dc: b,
      od: c
    };
    this.f("Authenticating using credential: " + a);
    Xh(this || _global);
    (b = 40 == a.length) || (a = Cd(a).Ec, b = "object" === typeof a && !0 === z(a, "admin"));
    b && (this.f("Admin auth credential detected.  Reducing max reconnect time."), (this || _global).Id = 30000);
  };

  h.je = function (a) {
    (this || _global).Ca = null;
    (this || _global).qa && this.Ia("unauth", {}, function (b) {
      a(b.s, b.d);
    });
  };

  function Xh(a) {
    var b = a.Ca;
    a.qa && b && a.Ia("auth", {
      cred: b.rg
    }, function (c) {
      var d = c.s;
      c = c.d || "error";
      "ok" !== d && a.Ca === b && (a.Ca = null);
      b.sf ? "ok" !== d && b.od && b.od(d, c) : (b.sf = !0, b.Dc && b.Dc(d, c));
    });
  }

  h.$f = function (a, b) {
    var c = a.path.toString(),
        d = a.wa();
    this.f("Unlisten called for " + c + " " + d);
    O(Ie(a.n) || !He(a.n), "unlisten() called for non-default but complete query");

    if (Wh(this || _global, c, d) && (this || _global).qa) {
      var e = Ge(a.n);
      this.f("Unlisten on " + c + " for " + d);
      c = {
        p: c
      };
      b && (c.q = e, c.t = b);
      this.Ia("n", c);
    }
  };

  h.Qe = function (a, b, c) {
    (this || _global).qa ? Yh(this || _global, "o", a, b, c) : (this || _global).Yc.push({
      bd: a,
      action: "o",
      data: b,
      I: c
    });
  };

  h.Gf = function (a, b, c) {
    (this || _global).qa ? Yh(this || _global, "om", a, b, c) : (this || _global).Yc.push({
      bd: a,
      action: "om",
      data: b,
      I: c
    });
  };

  h.Md = function (a, b) {
    (this || _global).qa ? Yh(this || _global, "oc", a, null, b) : (this || _global).Yc.push({
      bd: a,
      action: "oc",
      data: null,
      I: b
    });
  };

  function Yh(a, b, c, d, e) {
    c = {
      p: c,
      d: d
    };
    a.f("onDisconnect " + b, c);
    a.Ia(b, c, function (a) {
      e && setTimeout(function () {
        e(a.s, a.d);
      }, Math.floor(0));
    });
  }

  h.put = function (a, b, c, d) {
    Zh(this || _global, "p", a, b, c, d);
  };

  h.Df = function (a, b, c, d) {
    Zh(this || _global, "m", a, b, c, d);
  };

  function Zh(a, b, c, d, e, f) {
    d = {
      p: c,
      d: d
    };
    p(f) && (d.h = f);
    a.sa.push({
      action: b,
      Pf: d,
      I: e
    });
    a.ad++;
    b = a.sa.length - 1;
    a.qa ? $h(a, b) : a.f("Buffering put: " + c);
  }

  function $h(a, b) {
    var c = a.sa[b].action,
        d = a.sa[b].Pf,
        e = a.sa[b].I;
    a.sa[b].Vg = a.qa;
    a.Ia(c, d, function (d) {
      a.f(c + " response", d);
      delete a.sa[b];
      a.ad--;
      0 === a.ad && (a.sa = []);
      e && e(d.s, d.d);
    });
  }

  h.Ye = function (a) {
    (this || _global).qa && (a = {
      c: a
    }, this.f("reportStats", a), this.Ia("s", a, function (a) {
      "ok" !== a.s && this.f("reportStats", "Error sending stats: " + a.d);
    }));
  };

  h.Ld = function (a) {
    if ("r" in a) {
      this.f("from server: " + G(a));
      var b = a.r,
          c = (this || _global).Wd[b];
      c && (delete (this || _global).Wd[b], c(a.b));
    } else {
      if ("error" in a) throw "A server-side error has occurred: " + a.error;
      "a" in a && (b = a.a, c = a.b, this.f("handleServerMessage", b, c), "d" === b ? this.Kb(c.p, c.d, !1, c.t) : "m" === b ? this.Kb(c.p, c.d, !0, c.t) : "c" === b ? ai(this || _global, c.p, c.q) : "ac" === b ? (a = c.s, b = c.d, c = (this || _global).Ca, (this || _global).Ca = null, c && c.od && c.od(a, b)) : "sd" === b ? (this || _global).$e ? this.$e(c) : "msg" in c && "undefined" !== typeof console && console.log("FIREBASE: " + c.msg.replace("\n", "\nFIREBASE: ")) : qd("Unrecognized action received from server: " + G(b) + "\nAre you using the latest client?"));
    }
  };

  h.Zc = function (a, b) {
    this.f("connection ready");
    (this || _global).qa = !0;
    (this || _global).Oc = new Date().getTime();
    this.Se({
      serverTimeOffset: a - new Date().getTime()
    });
    (this || _global).Fb = b;

    if ((this || _global).rf) {
      var c = {};
      c["sdk.js." + Eb.replace(/\./g, "-")] = 1;
      Dg() ? c["framework.cordova"] = 1 : "object" === typeof navigator && "ReactNative" === navigator.product && (c["framework.reactnative"] = 1);
      this.Ye(c);
    }

    bi(this || _global);
    (this || _global).rf = !1;
    this.Xc(!0);
  };

  function Th(a, b) {
    O(!a.Ma, "Scheduling a connect when we're already connected/ing?");
    a.wb && clearTimeout(a.wb);
    a.wb = setTimeout(function () {
      a.wb = null;
      ci(a);
    }, Math.floor(b));
  }

  h.Og = function (a) {
    a && !(this || _global).Sb && (this || _global).eb === (this || _global).Id && (this.f("Window became visible.  Reducing delay."), (this || _global).eb = 1000, (this || _global).Ma || Th(this || _global, 0));
    (this || _global).Sb = a;
  };

  h.Mg = function (a) {
    a ? (this.f("Browser went online."), (this || _global).eb = 1000, (this || _global).Ma || Th(this || _global, 0)) : (this.f("Browser went offline.  Killing connection."), (this || _global).Ma && (this || _global).Ma.close());
  };

  h.If = function () {
    this.f("data client disconnected");
    (this || _global).qa = !1;
    (this || _global).Ma = null;

    for (var a = 0; a < (this || _global).sa.length; a++) {
      var b = (this || _global).sa[a];
      b && "h" in b.Pf && b.Vg && (b.I && b.I("disconnect"), delete (this || _global).sa[a], (this || _global).ad--);
    }

    0 === (this || _global).ad && ((this || _global).sa = []);
    (this || _global).Wd = {};
    di(this || _global) && ((this || _global).Sb ? (this || _global).Oc && (30000 < new Date().getTime() - (this || _global).Oc && ((this || _global).eb = 1000), (this || _global).Oc = null) : (this.f("Window isn't visible.  Delaying reconnect."), (this || _global).eb = (this || _global).Id, (this || _global).Ke = new Date().getTime()), a = Math.max(0, (this || _global).eb - (new Date().getTime() - (this || _global).Ke)), a *= Math.random(), this.f("Trying to reconnect in " + a + "ms"), Th(this || _global, a), (this || _global).eb = Math.min((this || _global).Id, 1.3 * (this || _global).eb));
    this.Xc(!1);
  };

  function ci(a) {
    if (di(a)) {
      a.f("Making a connection attempt");
      a.Ke = new Date().getTime();
      a.Oc = null;
      var b = u(a.Ld, a),
          c = u(a.Zc, a),
          d = u(a.If, a),
          e = a.id + ":" + Uh++;
      a.Ma = new Fh(e, a.G, b, c, d, function (b) {
        S(b + " (" + a.G.toString() + ")");
        a.Bf = !0;
      }, a.Fb);
    }
  }

  h.Cb = function () {
    (this || _global).Ie = !0;
    (this || _global).Ma ? (this || _global).Ma.close() : ((this || _global).wb && (clearTimeout((this || _global).wb), (this || _global).wb = null), (this || _global).qa && this.If());
  };

  h.vc = function () {
    (this || _global).Ie = !1;
    (this || _global).eb = 1000;
    (this || _global).Ma || Th(this || _global, 0);
  };

  function ai(a, b, c) {
    c = c ? Oa(c, function (a) {
      return xd(a);
    }).join("$") : "default";
    (a = Wh(a, b, c)) && a.I && a.I("permission_denied");
  }

  function Wh(a, b, c) {
    b = new P(b).toString();
    var d;
    p(a.ba[b]) ? (d = a.ba[b][c], delete a.ba[b][c], 0 === oa(a.ba[b]) && delete a.ba[b]) : d = void 0;
    return d;
  }

  function bi(a) {
    Xh(a);
    v(a.ba, function (b) {
      v(b, function (b) {
        Vh(a, b);
      });
    });

    for (var b = 0; b < a.sa.length; b++) a.sa[b] && $h(a, b);

    for (; a.Yc.length;) b = a.Yc.shift(), Yh(a, b.action, b.bd, b.data, b.I);
  }

  function di(a) {
    var b;
    b = jf.yb().oc;
    return !a.Bf && !a.Ie && b;
  }

  ;
  var U = {
    zg: function () {
      oh = xh = !0;
    }
  };
  U.forceLongPolling = U.zg;

  U.Ag = function () {
    ph = !0;
  };

  U.forceWebSockets = U.Ag;

  U.Eg = function () {
    return wh.isAvailable();
  };

  U.isWebSocketsAvailable = U.Eg;

  U.ah = function (a, b) {
    a.k.Va.$e = b;
  };

  U.setSecurityDebugCallback = U.ah;

  U.bf = function (a, b) {
    a.k.bf(b);
  };

  U.stats = U.bf;

  U.cf = function (a, b) {
    a.k.cf(b);
  };

  U.statsIncrementCounter = U.cf;

  U.ud = function (a) {
    return a.k.ud;
  };

  U.dataUpdateCount = U.ud;

  U.Dg = function (a, b) {
    a.k.He = b;
  };

  U.interceptServerData = U.Dg;

  U.Kg = function (a) {
    new Og(a);
  };

  U.onPopupOpen = U.Kg;

  U.Zg = function (a) {
    xg = a;
  };

  U.setAuthenticationServer = U.Zg;

  function ei(a, b) {
    (this || _global).committed = a;
    (this || _global).snapshot = b;
  }

  ;

  function V(a, b) {
    (this || _global).dd = a;
    (this || _global).ta = b;
  }

  V.prototype.cancel = function (a) {
    D("Firebase.onDisconnect().cancel", 0, 1, arguments.length);
    F("Firebase.onDisconnect().cancel", 1, a, !0);
    var b = new B();

    (this || _global).dd.Md((this || _global).ta, C(b, a));

    return b.D;
  };

  V.prototype.cancel = V.prototype.cancel;

  V.prototype.remove = function (a) {
    D("Firebase.onDisconnect().remove", 0, 1, arguments.length);
    og("Firebase.onDisconnect().remove", (this || _global).ta);
    F("Firebase.onDisconnect().remove", 1, a, !0);
    var b = new B();
    fi((this || _global).dd, (this || _global).ta, null, C(b, a));
    return b.D;
  };

  V.prototype.remove = V.prototype.remove;

  V.prototype.set = function (a, b) {
    D("Firebase.onDisconnect().set", 1, 2, arguments.length);
    og("Firebase.onDisconnect().set", (this || _global).ta);
    gg("Firebase.onDisconnect().set", a, (this || _global).ta, !1);
    F("Firebase.onDisconnect().set", 2, b, !0);
    var c = new B();
    fi((this || _global).dd, (this || _global).ta, a, C(c, b));
    return c.D;
  };

  V.prototype.set = V.prototype.set;

  V.prototype.Ob = function (a, b, c) {
    D("Firebase.onDisconnect().setWithPriority", 2, 3, arguments.length);
    og("Firebase.onDisconnect().setWithPriority", (this || _global).ta);
    gg("Firebase.onDisconnect().setWithPriority", a, (this || _global).ta, !1);
    kg("Firebase.onDisconnect().setWithPriority", 2, b);
    F("Firebase.onDisconnect().setWithPriority", 3, c, !0);
    var d = new B();
    gi((this || _global).dd, (this || _global).ta, a, b, C(d, c));
    return d.D;
  };

  V.prototype.setWithPriority = V.prototype.Ob;

  V.prototype.update = function (a, b) {
    D("Firebase.onDisconnect().update", 1, 2, arguments.length);
    og("Firebase.onDisconnect().update", (this || _global).ta);

    if (da(a)) {
      for (var c = {}, d = 0; d < a.length; ++d) c["" + d] = a[d];

      a = c;
      S("Passing an Array to Firebase.onDisconnect().update() is deprecated. Use set() if you want to overwrite the existing data, or an Object with integer keys if you really do want to only update some of the children.");
    }

    jg("Firebase.onDisconnect().update", a, (this || _global).ta);
    F("Firebase.onDisconnect().update", 2, b, !0);
    c = new B();
    hi((this || _global).dd, (this || _global).ta, a, C(c, b));
    return c.D;
  };

  V.prototype.update = V.prototype.update;

  function W(a, b, c) {
    (this || _global).A = a;
    (this || _global).Y = b;
    (this || _global).g = c;
  }

  W.prototype.J = function () {
    D("Firebase.DataSnapshot.val", 0, 0, arguments.length);
    return (this || _global).A.J();
  };

  W.prototype.val = W.prototype.J;

  W.prototype.qf = function () {
    D("Firebase.DataSnapshot.exportVal", 0, 0, arguments.length);
    return (this || _global).A.J(!0);
  };

  W.prototype.exportVal = W.prototype.qf;

  W.prototype.xg = function () {
    D("Firebase.DataSnapshot.exists", 0, 0, arguments.length);
    return !(this || _global).A.e();
  };

  W.prototype.exists = W.prototype.xg;

  W.prototype.o = function (a) {
    D("Firebase.DataSnapshot.child", 0, 1, arguments.length);
    fa(a) && (a = String(a));
    ng("Firebase.DataSnapshot.child", a);

    var b = new P(a),
        c = (this || _global).Y.o(b);

    return new W((this || _global).A.S(b), c, R);
  };

  W.prototype.child = W.prototype.o;

  W.prototype.Fa = function (a) {
    D("Firebase.DataSnapshot.hasChild", 1, 1, arguments.length);
    ng("Firebase.DataSnapshot.hasChild", a);
    var b = new P(a);
    return !(this || _global).A.S(b).e();
  };

  W.prototype.hasChild = W.prototype.Fa;

  W.prototype.C = function () {
    D("Firebase.DataSnapshot.getPriority", 0, 0, arguments.length);
    return (this || _global).A.C().J();
  };

  W.prototype.getPriority = W.prototype.C;

  W.prototype.forEach = function (a) {
    D("Firebase.DataSnapshot.forEach", 1, 1, arguments.length);
    F("Firebase.DataSnapshot.forEach", 1, a, !1);
    if ((this || _global).A.L()) return !1;
    var b = this || _global;
    return !!(this || _global).A.R((this || _global).g, function (c, d) {
      return a(new W(d, b.Y.o(c), R));
    });
  };

  W.prototype.forEach = W.prototype.forEach;

  W.prototype.zd = function () {
    D("Firebase.DataSnapshot.hasChildren", 0, 0, arguments.length);
    return (this || _global).A.L() ? !1 : !(this || _global).A.e();
  };

  W.prototype.hasChildren = W.prototype.zd;

  W.prototype.name = function () {
    S("Firebase.DataSnapshot.name() being deprecated. Please use Firebase.DataSnapshot.key() instead.");
    D("Firebase.DataSnapshot.name", 0, 0, arguments.length);
    return this.key();
  };

  W.prototype.name = W.prototype.name;

  W.prototype.key = function () {
    D("Firebase.DataSnapshot.key", 0, 0, arguments.length);
    return (this || _global).Y.key();
  };

  W.prototype.key = W.prototype.key;

  W.prototype.Hb = function () {
    D("Firebase.DataSnapshot.numChildren", 0, 0, arguments.length);
    return (this || _global).A.Hb();
  };

  W.prototype.numChildren = W.prototype.Hb;

  W.prototype.Mb = function () {
    D("Firebase.DataSnapshot.ref", 0, 0, arguments.length);
    return (this || _global).Y;
  };

  W.prototype.ref = W.prototype.Mb;

  function ii(a, b, c) {
    (this || _global).Vb = a;
    (this || _global).tb = b;
    (this || _global).vb = c || null;
  }

  h = ii.prototype;

  h.Qf = function (a) {
    return "value" === a;
  };

  h.createEvent = function (a, b) {
    var c = b.n.g;
    return new jc("value", this || _global, new W(a.Na, b.Mb(), c));
  };

  h.Zb = function (a) {
    var b = (this || _global).vb;

    if ("cancel" === a.De()) {
      O((this || _global).tb, "Raising a cancel event on a listener with no cancel callback");
      var c = (this || _global).tb;
      return function () {
        c.call(b, a.error);
      };
    }

    var d = (this || _global).Vb;
    return function () {
      d.call(b, a.be);
    };
  };

  h.lf = function (a, b) {
    return (this || _global).tb ? new kc(this || _global, a, b) : null;
  };

  h.matches = function (a) {
    return a instanceof ii ? a.Vb && (this || _global).Vb ? a.Vb === (this || _global).Vb && a.vb === (this || _global).vb : !0 : !1;
  };

  h.yf = function () {
    return null !== (this || _global).Vb;
  };

  function ji(a, b, c) {
    (this || _global).ja = a;
    (this || _global).tb = b;
    (this || _global).vb = c;
  }

  h = ji.prototype;

  h.Qf = function (a) {
    a = "children_added" === a ? "child_added" : a;
    return ("children_removed" === a ? "child_removed" : a) in (this || _global).ja;
  };

  h.lf = function (a, b) {
    return (this || _global).tb ? new kc(this || _global, a, b) : null;
  };

  h.createEvent = function (a, b) {
    O(null != a.Za, "Child events should have a childName.");
    var c = b.Mb().o(a.Za);
    return new jc(a.type, this || _global, new W(a.Na, c, b.n.g), a.Td);
  };

  h.Zb = function (a) {
    var b = (this || _global).vb;

    if ("cancel" === a.De()) {
      O((this || _global).tb, "Raising a cancel event on a listener with no cancel callback");
      var c = (this || _global).tb;
      return function () {
        c.call(b, a.error);
      };
    }

    var d = (this || _global).ja[a.wd];
    return function () {
      d.call(b, a.be, a.Td);
    };
  };

  h.matches = function (a) {
    if (a instanceof ji) {
      if (!(this || _global).ja || !a.ja) return !0;

      if ((this || _global).vb === a.vb) {
        var b = oa(a.ja);

        if (b === oa((this || _global).ja)) {
          if (1 === b) {
            var b = pa(a.ja),
                c = pa((this || _global).ja);
            return c === b && (!a.ja[b] || !(this || _global).ja[c] || a.ja[b] === (this || _global).ja[c]);
          }

          return na((this || _global).ja, function (b, c) {
            return a.ja[c] === b;
          });
        }
      }
    }

    return !1;
  };

  h.yf = function () {
    return null !== (this || _global).ja;
  };

  function ki() {
    (this || _global).za = {};
  }

  h = ki.prototype;

  h.e = function () {
    return va((this || _global).za);
  };

  h.gb = function (a, b, c) {
    var d = a.source.Lb;
    if (null !== d) return d = z((this || _global).za, d), O(null != d, "SyncTree gave us an op for an invalid query."), d.gb(a, b, c);
    var e = [];
    v((this || _global).za, function (d) {
      e = e.concat(d.gb(a, b, c));
    });
    return e;
  };

  h.Tb = function (a, b, c, d, e) {
    var f = a.wa(),
        g = z((this || _global).za, f);

    if (!g) {
      var g = c.Aa(e ? d : null),
          k = !1;
      g ? k = !0 : (g = d instanceof fe ? c.Cc(d) : H, k = !1);
      g = new Ye(a, new je(new Xb(g, k, !1), new Xb(d, e, !1)));
      (this || _global).za[f] = g;
    }

    g.Tb(b);
    return af(g, b);
  };

  h.nb = function (a, b, c) {
    var d = a.wa(),
        e = [],
        f = [],
        g = null != li(this || _global);

    if ("default" === d) {
      var k = this || _global;
      v((this || _global).za, function (a, d) {
        f = f.concat(a.nb(b, c));
        a.e() && (delete k.za[d], He(a.Y.n) || e.push(a.Y));
      });
    } else {
      var m = z((this || _global).za, d);
      m && (f = f.concat(m.nb(b, c)), m.e() && (delete (this || _global).za[d], He(m.Y.n) || e.push(m.Y)));
    }

    g && null == li(this || _global) && e.push(new X(a.k, a.path));
    return {
      Wg: e,
      vg: f
    };
  };

  function mi(a) {
    return Na(qa(a.za), function (a) {
      return !He(a.Y.n);
    });
  }

  h.kb = function (a) {
    var b = null;
    v((this || _global).za, function (c) {
      b = b || c.kb(a);
    });
    return b;
  };

  function ni(a, b) {
    if (He(b.n)) return li(a);
    var c = b.wa();
    return z(a.za, c);
  }

  function li(a) {
    return ua(a.za, function (a) {
      return He(a.Y.n);
    }) || null;
  }

  ;

  function oi(a) {
    (this || _global).va = qe;
    (this || _global).mb = new Pf();
    (this || _global).df = {};
    (this || _global).qc = {};
    (this || _global).Qc = a;
  }

  function pi(a, b, c, d, e) {
    var f = a.mb,
        g = e;
    O(d > f.Pc, "Stacking an older write on top of newer ones");
    p(g) || (g = !0);
    f.pa.push({
      path: b,
      Ja: c,
      md: d,
      visible: g
    });
    g && (f.V = Jf(f.V, b, c));
    f.Pc = d;
    return e ? qi(a, new Ac(Ef, b, c)) : [];
  }

  function ri(a, b, c, d) {
    var e = a.mb;
    O(d > e.Pc, "Stacking an older merge on top of newer ones");
    e.pa.push({
      path: b,
      children: c,
      md: d,
      visible: !0
    });
    e.V = Kf(e.V, b, c);
    e.Pc = d;
    c = sf(c);
    return qi(a, new bf(Ef, b, c));
  }

  function si(a, b, c) {
    c = c || !1;
    var d = Qf(a.mb, b);

    if (a.mb.Ud(b)) {
      var e = qe;
      null != d.Ja ? e = e.set(M, !0) : Fb(d.children, function (a, b) {
        e = e.set(new P(a), b);
      });
      return qi(a, new Df(d.path, e, c));
    }

    return [];
  }

  function ti(a, b, c) {
    c = sf(c);
    return qi(a, new bf(Gf, b, c));
  }

  function ui(a, b, c, d) {
    d = vi(a, d);

    if (null != d) {
      var e = wi(d);
      d = e.path;
      e = e.Lb;
      b = lf(d, b);
      c = new Ac(new Ff(!1, !0, e, !0), b, c);
      return xi(a, d, c);
    }

    return [];
  }

  function yi(a, b, c, d) {
    if (d = vi(a, d)) {
      var e = wi(d);
      d = e.path;
      e = e.Lb;
      b = lf(d, b);
      c = sf(c);
      c = new bf(new Ff(!1, !0, e, !0), b, c);
      return xi(a, d, c);
    }

    return [];
  }

  oi.prototype.Tb = function (a, b) {
    var c = a.path,
        d = null,
        e = !1;
    zf((this || _global).va, c, function (a, b) {
      var f = lf(a, c);
      d = d || b.kb(f);
      e = e || null != li(b);
    });

    var f = (this || _global).va.get(c);

    f ? (e = e || null != li(f), d = d || f.kb(M)) : (f = new ki(), (this || _global).va = (this || _global).va.set(c, f));
    var g;
    null != d ? g = !0 : (g = !1, d = H, Cf((this || _global).va.subtree(c), function (a, b) {
      var c = b.kb(M);
      c && (d = d.W(a, c));
    }));
    var k = null != ni(f, a);

    if (!k && !He(a.n)) {
      var m = zi(a);
      O(!(m in (this || _global).qc), "View does not exist, but we have a tag");
      var l = Ai++;
      (this || _global).qc[m] = l;
      (this || _global).df["_" + l] = m;
    }

    g = f.Tb(a, b, new Uf(c, (this || _global).mb), d, g);
    k || e || (f = ni(f, a), g = g.concat(Bi(this || _global, a, f)));
    return g;
  };

  oi.prototype.nb = function (a, b, c) {
    var d = a.path,
        e = (this || _global).va.get(d),
        f = [];

    if (e && ("default" === a.wa() || null != ni(e, a))) {
      f = e.nb(a, b, c);
      e.e() && ((this || _global).va = (this || _global).va.remove(d));
      e = f.Wg;
      f = f.vg;
      b = -1 !== Sa(e, function (a) {
        return He(a.n);
      });
      var g = xf((this || _global).va, d, function (a, b) {
        return null != li(b);
      });
      if (b && !g && (d = (this || _global).va.subtree(d), !d.e())) for (var d = Ci(d), k = 0; k < d.length; ++k) {
        var m = d[k],
            l = m.Y,
            m = Di(this || _global, m);

        (this || _global).Qc.af(Ei(l), Fi(this || _global, l), m.Ad, m.I);
      }
      if (!g && 0 < e.length && !c) if (b) (this || _global).Qc.de(Ei(a), null);else {
        var t = this || _global;
        Ma(e, function (a) {
          a.wa();
          var b = t.qc[zi(a)];
          t.Qc.de(Ei(a), b);
        });
      }
      Gi(this || _global, e);
    }

    return f;
  };

  oi.prototype.Aa = function (a, b) {
    var c = (this || _global).mb,
        d = xf((this || _global).va, a, function (b, c) {
      var d = lf(b, a);
      if (d = c.kb(d)) return d;
    });
    return c.Aa(a, d, b, !0);
  };

  function Ci(a) {
    return vf(a, function (a, c, d) {
      if (c && null != li(c)) return [li(c)];
      var e = [];
      c && (e = mi(c));
      v(d, function (a) {
        e = e.concat(a);
      });
      return e;
    });
  }

  function Gi(a, b) {
    for (var c = 0; c < b.length; ++c) {
      var d = b[c];

      if (!He(d.n)) {
        var d = zi(d),
            e = a.qc[d];
        delete a.qc[d];
        delete a.df["_" + e];
      }
    }
  }

  function Ei(a) {
    return He(a.n) && !Ie(a.n) ? a.Mb() : a;
  }

  function Bi(a, b, c) {
    var d = b.path,
        e = Fi(a, b);
    c = Di(a, c);
    b = a.Qc.af(Ei(b), e, c.Ad, c.I);
    d = a.va.subtree(d);
    if (e) O(null == li(d.value), "If we're adding a query, it shouldn't be shadowed");else for (e = vf(d, function (a, b, c) {
      if (!a.e() && b && null != li(b)) return [Ze(li(b))];
      var d = [];
      b && (d = d.concat(Oa(mi(b), function (a) {
        return a.Y;
      })));
      v(c, function (a) {
        d = d.concat(a);
      });
      return d;
    }), d = 0; d < e.length; ++d) c = e[d], a.Qc.de(Ei(c), Fi(a, c));
    return b;
  }

  function Di(a, b) {
    var c = b.Y,
        d = Fi(a, c);
    return {
      Ad: function () {
        return (b.w() || H).hash();
      },
      I: function (b) {
        if ("ok" === b) {
          if (d) {
            var f = c.path;

            if (b = vi(a, d)) {
              var g = wi(b);
              b = g.path;
              g = g.Lb;
              f = lf(b, f);
              f = new Cc(new Ff(!1, !0, g, !0), f);
              b = xi(a, b, f);
            } else b = [];
          } else b = qi(a, new Cc(Gf, c.path));

          return b;
        }

        f = "Unknown Error";
        "too_big" === b ? f = "The data requested exceeds the maximum size that can be accessed with a single request." : "permission_denied" == b ? f = "Client doesn't have permission to access the desired data." : "unavailable" == b && (f = "The service is unavailable");
        f = Error(b + " at " + c.path.toString() + ": " + f);
        f.code = b.toUpperCase();
        return a.nb(c, null, f);
      }
    };
  }

  function zi(a) {
    return a.path.toString() + "$" + a.wa();
  }

  function wi(a) {
    var b = a.indexOf("$");
    O(-1 !== b && b < a.length - 1, "Bad queryKey.");
    return {
      Lb: a.substr(b + 1),
      path: new P(a.substr(0, b))
    };
  }

  function vi(a, b) {
    var c = a.df,
        d = "_" + b;
    return d in c ? c[d] : void 0;
  }

  function Fi(a, b) {
    var c = zi(b);
    return z(a.qc, c);
  }

  var Ai = 1;

  function xi(a, b, c) {
    var d = a.va.get(b);
    O(d, "Missing sync point for query tag that we're tracking");
    return d.gb(c, new Uf(b, a.mb), null);
  }

  function qi(a, b) {
    return Hi(a, b, a.va, null, new Uf(M, a.mb));
  }

  function Hi(a, b, c, d, e) {
    if (b.path.e()) return Ii(a, b, c, d, e);
    var f = c.get(M);
    null == d && null != f && (d = f.kb(M));
    var g = [],
        k = K(b.path),
        m = b.$c(k);
    if ((c = c.children.get(k)) && m) var l = d ? d.T(k) : null,
        k = e.o(k),
        g = g.concat(Hi(a, m, c, l, k));
    f && (g = g.concat(f.gb(b, e, d)));
    return g;
  }

  function Ii(a, b, c, d, e) {
    var f = c.get(M);
    null == d && null != f && (d = f.kb(M));
    var g = [];
    c.children.ka(function (c, f) {
      var l = d ? d.T(c) : null,
          t = e.o(c),
          A = b.$c(c);
      A && (g = g.concat(Ii(a, A, f, l, t)));
    });
    f && (g = g.concat(f.gb(b, e, d)));
    return g;
  }

  ;

  function Ji(a, b) {
    (this || _global).G = a;
    (this || _global).Xa = uc(a);
    (this || _global).hd = null;
    (this || _global).fa = new Zb();
    (this || _global).Kd = 1;
    (this || _global).Va = null;
    b || 0 <= ("object" === typeof window && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) ? ((this || _global).da = new cf((this || _global).G, u((this || _global).Kb, this || _global)), setTimeout(u((this || _global).Xc, this || _global, !0), 0)) : (this || _global).da = (this || _global).Va = new Rh((this || _global).G, u((this || _global).Kb, this || _global), u((this || _global).Xc, this || _global), u((this || _global).Se, this || _global));
    (this || _global).eh = vc(a, u(function () {
      return new pc((this || _global).Xa, (this || _global).da);
    }, this || _global));
    (this || _global).yc = new Wf();
    (this || _global).Ge = new Sb();
    var c = this || _global;
    (this || _global).Fd = new oi({
      af: function (a, b, f, g) {
        b = [];
        f = c.Ge.j(a.path);
        f.e() || (b = qi(c.Fd, new Ac(Gf, a.path, f)), setTimeout(function () {
          g("ok");
        }, 0));
        return b;
      },
      de: aa
    });
    Ki(this || _global, "connected", !1);
    (this || _global).na = new Vc();
    (this || _global).O = new Yg(a, u((this || _global).da.O, (this || _global).da), u((this || _global).da.je, (this || _global).da), u((this || _global).Pe, this || _global));
    (this || _global).ud = 0;
    (this || _global).He = null;
    (this || _global).M = new oi({
      af: function (a, b, f, g) {
        c.da.Cf(a, f, b, function (b, e) {
          var f = g(b, e);
          dc(c.fa, a.path, f);
        });
        return [];
      },
      de: function (a, b) {
        c.da.$f(a, b);
      }
    });
  }

  h = Ji.prototype;

  h.toString = function () {
    return ((this || _global).G.ob ? "https://" : "http://") + (this || _global).G.host;
  };

  h.name = function () {
    return (this || _global).G.lc;
  };

  function Li(a) {
    a = a.Ge.j(new P(".info/serverTimeOffset")).J() || 0;
    return new Date().getTime() + a;
  }

  function Mi(a) {
    a = a = {
      timestamp: Li(a)
    };
    a.timestamp = a.timestamp || new Date().getTime();
    return a;
  }

  h.Kb = function (a, b, c, d) {
    (this || _global).ud++;
    var e = new P(a);
    b = (this || _global).He ? this.He(a, b) : b;
    a = [];
    d ? c ? (b = ma(b, function (a) {
      return Q(a);
    }), a = yi((this || _global).M, e, b, d)) : (b = Q(b), a = ui((this || _global).M, e, b, d)) : c ? (d = ma(b, function (a) {
      return Q(a);
    }), a = ti((this || _global).M, e, d)) : (d = Q(b), a = qi((this || _global).M, new Ac(Gf, e, d)));
    d = e;
    0 < a.length && (d = Ni(this || _global, e));
    dc((this || _global).fa, d, a);
  };

  h.Xc = function (a) {
    Ki(this || _global, "connected", a);
    !1 === a && Oi(this || _global);
  };

  h.Se = function (a) {
    var b = this || _global;
    zd(a, function (a, d) {
      Ki(b, d, a);
    });
  };

  h.Pe = function (a) {
    Ki(this || _global, "authenticated", a);
  };

  function Ki(a, b, c) {
    b = new P("/.info/" + b);
    c = Q(c);
    var d = a.Ge;
    d.Zd = d.Zd.H(b, c);
    c = qi(a.Fd, new Ac(Gf, b, c));
    dc(a.fa, b, c);
  }

  h.Ob = function (a, b, c, d) {
    this.f("set", {
      path: a.toString(),
      value: b,
      nh: c
    });
    var e = Mi(this || _global);
    b = Q(b, c);
    var e = Xc(b, e),
        f = (this || _global).Kd++,
        e = pi((this || _global).M, a, e, f, !0);
    $b((this || _global).fa, e);
    var g = this || _global;

    (this || _global).da.put(a.toString(), b.J(!0), function (b, c) {
      var e = "ok" === b;
      e || S("set at " + a + " failed: " + b);
      e = si(g.M, f, !e);
      dc(g.fa, a, e);
      Pi(d, b, c);
    });

    e = Qi(this || _global, a);
    Ni(this || _global, e);
    dc((this || _global).fa, e, []);
  };

  h.update = function (a, b, c) {
    this.f("update", {
      path: a.toString(),
      value: b
    });
    var d = !0,
        e = Mi(this || _global),
        f = {};
    v(b, function (a, b) {
      d = !1;
      var c = Q(a);
      f[b] = Xc(c, e);
    });
    if (d) fc("update() called with empty data.  Don't do anything."), Pi(c, "ok");else {
      var g = (this || _global).Kd++,
          k = ri((this || _global).M, a, f, g);
      $b((this || _global).fa, k);
      var m = this || _global;

      (this || _global).da.Df(a.toString(), b, function (b, d) {
        var e = "ok" === b;
        e || S("update at " + a + " failed: " + b);
        var e = si(m.M, g, !e),
            f = a;
        0 < e.length && (f = Ni(m, a));
        dc(m.fa, f, e);
        Pi(c, b, d);
      });

      b = Qi(this || _global, a);
      Ni(this || _global, b);
      dc((this || _global).fa, a, []);
    }
  };

  function Oi(a) {
    a.f("onDisconnectEvents");
    var b = Mi(a),
        c = [];
    Wc(Uc(a.na, b), M, function (b, e) {
      c = c.concat(qi(a.M, new Ac(Gf, b, e)));
      var f = Qi(a, b);
      Ni(a, f);
    });
    a.na = new Vc();
    dc(a.fa, M, c);
  }

  h.Md = function (a, b) {
    var c = this || _global;

    (this || _global).da.Md(a.toString(), function (d, e) {
      "ok" === d && wg(c.na, a);
      Pi(b, d, e);
    });
  };

  function fi(a, b, c, d) {
    var e = Q(c);
    a.da.Qe(b.toString(), e.J(!0), function (c, g) {
      "ok" === c && a.na.rc(b, e);
      Pi(d, c, g);
    });
  }

  function gi(a, b, c, d, e) {
    var f = Q(c, d);
    a.da.Qe(b.toString(), f.J(!0), function (c, d) {
      "ok" === c && a.na.rc(b, f);
      Pi(e, c, d);
    });
  }

  function hi(a, b, c, d) {
    var e = !0,
        f;

    for (f in c) e = !1;

    e ? (fc("onDisconnect().update() called with empty data.  Don't do anything."), Pi(d, "ok")) : a.da.Gf(b.toString(), c, function (e, f) {
      if ("ok" === e) for (var m in c) {
        var l = Q(c[m]);
        a.na.rc(b.o(m), l);
      }
      Pi(d, e, f);
    });
  }

  function Ri(a, b, c) {
    c = ".info" === K(b.path) ? a.Fd.Tb(b, c) : a.M.Tb(b, c);
    bc(a.fa, b.path, c);
  }

  h.Cb = function () {
    (this || _global).Va && (this || _global).Va.Cb();
  };

  h.vc = function () {
    (this || _global).Va && (this || _global).Va.vc();
  };

  h.bf = function (a) {
    if ("undefined" !== typeof console) {
      a ? ((this || _global).hd || ((this || _global).hd = new oc((this || _global).Xa)), a = (this || _global).hd.get()) : a = (this || _global).Xa.get();
      var b = Pa(ra(a), function (a, b) {
        return Math.max(b.length, a);
      }, 0),
          c;

      for (c in a) {
        for (var d = a[c], e = c.length; e < b + 2; e++) c += " ";

        console.log(c + d);
      }
    }
  };

  h.cf = function (a) {
    rc((this || _global).Xa, a);
    (this || _global).eh.Vf[a] = !0;
  };

  h.f = function (a) {
    var b = "";
    (this || _global).Va && (b = (this || _global).Va.id + ":");
    fc(b, arguments);
  };

  function Pi(a, b, c) {
    a && gc(function () {
      if ("ok" == b) a(null);else {
        var d = (b || "error").toUpperCase(),
            e = d;
        c && (e += ": " + c);
        e = Error(e);
        e.code = d;
        a(e);
      }
    });
  }

  ;

  function Si(a, b, c, d, e) {
    function f() {}

    a.f("transaction on " + b);
    var g = new X(a, b);
    g.Ib("value", f);
    c = {
      path: b,
      update: c,
      I: d,
      status: null,
      Lf: id(),
      gf: e,
      Sf: 0,
      le: function () {
        g.mc("value", f);
      },
      ne: null,
      Da: null,
      rd: null,
      sd: null,
      td: null
    };
    d = a.M.Aa(b, void 0) || H;
    c.rd = d;
    d = c.update(d.J());

    if (p(d)) {
      hg("transaction failed: Data returned ", d, c.path);
      c.status = 1;
      e = Xf(a.yc, b);
      var k = e.Ea() || [];
      k.push(c);
      Yf(e, k);
      "object" === typeof d && null !== d && y(d, ".priority") ? (k = z(d, ".priority"), O(fg(k), "Invalid priority returned by transaction. Priority must be a valid string, finite number, server value, or null.")) : k = (a.M.Aa(b) || H).C().J();
      e = Mi(a);
      d = Q(d, k);
      e = Xc(d, e);
      c.sd = d;
      c.td = e;
      c.Da = a.Kd++;
      c = pi(a.M, b, e, c.Da, c.gf);
      dc(a.fa, b, c);
      Ti(a);
    } else c.le(), c.sd = null, c.td = null, c.I && (a = new W(c.rd, new X(a, c.path), R), c.I(null, !1, a));
  }

  function Ti(a, b) {
    var c = b || a.yc;
    b || Ui(a, c);

    if (null !== c.Ea()) {
      var d = Vi(a, c);
      O(0 < d.length, "Sending zero length transaction queue");
      Qa(d, function (a) {
        return 1 === a.status;
      }) && Wi(a, c.path(), d);
    } else c.zd() && c.R(function (b) {
      Ti(a, b);
    });
  }

  function Wi(a, b, c) {
    for (var d = Oa(c, function (a) {
      return a.Da;
    }), e = a.M.Aa(b, d) || H, d = e, e = e.hash(), f = 0; f < c.length; f++) {
      var g = c[f];
      O(1 === g.status, "tryToSendTransactionQueue_: items in queue should all be run.");
      g.status = 2;
      g.Sf++;
      var k = lf(b, g.path),
          d = d.H(k, g.sd);
    }

    d = d.J(!0);
    a.da.put(b.toString(), d, function (d) {
      a.f("transaction put response", {
        path: b.toString(),
        status: d
      });
      var e = [];

      if ("ok" === d) {
        d = [];

        for (f = 0; f < c.length; f++) {
          c[f].status = 3;
          e = e.concat(si(a.M, c[f].Da));

          if (c[f].I) {
            var g = c[f].td,
                k = new X(a, c[f].path);
            d.push(u(c[f].I, null, null, !0, new W(g, k, R)));
          }

          c[f].le();
        }

        Ui(a, Xf(a.yc, b));
        Ti(a);
        dc(a.fa, b, e);

        for (f = 0; f < d.length; f++) gc(d[f]);
      } else {
        if ("datastale" === d) for (f = 0; f < c.length; f++) c[f].status = 4 === c[f].status ? 5 : 1;else for (S("transaction at " + b.toString() + " failed: " + d), f = 0; f < c.length; f++) c[f].status = 5, c[f].ne = d;
        Ni(a, b);
      }
    }, e);
  }

  function Ni(a, b) {
    var c = Xi(a, b),
        d = c.path(),
        c = Vi(a, c);
    Yi(a, c, d);
    return d;
  }

  function Yi(a, b, c) {
    if (0 !== b.length) {
      for (var d = [], e = [], f = Na(b, function (a) {
        return 1 === a.status;
      }), f = Oa(f, function (a) {
        return a.Da;
      }), g = 0; g < b.length; g++) {
        var k = b[g],
            m = lf(c, k.path),
            l = !1,
            t;
        O(null !== m, "rerunTransactionsUnderNode_: relativePath should not be null.");
        if (5 === k.status) l = !0, t = k.ne, e = e.concat(si(a.M, k.Da, !0));else if (1 === k.status) if (25 <= k.Sf) l = !0, t = "maxretry", e = e.concat(si(a.M, k.Da, !0));else {
          var A = a.M.Aa(k.path, f) || H;
          k.rd = A;
          var I = b[g].update(A.J());
          p(I) ? (hg("transaction failed: Data returned ", I, k.path), m = Q(I), "object" === typeof I && null != I && y(I, ".priority") || (m = m.ia(A.C())), A = k.Da, I = Mi(a), I = Xc(m, I), k.sd = m, k.td = I, k.Da = a.Kd++, Ta(f, A), e = e.concat(pi(a.M, k.path, I, k.Da, k.gf)), e = e.concat(si(a.M, A, !0))) : (l = !0, t = "nodata", e = e.concat(si(a.M, k.Da, !0)));
        }
        dc(a.fa, c, e);
        e = [];
        l && (b[g].status = 3, setTimeout(b[g].le, Math.floor(0)), b[g].I && ("nodata" === t ? (k = new X(a, b[g].path), d.push(u(b[g].I, null, null, !1, new W(b[g].rd, k, R)))) : d.push(u(b[g].I, null, Error(t), !1, null))));
      }

      Ui(a, a.yc);

      for (g = 0; g < d.length; g++) gc(d[g]);

      Ti(a);
    }
  }

  function Xi(a, b) {
    for (var c, d = a.yc; null !== (c = K(b)) && null === d.Ea();) d = Xf(d, c), b = N(b);

    return d;
  }

  function Vi(a, b) {
    var c = [];
    Zi(a, b, c);
    c.sort(function (a, b) {
      return a.Lf - b.Lf;
    });
    return c;
  }

  function Zi(a, b, c) {
    var d = b.Ea();
    if (null !== d) for (var e = 0; e < d.length; e++) c.push(d[e]);
    b.R(function (b) {
      Zi(a, b, c);
    });
  }

  function Ui(a, b) {
    var c = b.Ea();

    if (c) {
      for (var d = 0, e = 0; e < c.length; e++) 3 !== c[e].status && (c[d] = c[e], d++);

      c.length = d;
      Yf(b, 0 < c.length ? c : null);
    }

    b.R(function (b) {
      Ui(a, b);
    });
  }

  function Qi(a, b) {
    var c = Xi(a, b).path(),
        d = Xf(a.yc, b);
    ag(d, function (b) {
      $i(a, b);
    });
    $i(a, d);
    $f(d, function (b) {
      $i(a, b);
    });
    return c;
  }

  function $i(a, b) {
    var c = b.Ea();

    if (null !== c) {
      for (var d = [], e = [], f = -1, g = 0; g < c.length; g++) 4 !== c[g].status && (2 === c[g].status ? (O(f === g - 1, "All SENT items should be at beginning of queue."), f = g, c[g].status = 4, c[g].ne = "set") : (O(1 === c[g].status, "Unexpected transaction status in abort"), c[g].le(), e = e.concat(si(a.M, c[g].Da, !0)), c[g].I && d.push(u(c[g].I, null, Error("set"), !1, null))));

      -1 === f ? Yf(b, null) : c.length = f + 1;
      dc(a.fa, b.path(), e);

      for (g = 0; g < d.length; g++) gc(d[g]);
    }
  }

  ;

  function aj() {
    (this || _global).sc = {};
    (this || _global).ag = !1;
  }

  aj.prototype.Cb = function () {
    for (var a in (this || _global).sc) (this || _global).sc[a].Cb();
  };

  aj.prototype.vc = function () {
    for (var a in (this || _global).sc) (this || _global).sc[a].vc();
  };

  aj.prototype.ze = function () {
    (this || _global).ag = !0;
  };

  ba(aj);
  aj.prototype.interrupt = aj.prototype.Cb;
  aj.prototype.resume = aj.prototype.vc;

  function Y(a, b, c, d) {
    (this || _global).k = a;
    (this || _global).path = b;
    (this || _global).n = c;
    (this || _global).pc = d;
  }

  function bj(a) {
    var b = null,
        c = null;
    a.oa && (b = Od(a));
    a.ra && (c = Rd(a));

    if (a.g === re) {
      if (a.oa) {
        if ("[MIN_NAME]" != Nd(a)) throw Error("Query: When ordering by key, you may only pass one argument to startAt(), endAt(), or equalTo().");
        if ("string" !== typeof b) throw Error("Query: When ordering by key, the argument passed to startAt(), endAt(),or equalTo() must be a string.");
      }

      if (a.ra) {
        if ("[MAX_NAME]" != Pd(a)) throw Error("Query: When ordering by key, you may only pass one argument to startAt(), endAt(), or equalTo().");
        if ("string" !== typeof c) throw Error("Query: When ordering by key, the argument passed to startAt(), endAt(),or equalTo() must be a string.");
      }
    } else if (a.g === R) {
      if (null != b && !fg(b) || null != c && !fg(c)) throw Error("Query: When ordering by priority, the first argument passed to startAt(), endAt(), or equalTo() must be a valid priority value (null, a number, or a string).");
    } else if (O(a.g instanceof ve || a.g === Be, "unknown index type."), null != b && "object" === typeof b || null != c && "object" === typeof c) throw Error("Query: First argument passed to startAt(), endAt(), or equalTo() cannot be an object.");
  }

  function cj(a) {
    if (a.oa && a.ra && a.la && (!a.la || "" === a.Rb)) throw Error("Query: Can't combine startAt(), endAt(), and limit(). Use limitToFirst() or limitToLast() instead.");
  }

  function dj(a, b) {
    if (!0 === a.pc) throw Error(b + ": You can't combine multiple orderBy calls.");
  }

  h = Y.prototype;

  h.Mb = function () {
    D("Query.ref", 0, 0, arguments.length);
    return new X((this || _global).k, (this || _global).path);
  };

  h.Ib = function (a, b, c, d) {
    D("Query.on", 2, 4, arguments.length);
    lg("Query.on", a, !1);
    F("Query.on", 2, b, !1);
    var e = ej("Query.on", c, d);
    if ("value" === a) Ri((this || _global).k, this || _global, new ii(b, e.cancel || null, e.Qa || null));else {
      var f = {};
      f[a] = b;
      Ri((this || _global).k, this || _global, new ji(f, e.cancel, e.Qa));
    }
    return b;
  };

  h.mc = function (a, b, c) {
    D("Query.off", 0, 3, arguments.length);
    lg("Query.off", a, !0);
    F("Query.off", 2, b, !0);
    Qb("Query.off", 3, c);
    var d = null,
        e = null;
    "value" === a ? d = new ii(b || null, null, c || null) : a && (b && (e = {}, e[a] = b), d = new ji(e, null, c || null));
    e = (this || _global).k;
    d = ".info" === K((this || _global).path) ? e.Fd.nb(this || _global, d) : e.M.nb(this || _global, d);
    bc(e.fa, (this || _global).path, d);
  };

  h.Pg = function (a, b) {
    function c(k) {
      f && (f = !1, e.mc(a, c), b && b.call(d.Qa, k), g.resolve(k));
    }

    D("Query.once", 1, 4, arguments.length);
    lg("Query.once", a, !1);
    F("Query.once", 2, b, !0);
    var d = ej("Query.once", arguments[2], arguments[3]),
        e = this || _global,
        f = !0,
        g = new B();
    Nb(g.D);
    this.Ib(a, c, function (b) {
      e.mc(a, c);
      d.cancel && d.cancel.call(d.Qa, b);
      g.reject(b);
    });
    return g.D;
  };

  h.Le = function (a) {
    S("Query.limit() being deprecated. Please use Query.limitToFirst() or Query.limitToLast() instead.");
    D("Query.limit", 1, 1, arguments.length);
    if (!fa(a) || Math.floor(a) !== a || 0 >= a) throw Error("Query.limit: First argument must be a positive integer.");
    if ((this || _global).n.la) throw Error("Query.limit: Limit was already set (by another call to limit, limitToFirst, orlimitToLast.");

    var b = (this || _global).n.Le(a);

    cj(b);
    return new Y((this || _global).k, (this || _global).path, b, (this || _global).pc);
  };

  h.Me = function (a) {
    D("Query.limitToFirst", 1, 1, arguments.length);
    if (!fa(a) || Math.floor(a) !== a || 0 >= a) throw Error("Query.limitToFirst: First argument must be a positive integer.");
    if ((this || _global).n.la) throw Error("Query.limitToFirst: Limit was already set (by another call to limit, limitToFirst, or limitToLast).");
    return new Y((this || _global).k, (this || _global).path, (this || _global).n.Me(a), (this || _global).pc);
  };

  h.Ne = function (a) {
    D("Query.limitToLast", 1, 1, arguments.length);
    if (!fa(a) || Math.floor(a) !== a || 0 >= a) throw Error("Query.limitToLast: First argument must be a positive integer.");
    if ((this || _global).n.la) throw Error("Query.limitToLast: Limit was already set (by another call to limit, limitToFirst, or limitToLast).");
    return new Y((this || _global).k, (this || _global).path, (this || _global).n.Ne(a), (this || _global).pc);
  };

  h.Qg = function (a) {
    D("Query.orderByChild", 1, 1, arguments.length);
    if ("$key" === a) throw Error("Query.orderByChild: \"$key\" is invalid.  Use Query.orderByKey() instead.");
    if ("$priority" === a) throw Error("Query.orderByChild: \"$priority\" is invalid.  Use Query.orderByPriority() instead.");
    if ("$value" === a) throw Error("Query.orderByChild: \"$value\" is invalid.  Use Query.orderByValue() instead.");
    ng("Query.orderByChild", a);
    dj(this || _global, "Query.orderByChild");
    var b = new P(a);
    if (b.e()) throw Error("Query.orderByChild: cannot pass in empty path.  Use Query.orderByValue() instead.");
    b = new ve(b);
    b = Fe((this || _global).n, b);
    bj(b);
    return new Y((this || _global).k, (this || _global).path, b, !0);
  };

  h.Rg = function () {
    D("Query.orderByKey", 0, 0, arguments.length);
    dj(this || _global, "Query.orderByKey");
    var a = Fe((this || _global).n, re);
    bj(a);
    return new Y((this || _global).k, (this || _global).path, a, !0);
  };

  h.Sg = function () {
    D("Query.orderByPriority", 0, 0, arguments.length);
    dj(this || _global, "Query.orderByPriority");
    var a = Fe((this || _global).n, R);
    bj(a);
    return new Y((this || _global).k, (this || _global).path, a, !0);
  };

  h.Tg = function () {
    D("Query.orderByValue", 0, 0, arguments.length);
    dj(this || _global, "Query.orderByValue");
    var a = Fe((this || _global).n, Be);
    bj(a);
    return new Y((this || _global).k, (this || _global).path, a, !0);
  };

  h.ce = function (a, b) {
    D("Query.startAt", 0, 2, arguments.length);
    gg("Query.startAt", a, (this || _global).path, !0);
    mg("Query.startAt", b);

    var c = (this || _global).n.ce(a, b);

    cj(c);
    bj(c);
    if ((this || _global).n.oa) throw Error("Query.startAt: Starting point was already set (by another call to startAt or equalTo).");
    p(a) || (b = a = null);
    return new Y((this || _global).k, (this || _global).path, c, (this || _global).pc);
  };

  h.vd = function (a, b) {
    D("Query.endAt", 0, 2, arguments.length);
    gg("Query.endAt", a, (this || _global).path, !0);
    mg("Query.endAt", b);

    var c = (this || _global).n.vd(a, b);

    cj(c);
    bj(c);
    if ((this || _global).n.ra) throw Error("Query.endAt: Ending point was already set (by another call to endAt or equalTo).");
    return new Y((this || _global).k, (this || _global).path, c, (this || _global).pc);
  };

  h.tg = function (a, b) {
    D("Query.equalTo", 1, 2, arguments.length);
    gg("Query.equalTo", a, (this || _global).path, !1);
    mg("Query.equalTo", b);
    if ((this || _global).n.oa) throw Error("Query.equalTo: Starting point was already set (by another call to endAt or equalTo).");
    if ((this || _global).n.ra) throw Error("Query.equalTo: Ending point was already set (by another call to endAt or equalTo).");
    return this.ce(a, b).vd(a, b);
  };

  h.toString = function () {
    D("Query.toString", 0, 0, arguments.length);

    for (var a = (this || _global).path, b = "", c = a.aa; c < a.u.length; c++) "" !== a.u[c] && (b += "/" + encodeURIComponent(String(a.u[c])));

    return (this || _global).k.toString() + (b || "/");
  };

  h.wa = function () {
    var a = xd(Ge((this || _global).n));
    return "{}" === a ? "default" : a;
  };

  function ej(a, b, c) {
    var d = {
      cancel: null,
      Qa: null
    };
    if (b && c) d.cancel = b, F(a, 3, d.cancel, !0), d.Qa = c, Qb(a, 4, d.Qa);else if (b) if ("object" === typeof b && null !== b) d.Qa = b;else if ("function" === typeof b) d.cancel = b;else throw Error(E(a, 3, !0) + " must either be a cancel callback or a context object.");
    return d;
  }

  Y.prototype.ref = Y.prototype.Mb;
  Y.prototype.on = Y.prototype.Ib;
  Y.prototype.off = Y.prototype.mc;
  Y.prototype.once = Y.prototype.Pg;
  Y.prototype.limit = Y.prototype.Le;
  Y.prototype.limitToFirst = Y.prototype.Me;
  Y.prototype.limitToLast = Y.prototype.Ne;
  Y.prototype.orderByChild = Y.prototype.Qg;
  Y.prototype.orderByKey = Y.prototype.Rg;
  Y.prototype.orderByPriority = Y.prototype.Sg;
  Y.prototype.orderByValue = Y.prototype.Tg;
  Y.prototype.startAt = Y.prototype.ce;
  Y.prototype.endAt = Y.prototype.vd;
  Y.prototype.equalTo = Y.prototype.tg;
  Y.prototype.toString = Y.prototype.toString;
  var Z = {};
  Z.zc = Rh;
  Z.DataConnection = Z.zc;

  Rh.prototype.dh = function (a, b) {
    this.Ia("q", {
      p: a
    }, b);
  };

  Z.zc.prototype.simpleListen = Z.zc.prototype.dh;

  Rh.prototype.sg = function (a, b) {
    this.Ia("echo", {
      d: a
    }, b);
  };

  Z.zc.prototype.echo = Z.zc.prototype.sg;
  Rh.prototype.interrupt = Rh.prototype.Cb;
  Z.dg = Fh;
  Z.RealTimeConnection = Z.dg;
  Fh.prototype.sendRequest = Fh.prototype.Ia;
  Fh.prototype.close = Fh.prototype.close;

  Z.Cg = function (a) {
    var b = Rh.prototype.put;

    Rh.prototype.put = function (c, d, e, f) {
      p(f) && (f = a());
      b.call(this || _global, c, d, e, f);
    };

    return function () {
      Rh.prototype.put = b;
    };
  };

  Z.hijackHash = Z.Cg;
  Z.cg = dd;
  Z.ConnectionTarget = Z.cg;

  Z.wa = function (a) {
    return a.wa();
  };

  Z.queryIdentifier = Z.wa;

  Z.Fg = function (a) {
    return a.k.Va.ba;
  };

  Z.listens = Z.Fg;

  Z.ze = function (a) {
    a.ze();
  };

  Z.forceRestClient = Z.ze;

  function X(a, b) {
    var c, d, e;
    if (a instanceof Ji) c = a, d = b;else {
      D("new Firebase", 1, 2, arguments.length);
      d = sd(arguments[0]);
      c = d.fh;
      "firebase" === d.domain && rd(d.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead");
      c && "undefined" != c || rd("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com");
      d.ob || "undefined" !== typeof window && window.location && window.location.protocol && -1 !== window.location.protocol.indexOf("https:") && S("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
      c = new dd(d.host, d.ob, c, "ws" === d.scheme || "wss" === d.scheme);
      d = new P(d.bd);
      e = d.toString();
      var f;
      !(f = !q(c.host) || 0 === c.host.length || !eg(c.lc)) && (f = 0 !== e.length) && (e && (e = e.replace(/^\/*\.info(\/|$)/, "/")), f = !(q(e) && 0 !== e.length && !cg.test(e)));
      if (f) throw Error(E("new Firebase", 1, !1) + "must be a valid firebase URL and the path can't contain \".\", \"#\", \"$\", \"[\", or \"]\".");
      if (b) {
        if (b instanceof aj) e = b;else if (q(b)) e = aj.yb(), c.Rd = b;else throw Error("Expected a valid Firebase.Context for second argument to new Firebase()");
      } else e = aj.yb();
      f = c.toString();
      var g = z(e.sc, f);
      g || (g = new Ji(c, e.ag), e.sc[f] = g);
      c = g;
    }
    Y.call(this || _global, c, d, De, !1);
    (this || _global).then = void 0;
    (this || _global)["catch"] = void 0;
  }

  ka(X, Y);
  var fj = X,
      gj = ["Firebase"],
      hj = n;
  gj[0] in hj || !hj.execScript || hj.execScript("var " + gj[0]);

  for (var ij; gj.length && (ij = gj.shift());) !gj.length && p(fj) ? hj[ij] = fj : hj = hj[ij] ? hj[ij] : hj[ij] = {};

  X.goOffline = function () {
    D("Firebase.goOffline", 0, 0, arguments.length);
    aj.yb().Cb();
  };

  X.goOnline = function () {
    D("Firebase.goOnline", 0, 0, arguments.length);
    aj.yb().vc();
  };

  X.enableLogging = od;
  X.ServerValue = {
    TIMESTAMP: {
      ".sv": "timestamp"
    }
  };
  X.SDK_VERSION = Eb;
  X.INTERNAL = U;
  X.Context = aj;
  X.TEST_ACCESS = Z;

  X.prototype.name = function () {
    S("Firebase.name() being deprecated. Please use Firebase.key() instead.");
    D("Firebase.name", 0, 0, arguments.length);
    return this.key();
  };

  X.prototype.name = X.prototype.name;

  X.prototype.key = function () {
    D("Firebase.key", 0, 0, arguments.length);
    return (this || _global).path.e() ? null : me((this || _global).path);
  };

  X.prototype.key = X.prototype.key;

  X.prototype.o = function (a) {
    D("Firebase.child", 1, 1, arguments.length);
    if (fa(a)) a = String(a);else if (!(a instanceof P)) if (null === K((this || _global).path)) {
      var b = a;
      b && (b = b.replace(/^\/*\.info(\/|$)/, "/"));
      ng("Firebase.child", b);
    } else ng("Firebase.child", a);
    return new X((this || _global).k, (this || _global).path.o(a));
  };

  X.prototype.child = X.prototype.o;

  X.prototype.parent = function () {
    D("Firebase.parent", 0, 0, arguments.length);

    var a = (this || _global).path.parent();

    return null === a ? null : new X((this || _global).k, a);
  };

  X.prototype.parent = X.prototype.parent;

  X.prototype.root = function () {
    D("Firebase.ref", 0, 0, arguments.length);

    for (var a = this || _global; null !== a.parent();) a = a.parent();

    return a;
  };

  X.prototype.root = X.prototype.root;

  X.prototype.set = function (a, b) {
    D("Firebase.set", 1, 2, arguments.length);
    og("Firebase.set", (this || _global).path);
    gg("Firebase.set", a, (this || _global).path, !1);
    F("Firebase.set", 2, b, !0);
    var c = new B();

    (this || _global).k.Ob((this || _global).path, a, null, C(c, b));

    return c.D;
  };

  X.prototype.set = X.prototype.set;

  X.prototype.update = function (a, b) {
    D("Firebase.update", 1, 2, arguments.length);
    og("Firebase.update", (this || _global).path);

    if (da(a)) {
      for (var c = {}, d = 0; d < a.length; ++d) c["" + d] = a[d];

      a = c;
      S("Passing an Array to Firebase.update() is deprecated. Use set() if you want to overwrite the existing data, or an Object with integer keys if you really do want to only update some of the children.");
    }

    jg("Firebase.update", a, (this || _global).path);
    F("Firebase.update", 2, b, !0);
    c = new B();

    (this || _global).k.update((this || _global).path, a, C(c, b));

    return c.D;
  };

  X.prototype.update = X.prototype.update;

  X.prototype.Ob = function (a, b, c) {
    D("Firebase.setWithPriority", 2, 3, arguments.length);
    og("Firebase.setWithPriority", (this || _global).path);
    gg("Firebase.setWithPriority", a, (this || _global).path, !1);
    kg("Firebase.setWithPriority", 2, b);
    F("Firebase.setWithPriority", 3, c, !0);
    if (".length" === this.key() || ".keys" === this.key()) throw "Firebase.setWithPriority failed: " + this.key() + " is a read-only object.";
    var d = new B();

    (this || _global).k.Ob((this || _global).path, a, b, C(d, c));

    return d.D;
  };

  X.prototype.setWithPriority = X.prototype.Ob;

  X.prototype.remove = function (a) {
    D("Firebase.remove", 0, 1, arguments.length);
    og("Firebase.remove", (this || _global).path);
    F("Firebase.remove", 1, a, !0);
    return this.set(null, a);
  };

  X.prototype.remove = X.prototype.remove;

  X.prototype.transaction = function (a, b, c) {
    D("Firebase.transaction", 1, 3, arguments.length);
    og("Firebase.transaction", (this || _global).path);
    F("Firebase.transaction", 1, a, !1);
    F("Firebase.transaction", 2, b, !0);
    if (p(c) && "boolean" != typeof c) throw Error(E("Firebase.transaction", 3, !0) + "must be a boolean.");
    if (".length" === this.key() || ".keys" === this.key()) throw "Firebase.transaction failed: " + this.key() + " is a read-only object.";
    "undefined" === typeof c && (c = !0);
    var d = new B();
    r(b) && Nb(d.D);
    Si((this || _global).k, (this || _global).path, a, function (a, c, g) {
      a ? d.reject(a) : d.resolve(new ei(c, g));
      r(b) && b(a, c, g);
    }, c);
    return d.D;
  };

  X.prototype.transaction = X.prototype.transaction;

  X.prototype.$g = function (a, b) {
    D("Firebase.setPriority", 1, 2, arguments.length);
    og("Firebase.setPriority", (this || _global).path);
    kg("Firebase.setPriority", 1, a);
    F("Firebase.setPriority", 2, b, !0);
    var c = new B();

    (this || _global).k.Ob((this || _global).path.o(".priority"), a, null, C(c, b));

    return c.D;
  };

  X.prototype.setPriority = X.prototype.$g;

  X.prototype.push = function (a, b) {
    D("Firebase.push", 0, 2, arguments.length);
    og("Firebase.push", (this || _global).path);
    gg("Firebase.push", a, (this || _global).path, !0);
    F("Firebase.push", 2, b, !0);
    var c = Li((this || _global).k),
        d = hf(c),
        c = this.o(d);

    if (null != a) {
      var e = this || _global,
          f = c.set(a, b).then(function () {
        return e.o(d);
      });
      c.then = u(f.then, f);
      c["catch"] = u(f.then, f, void 0);
      r(b) && Nb(f);
    }

    return c;
  };

  X.prototype.push = X.prototype.push;

  X.prototype.lb = function () {
    og("Firebase.onDisconnect", (this || _global).path);
    return new V((this || _global).k, (this || _global).path);
  };

  X.prototype.onDisconnect = X.prototype.lb;

  X.prototype.O = function (a, b, c) {
    S("FirebaseRef.auth() being deprecated. Please use FirebaseRef.authWithCustomToken() instead.");
    D("Firebase.auth", 1, 3, arguments.length);
    pg("Firebase.auth", a);
    F("Firebase.auth", 2, b, !0);
    F("Firebase.auth", 3, b, !0);
    var d = new B();
    dh((this || _global).k.O, a, {}, {
      remember: "none"
    }, C(d, b), c);
    return d.D;
  };

  X.prototype.auth = X.prototype.O;

  X.prototype.je = function (a) {
    D("Firebase.unauth", 0, 1, arguments.length);
    F("Firebase.unauth", 1, a, !0);
    var b = new B();
    eh((this || _global).k.O, C(b, a));
    return b.D;
  };

  X.prototype.unauth = X.prototype.je;

  X.prototype.Be = function () {
    D("Firebase.getAuth", 0, 0, arguments.length);
    return (this || _global).k.O.Be();
  };

  X.prototype.getAuth = X.prototype.Be;

  X.prototype.Jg = function (a, b) {
    D("Firebase.onAuth", 1, 2, arguments.length);
    F("Firebase.onAuth", 1, a, !1);
    Qb("Firebase.onAuth", 2, b);

    (this || _global).k.O.Ib("auth_status", a, b);
  };

  X.prototype.onAuth = X.prototype.Jg;

  X.prototype.Ig = function (a, b) {
    D("Firebase.offAuth", 1, 2, arguments.length);
    F("Firebase.offAuth", 1, a, !1);
    Qb("Firebase.offAuth", 2, b);

    (this || _global).k.O.mc("auth_status", a, b);
  };

  X.prototype.offAuth = X.prototype.Ig;

  X.prototype.hg = function (a, b, c) {
    D("Firebase.authWithCustomToken", 1, 3, arguments.length);
    2 === arguments.length && Hb(b) && (c = b, b = void 0);
    pg("Firebase.authWithCustomToken", a);
    F("Firebase.authWithCustomToken", 2, b, !0);
    sg("Firebase.authWithCustomToken", 3, c, !0);
    var d = new B();
    dh((this || _global).k.O, a, {}, c || {}, C(d, b));
    return d.D;
  };

  X.prototype.authWithCustomToken = X.prototype.hg;

  X.prototype.ig = function (a, b, c) {
    D("Firebase.authWithOAuthPopup", 1, 3, arguments.length);
    2 === arguments.length && Hb(b) && (c = b, b = void 0);
    rg("Firebase.authWithOAuthPopup", a);
    F("Firebase.authWithOAuthPopup", 2, b, !0);
    sg("Firebase.authWithOAuthPopup", 3, c, !0);
    var d = new B();
    ih((this || _global).k.O, a, c, C(d, b));
    return d.D;
  };

  X.prototype.authWithOAuthPopup = X.prototype.ig;

  X.prototype.jg = function (a, b, c) {
    D("Firebase.authWithOAuthRedirect", 1, 3, arguments.length);
    2 === arguments.length && Hb(b) && (c = b, b = void 0);
    rg("Firebase.authWithOAuthRedirect", a);
    F("Firebase.authWithOAuthRedirect", 2, b, !1);
    sg("Firebase.authWithOAuthRedirect", 3, c, !0);
    var d = new B(),
        e = (this || _global).k.O,
        f = c,
        g = C(d, b);
    gh(e);
    var k = [Qg],
        f = Ag(f);
    "anonymous" === a || "firebase" === a ? T(g, Sg("TRANSPORT_UNAVAILABLE")) : (cd.set("redirect_client_options", f.qd), hh(e, k, "/auth/" + a, f, g));
    return d.D;
  };

  X.prototype.authWithOAuthRedirect = X.prototype.jg;

  X.prototype.kg = function (a, b, c, d) {
    D("Firebase.authWithOAuthToken", 2, 4, arguments.length);
    3 === arguments.length && Hb(c) && (d = c, c = void 0);
    rg("Firebase.authWithOAuthToken", a);
    F("Firebase.authWithOAuthToken", 3, c, !0);
    sg("Firebase.authWithOAuthToken", 4, d, !0);
    var e = new B();
    q(b) ? (qg("Firebase.authWithOAuthToken", 2, b), fh((this || _global).k.O, a + "/token", {
      access_token: b
    }, d, C(e, c))) : (sg("Firebase.authWithOAuthToken", 2, b, !1), fh((this || _global).k.O, a + "/token", b, d, C(e, c)));
    return e.D;
  };

  X.prototype.authWithOAuthToken = X.prototype.kg;

  X.prototype.gg = function (a, b) {
    D("Firebase.authAnonymously", 0, 2, arguments.length);
    1 === arguments.length && Hb(a) && (b = a, a = void 0);
    F("Firebase.authAnonymously", 1, a, !0);
    sg("Firebase.authAnonymously", 2, b, !0);
    var c = new B();
    fh((this || _global).k.O, "anonymous", {}, b, C(c, a));
    return c.D;
  };

  X.prototype.authAnonymously = X.prototype.gg;

  X.prototype.lg = function (a, b, c) {
    D("Firebase.authWithPassword", 1, 3, arguments.length);
    2 === arguments.length && Hb(b) && (c = b, b = void 0);
    sg("Firebase.authWithPassword", 1, a, !1);
    tg("Firebase.authWithPassword", a, "email");
    tg("Firebase.authWithPassword", a, "password");
    F("Firebase.authWithPassword", 2, b, !0);
    sg("Firebase.authWithPassword", 3, c, !0);
    var d = new B();
    fh((this || _global).k.O, "password", a, c, C(d, b));
    return d.D;
  };

  X.prototype.authWithPassword = X.prototype.lg;

  X.prototype.ve = function (a, b) {
    D("Firebase.createUser", 1, 2, arguments.length);
    sg("Firebase.createUser", 1, a, !1);
    tg("Firebase.createUser", a, "email");
    tg("Firebase.createUser", a, "password");
    F("Firebase.createUser", 2, b, !0);
    var c = new B();

    (this || _global).k.O.ve(a, C(c, b));

    return c.D;
  };

  X.prototype.createUser = X.prototype.ve;

  X.prototype.Xe = function (a, b) {
    D("Firebase.removeUser", 1, 2, arguments.length);
    sg("Firebase.removeUser", 1, a, !1);
    tg("Firebase.removeUser", a, "email");
    tg("Firebase.removeUser", a, "password");
    F("Firebase.removeUser", 2, b, !0);
    var c = new B();

    (this || _global).k.O.Xe(a, C(c, b));

    return c.D;
  };

  X.prototype.removeUser = X.prototype.Xe;

  X.prototype.se = function (a, b) {
    D("Firebase.changePassword", 1, 2, arguments.length);
    sg("Firebase.changePassword", 1, a, !1);
    tg("Firebase.changePassword", a, "email");
    tg("Firebase.changePassword", a, "oldPassword");
    tg("Firebase.changePassword", a, "newPassword");
    F("Firebase.changePassword", 2, b, !0);
    var c = new B();

    (this || _global).k.O.se(a, C(c, b));

    return c.D;
  };

  X.prototype.changePassword = X.prototype.se;

  X.prototype.re = function (a, b) {
    D("Firebase.changeEmail", 1, 2, arguments.length);
    sg("Firebase.changeEmail", 1, a, !1);
    tg("Firebase.changeEmail", a, "oldEmail");
    tg("Firebase.changeEmail", a, "newEmail");
    tg("Firebase.changeEmail", a, "password");
    F("Firebase.changeEmail", 2, b, !0);
    var c = new B();

    (this || _global).k.O.re(a, C(c, b));

    return c.D;
  };

  X.prototype.changeEmail = X.prototype.re;

  X.prototype.Ze = function (a, b) {
    D("Firebase.resetPassword", 1, 2, arguments.length);
    sg("Firebase.resetPassword", 1, a, !1);
    tg("Firebase.resetPassword", a, "email");
    F("Firebase.resetPassword", 2, b, !0);
    var c = new B();

    (this || _global).k.O.Ze(a, C(c, b));

    return c.D;
  };

  X.prototype.resetPassword = X.prototype.Ze;
})();

exports = Firebase;
export default exports;